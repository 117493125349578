<template>
  <div
    @click.stop
    class="no-drag vis-height-100"
    :id="'pivot-table-wrapper' + panelId"
  >
    <div class="vis-height-100" :ref="`${panelId}ejs-pivot-container`">
      <ejs-pivotview
        v-if="panelDataDetailsMetrics.length"
        v-show="!isContainerResizing"
        :ref="panelId"
        :id="panelId"
        :height="pivotHeight()"
        :dataSourceSettings="dataSourceSettings"
        :enableValueSorting="enableValueSorting"
        :allowExportMenu="allowExportMenu"
        :allowExcelExport="allowExcelExport"
        :allowPdfExport="allowPdfExport"
        :allowCsvExport="allowCsvExport"
        :allowContextMenu="allowContextMenu"
        :cellSelecting="cellSelecting"
        :gridSettings="gridSettings"
        :showFieldList="showFieldListEnabled && showFieldList"
        :allowCalculatedField="allowCalculatedField"
        :allowConditionalFormatting="allowConditionalFormatting"
        :dataBound="dataBound"
        :actionComplete="onActionComplete"
        :enablePaging="true"
        :pageSettings="pageSettings"
        :pagerSettings="pagerSettings"
        :aggregateCellInfo="aggregateCellInfo"
        :tooltipTemplate="tooltipTemplate"
        :cellTemplate="cellTemplate"
      >
      </ejs-pivotview>

      <div v-if="eventAreaHidden" class="eventarea">
        <span
          :id="`eventlog${panelId}`"
          :ref="`eventlog${panelId}`"
          style="word-break: normal"
        ></span>
      </div>
      <div class="vis-text--center" v-if="!allMetrics.length">
        <img
          src="../../../assets/images/chartEmptyStates/PIVOT_TABLE.svg"
          alt="PIVOT_TABLE"
        />
      </div>
    </div>
    <ejs-contextmenu
      class="chart-context"
      :id="contextMenuId"
      :items="contextMenuItems"
      :select="selectContextItem"
      :beforeItemRender="beforeContextItemHighlight"
    ></ejs-contextmenu>
  </div>
</template>

<script>
import Vue from "vue";
import {
  PivotViewPlugin,
  Pager,
  // eslint-disable-next-line no-unused-vars
  PivotView,
  FieldList,
  Toolbar,
  ConditionalFormatting,
  ExcelExport,
  PDFExport,
} from "@syncfusion/ej2-vue-pivotview";
import { enableRipple } from "@syncfusion/ej2-base";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import {
  pivotTableTotalsEnums,
  PIVOT_TABLE_PROPERTIES_ENUM,
} from "../../../commons/dashboardProperties";
import cloneDeep from "clone-deep";
import {
  ejsPivotTableActionNames,
  ejsTableSortTypeConversion,
  toolbarOptions as toolbarOptionsEnum,
  tableFooterAggregrations,
} from "../../../commons/tableData";
import { mapActions, mapGetters } from "vuex";
import { ACTION as ACTION_EXPORT_METADATA } from "../../../store/modules/Visualize/ExportMetaData/types";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";
import { createChartFilter } from "../../../util/chart-filter/filterValueSetters";
import { GETTER as GETTER_USER } from "../../../store/modules/Visualize/User/types";
import _ from "lodash";
import { chartContextMenuItemKey } from "../../../util/chart-context-menu/chartContextMenuHelper";
import { filterOperator } from "../../../commons/filterComponents";
import { PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS } from "../../../mocks/pivotTableColumnsDefaultProperties";
import {
  conditionalOperatorValues,
  iconFormatPositionValue,
  iconFormatShowType,
  valueFormatShowType,
} from "../../panel/conditionalFormatting/conditionalFormattingHelper";
import moment from "moment";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import { BOX_KEY_ENUM } from "../../../commons/dashboardAndPanel";
import {tableColumnFormatValues} from "../../../commons/dashboardProperties.js";
import DOMPurify from "dompurify";

enableRipple(false);

Vue.use(PivotViewPlugin);

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    showFieldListEnabled: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default() {
        return { arr: [] };
      },
    },
    panelData: {
      type: Object,
    },
    contextMenuItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    refreshOnStyleChanges: {
      type: Boolean,
      default: false,
    },
    selectedColumnField: {
      type: Object,
    },
    skipSetPivotGridContent : {
      type: Boolean,
      default: false,
    },
    isSelectionCellActive: {
      type: Boolean,
      default: true
    }
  },
  provide: {
    pivotview: [
      ConditionalFormatting,
      FieldList,
      Toolbar,
      Pager,
      ExcelExport,
      PDFExport,
      PivotView,
    ],
  },
  watch: {
    selectionMode: {
      handler(val) {
        this.modeddlOnChange(val);
      },
    },
    selectionType: {
      handler(val) {
        this.typeddlOnChange(val);
      },
    },
    gridSettings: {
      handler() {
        let pivotObj = this.$refs?.[this.panelId]?.ej2Instances;
        pivotObj?.renderModule?.updateGridSettings();
      },
      deep: true,
    },
    "panelData.properties.style": {
      handler() {
        if (this.refreshOnStyleChanges && !this.isResizingCellWithMouse) {
          this.$nextTick(() => {
            let pivotObj = this.$refs?.[this.panelId]?.ej2Instances;
            pivotObj?.pivotRefresh();
          });
        }
      },
      deep: true,
    },
    conditionalFormatSettings: {
      handler(value) {
        const panelDataCloned = cloneDeep(this.panelData);

        panelDataCloned.properties.style[
          PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_CONDITIONAL_FORMAT
        ] = value;

        this.$emit("updateSelectedPanel", panelDataCloned);

        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
      deep: true,
    },
    subTotals: {
      handler() {
        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
    },
    drilledMembers: {
      handler() {
        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
    },
    grandTotals: {
      handler() {
        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
    },
    grandTotalPosition: {
      handler() {
        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
    },
    option: {
      handler() {
        this.setDataSourceSettings(this.option?.dataSourceSettings);
      },
      deep: true,
    },
  },
  data() {
    return {
      cellTemplate: (data) => {
        return this.allowHeatMap ? this.getHeatmapTemplate(data) : this.getConditionalTemplate(data);
      },
      fieldListPopupDeferButtonFound: false,
      exportTypes: {
        EXCEL: "EXCEL",
        CSV: "CSV",
        PDF: "PDF",
      },
      eventAreaHidden: false,
      allowCalculatedField: true,
      isContainerResizing: false,
      conditionalFormatSettings: null,
      dataSourceSettings: {},
      contextMenuId: "chartMenu_" + Math.random(),
      pivotContextData: {},
      pageCoord: {},
      activeCurrentRowPage: 1,
      activeCurrentColumnPage: 1,
      minValue: 0,
      maxValue: 0,
      colourScheme: [
        "range1",
        "range2",
        "range3",
        "range4",
        "range5",
        "range6",
        "range7",
        "range8",
        "range9",
        "range10",
        "range11",
        "range12",
        "range13",
        "range14",
      ],
      currentExportFormatType: null,
      isCallExportNotFormatted: false,
      exportFormatTypes: {
        formatted: "formatted",
        notFormatted: "notFormatted",
      },
      selectedColumnFormat: {
        cellPercentage: false,
      },
      doubleClickDetected: false,
      grandTotalValueByFieldKey: {},
      grandTotal: "Grand Total",
      isResizingCellWithMouse: false
    };
  },
  beforeDestroy() {
    this.pivotTableWrapper?.removeEventListener("dblclick", this.handleDblClickDrillThrough);
    this.pivotTableWrapper?.removeEventListener("click", this.handleClickDrillThrough);
    
  },
  mounted() {
    this.conditionalFormatSettings = cloneDeep(
      this.CHART_COMPUTED_VALUES.PIVOT_TABLE_CONDITIONAL_FORMAT
    );

    //for backend schedule screen shot
    const gridItem = document.getElementById(
      this.panelData.id || this.panelData.i
    );

    if (gridItem) gridItem.setAttribute("draw-finished", true);

    //for pivot cell click
    this.handlePivotCell();
  },
  computed: {
    ...mapGetters({ userPreferences: GETTER_USER.GET_USER_PREFERENCES }),
    allMetrics() {
      return this.panelData?.details?.metrics;
    },
    allAggregations() {
      return this.panelData?.details?.aggregation;
    },
    filteredAggregationByColumn() {
      return this.allAggregations.filter(x=> x.boxKey === BOX_KEY_ENUM.COLUMN.KEY);
    },
    filteredAggregationByRows() {
      return this.allAggregations.filter(x=> x.boxKey === BOX_KEY_ENUM.ROW.KEY);
    },
    onlyAggregationRows() {
      return this.filteredAggregationByRows?.length >= 1 && !this.filteredAggregationByColumn?.length;
    },
    onlyAggregationColumns() {
      return this.filteredAggregationByColumn?.length >= 1 && !this.filteredAggregationByRows?.length;
    },
    panelId() {
      return this.panelData?.i ?? "defaultDivId";
    },
    pivotTableWrapper() {
      return document?.getElementById?.(`pivot-table-wrapper${this.panelId}`);
    },
    enablePaging() {
      return (
        this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_ROW_PAGING ||
        this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_COLS_PAGING
      );
    },
    pageSettings() {
      return {
        rowPageSize: this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_ROW_PAGING
          ? this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROWS_PER_PAGE
          : 200,
        columnPageSize: this?.CHART_COMPUTED_VALUES
          .PIVOT_TABLE_ENABLE_COLS_PAGING
          ? this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_COLS_PER_PAGE
          : 200,
        currentColumnPage: this.activeCurrentColumnPage,
        currentRowPage: this.activeCurrentRowPage,
        isInversed: true,
      };
    },
    pagerSettings() {
      return {
        enableCompactView: false,
        showColumnPager:
          this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_COLS_PAGING,
        showRowPager: this?.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_ROW_PAGING,
        showColumnPageSize: false,
        showRowPageSize: false,
      };
    },
    drilledMembers() {
      return this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_DRILLED_MEMBERS;
    },
    enableValueSorting() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ENABLE_VALUE_SORTING;
    },
    allowExportMenu() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_EXPORT_MENU;
    },
    showFieldList() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_SHOW_FIELD_LIST;
    },
    allowExcelExport() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_EXCEL_EXPORT;
    },
    allowCsvExport() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_CSV_EXPORT;
    },
    allowPdfExport() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_PDF_EXPORT;
    },
    allowContextMenu() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_CONTEXT_MENU;
    },
    selectionMode() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_SELECTION_MODE;
    },
    selectionType() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_SELECTION_TYPE;
    },
    allowSelection() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_SELECTION;
    },
    subTotals() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_SUB_TOTALS;
    },
    grandTotals() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_GRAND_TOTALS;
    },
    grandTotalPosition() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_GRAND_TOTAL_POSITION;
    },
    allowConditionalFormatting() {
      return true;
    },
    allowHeatMap() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ALLOW_HEATMAP;
    },
    allowDrillThroughDoubleClick() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK;
    },
    drillThroughDashboardList() {
      return this.CHART_COMPUTED_VALUES.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST;
    },
    gridSettings() {
      return {
        width: "100%",
        columnWidth: 120,
        rowHeight: this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROW_HEIGHT,
        gridLines: this.CHART_COMPUTED_VALUES.PIVOT_TABLE_GRID_LINES,
        allowSelection: this.allowSelection,
        selectionSettings: {
          mode: this.selectionMode,
          type: this.selectionType,
          cellSelectionMode: "Box",
        },
        excelQueryCellInfo: (args) => {
          if (this.currentExportFormatType === this.exportFormatTypes.formatted){
          const selectedMetric = this.panelDataDetailsMetrics.find(
            (item) => `${item?.field}${item?.type}` === args?.cell.actualText
          );

          const selectedMetricFormat = selectedMetric?.format;

          if (selectedMetricFormat && args?.value) {
            args.value = formatMeasure(args?.value, selectedMetricFormat);
          }}
        },
        pdfQueryCellInfo: (args) => {
          if (this.currentExportFormatType === this.exportFormatTypes.formatted){
          const selectedMetric = this.panelDataDetailsMetrics.find(
            (item) => `${item?.field}${item?.type}` === args?.data.actualText
          );

          const selectedMetricFormat = selectedMetric?.format;

          if (selectedMetricFormat && args?.value) {
            args.value = formatMeasure(args?.value, selectedMetricFormat);
          }}
        },

        queryCellInfo: (args) => {
          args.pivotview.defaultLocale.grandTotal = this.$t(
            "panelProperties.grandTotals"
          );
          let field = null;
            
            if (this.panelDataDetailsMetrics?.length !== 1) {
              field = this.panelDataDetailsMetrics.find(x => x.field + x.type === args.column?.customAttributes?.cell?.actualText);
            } else {
              //Measure'da tek field olduğu zaman args.columndan alamıyoruz!!!
              field = this.panelDataDetailsMetrics[0]
            }
          const getColumnBasedCellFormatting = () => {
            if (field) {
              const columnBasedFormattingFontFamily =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_FAMILY,
                  field.slotFieldId
                );

              const columnBasedFormattingFontSize =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_SIZE,
                  field.slotFieldId
                );
              const columnBasedFormattingFontWeight =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_WEIGHT,
                  field.slotFieldId
                );
              const columnBasedFormattingFontColor =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_FONT_COLOR,
                  field.slotFieldId
                );
              const columnBasedFormattingBackgroundColor =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_BACKGROUND_COLOR,
                  field.slotFieldId
                );
              const columnBasedFormattingRowAlign =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN,
                  field.slotFieldId
                );
              const columnBasedFormattingFormatCell =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FORMAT
                );
  
                const columnBasedFormattingRowTextWrap =
                this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TEXT_WRAP
                );
                

              return {
                columnBasedFormattingFontFamily,
                columnBasedFormattingFontSize,
                columnBasedFormattingFontWeight,
                columnBasedFormattingFontColor,
                columnBasedFormattingBackgroundColor,
                columnBasedFormattingRowAlign,
                columnBasedFormattingFormatCell,
                columnBasedFormattingRowTextWrap
              };
            }

            return {};
          };

          const columnBasedCellFormatting = getColumnBasedCellFormatting();


          //GRAND TOTAL STYLE START
          if (args?.cell?.className?.includes("e-gtot")) { //gtot grand total alanları için geçerli
            if (args?.cell?.className?.includes("e-rowsheader")) {
              const gtotRowHeaderSpan = args.cell.querySelector(".e-cellvalue");
              gtotRowHeaderSpan.style.fontFamily =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY;
              gtotRowHeaderSpan.style.fontSize = `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE}px`;
              gtotRowHeaderSpan.style.fontWeight =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT;
              gtotRowHeaderSpan.style.color =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR;
            } else {
              const gtotMeasureSpan = args?.cell?.querySelector(".e-cellvalue");
              const gtotMeasure = args?.cell?.querySelectorAll(".e-cellvalue")?.[0];

              if (!this.selectedColumnFormat?.cellPercentage) {
                this.updateCellValue(gtotMeasure, args?.column?.headerText);
              }
              
              const setGtotMeasureSpanByGrandTotal = () => {
                if (
                  columnBasedCellFormatting.columnBasedFormattingFormatCell ===
                  tableColumnFormatValues.PERCENTAGE && gtotMeasureSpan.innerText != ""
                ) {
                  gtotMeasureSpan.innerText = gtotMeasureSpan.innerText + "%";
                }

                gtotMeasureSpan.style.fontFamily =
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY;
                gtotMeasureSpan.style.fontSize = `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE}px`;
                gtotMeasureSpan.style.fontWeight =
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT;
                gtotMeasureSpan.style.color =
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR;

              }

              //Heatmap kapalı, columns ve rows sütunlarının her ikiside dolu ise sadece total hücresi set edilecek
              if (!this.allowHeatMap && !this.onlyAggregationColumns && !this.onlyAggregationRows) {
                setGtotMeasureSpanByGrandTotal();
              } else if (!this.allowHeatMap && (this.onlyAggregationColumns || this.onlyAggregationRows)) {
                // else if => Heatmap kapalı, columns ve rows sütunlarından ikisinden biri dolu ise çalışacak. 
                //total kısmını ayırmak için
                if (args?.data[0]?.type === "grand sum") {
                  setGtotMeasureSpanByGrandTotal();

                  return;
                }
                if (
                  columnBasedCellFormatting.columnBasedFormattingFormatCell ===
                  tableColumnFormatValues.PERCENTAGE && gtotMeasureSpan.innerText != ""
                ) {
                  gtotMeasureSpan.innerText = gtotMeasureSpan.innerText + "%";
                }

                gtotMeasureSpan.style.fontFamily =
                  columnBasedCellFormatting.columnBasedFormattingFontFamily ||
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_FAMILY;
                gtotMeasureSpan.style.fontSize =
                  columnBasedCellFormatting.columnBasedFormattingFontSize
                    ? `${columnBasedCellFormatting.columnBasedFormattingFontSize}px`
                    : `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_SIZE}px`;
                gtotMeasureSpan.style.fontWeight =
                  columnBasedCellFormatting.columnBasedFormattingFontWeight ||
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_WEIGHT;
                gtotMeasureSpan.style.color =
                  columnBasedCellFormatting.columnBasedFormattingFontColor ||
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_COLOR;

                  const isCellTextWrapActive = columnBasedCellFormatting.columnBasedFormattingRowTextWrap;

                  gtotMeasureSpan.style.whiteSpace = isCellTextWrapActive ? "pre-wrap" : "nowrap";
                  gtotMeasureSpan.style.wordBreak = isCellTextWrapActive ? "break-word" : "inherit";
                  gtotMeasureSpan.style.lineHeight = isCellTextWrapActive ? "normal" : "inherit";

                  args.cell.style.textAlign =
                   columnBasedCellFormatting.columnBasedFormattingRowAlign;

                   args.cell.style.backgroundColor =
                  columnBasedCellFormatting.columnBasedFormattingBackgroundColor;
              } else {
                gtotMeasureSpan.style.display = "none";
              }
            }
            //GRAND TOTAL STYLE END
          } else { // else kısmı grand total dışındaki hücreler için çalışır
            if (args?.cell?.className?.includes("e-rowsheader")) {
              const rowHeaderSpan = args?.cell?.querySelector(".e-cellvalue");
              
              const fieldName = args?.cell?.getAttribute("fieldname");
              const columnBasedRowHeaderFormatting =
                this.getColumnBasedRowHeaderFormatting(fieldName);

              if (columnBasedRowHeaderFormatting.columnBasedRowMaxCharacter) {
                const maxRowCharacters = columnBasedRowHeaderFormatting.columnBasedRowMaxCharacter;

                if (rowHeaderSpan?.textContent?.length  > maxRowCharacters) {
                  rowHeaderSpan.textContent = rowHeaderSpan?.textContent.substring(0, maxRowCharacters) + '...';
                }
              }

              rowHeaderSpan.style.fontFamily =
                columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontFamily
                  ? columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontFamily
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY;
              rowHeaderSpan.style.fontSize =
                columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontSize
                  ? `${columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontSize}px`
                  : `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_ROW_TITLE_FONT_SIZE}px`;
              rowHeaderSpan.style.fontWeight =
                columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontWeight
                  ? columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontWeight
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT;
              rowHeaderSpan.style.color =
                columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontColor
                  ? columnBasedRowHeaderFormatting.columnBasedFormattingHeaderFontColor
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_ROW_TITLE_FONT_COLOR;
                      args.cell.style.textAlign =
                      columnBasedRowHeaderFormatting.columnBasedFormattingHeaderColumnAlign;
              args.column.width = this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROW_WIDTH
                    ? this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROW_WIDTH : "";

                const isRowTitleTextWrapActive = this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP;
                    
                rowHeaderSpan.style.whiteSpace = isRowTitleTextWrapActive ? "pre-wrap" : "nowrap";
                rowHeaderSpan.style.wordBreak = isRowTitleTextWrapActive ? "break-word" : "inherit";
                rowHeaderSpan.style.lineHeight = isRowTitleTextWrapActive ? "normal" : "inherit";
            } else {
              const gtotCellSpan =
                args?.cell?.querySelectorAll("span.e-cellvalue")?.[0];

              if (!this.selectedColumnFormat?.cellPercentage) {
                this.updateCellValue(gtotCellSpan, args?.column?.headerText);
              }
              
              const isCellTextWrapActive =
                columnBasedCellFormatting.columnBasedFormattingRowTextWrap
                  ? columnBasedCellFormatting.columnBasedFormattingRowTextWrap
                  : this.CHART_COMPUTED_VALUES.PIVOT_TABLE_ROW_TITLE_TEXT_WRAP;

              gtotCellSpan.style.whiteSpace = isCellTextWrapActive ? "pre-wrap" : "nowrap";
              gtotCellSpan.style.wordBreak = isCellTextWrapActive ? "break-word" : "inherit";
              gtotCellSpan.style.lineHeight = isCellTextWrapActive ? "normal" : "inherit";

              if (!this.allowHeatMap) {
                const gtotCell = args?.cell;
                //MEASURE FONT STYLE

                if (
                  columnBasedCellFormatting.columnBasedFormattingFormatCell ===
                  tableColumnFormatValues.PERCENTAGE && gtotCellSpan.innerText != ""
                ) {
                    gtotCellSpan.innerText = gtotCellSpan.innerText + "%";
                }

                gtotCell.style.backgroundColor = 
                  gtotCell.style.backgroundColor ||
                  columnBasedCellFormatting.columnBasedFormattingBackgroundColor;
                  
                 gtotCell.style.textAlign =
                   columnBasedCellFormatting.columnBasedFormattingRowAlign;
                
                gtotCellSpan.style.fontFamily =
                gtotCell.style.fontFamily ||
                columnBasedCellFormatting.columnBasedFormattingFontFamily ||
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_FAMILY;

                gtotCellSpan.style.fontSize = gtotCell.style.fontSize
                  ? gtotCell.style.fontSize
                  : columnBasedCellFormatting.columnBasedFormattingFontSize
                  ? `${columnBasedCellFormatting.columnBasedFormattingFontSize}px`
                  : `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_SIZE}px`;

                gtotCellSpan.style.fontWeight =
                  gtotCell.style.fontWeight ||
                  columnBasedCellFormatting.columnBasedFormattingFontWeight ||
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_WEIGHT;

                gtotCellSpan.style.color =
                  gtotCell.style.color ||
                  columnBasedCellFormatting.columnBasedFormattingFontColor ||
                  this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_MEASURE_FONT_COLOR;

                gtotCellSpan.style.overflow = "visible";
              } else {
                gtotCellSpan.style.display = "none";
              }
            }
          }
        },
        columnRender: function (args) {
          for (let i = 0; i < args.columns?.length; i++) {
            if (args.columns[i].headerText.includes("Grand Total")) {
              args.columns[i].autoFit = false; // there is a bug in this version of pivotview lib
            }
          }
        },
        headerCellInfo: (args) => {
          if (
            (args?.node?.classList?.contains("e-columnsheader") &&
              !args?.node?.classList?.contains("e-gtot")) ||
            (args?.node?.classList?.contains("e-columnsheader") &&
              args?.node?.classList?.contains("e-gtot")) ||
              args?.node?.classList?.contains("e-columnheader")
          ) {
            const columnTopHeaderSpan =
              args?.node?.querySelectorAll(
                ".e-headercelldiv .e-cellvalue span.e-headertext"
              )[0] ||
              args?.node?.querySelectorAll(
                ".e-stackedheadercelldiv .e-cellvalue"
              )[0];

            const columnHeaderSpan = args?.node?.querySelectorAll(
              ".e-headercelldiv .e-headertext span.e-headertext"
            )[0];
            const columnHeaderNodeSpan = args?.node?.querySelectorAll(
              ".e-headercelldiv .e-headertext span.e-headertext"
            );
            const getColumnBasedHeaderFormatting = () => {
              let field;
              
              const axisField = args.cell.column?.customAttributes?.cell?.valueSort?.axis;

              if (axisField) {
                field = this.allAggregations.find(x => x.field === axisField);
              } else {
                field = this.panelDataDetailsMetrics.find(x => x.field + x.type === args.cell.column?.customAttributes?.cell?.actualText);
              }

              if (field) {
                const columnBasedFormattingHeaderFontFamily =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_FAMILY,
                    field.slotFieldId
                  );

                const columnBasedFormattingHeaderFontSize =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_SIZE,
                    field.slotFieldId
                  );
                const columnBasedFormattingHeaderFontWeight =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
                    field.slotFieldId
                  );
                const columnBasedFormattingHeaderFontColor =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_COLOR,
                    field.slotFieldId
                  );
                const columnBasedFormattingHeaderColumnTextWrap =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TEXT_WRAP,
                    field.slotFieldId
                  );
                const columnBasedFormattingHeaderColumnAlign =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
                    field.slotFieldId
                  );
                const columnBasedFormattingScreenTip =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP,
                    field.screenTip,
                    field.slotFieldId
                  );
                const columnBasedFormattingColumnName =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME,
                    field.screenTip,
                    field.slotFieldId
                  );
                  
                const columnBasedCellWidth =
                  this.getPanelDataColumnBasedProperties(
                  field.fieldId,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
                  PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_WIDTH,
                  field.slotFieldId
                  );

                  const columnBasedTextWrap =
                  this.getPanelDataColumnBasedProperties(
                    field.fieldId,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
                    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TEXT_WRAP,
                    field.slotFieldId
                  );
                
                if (columnBasedCellWidth) {
                  args.cell.column.width = columnBasedCellWidth + "px";
                }
                

                return {
                  columnBasedFormattingHeaderFontFamily,
                  columnBasedFormattingHeaderFontSize,
                  columnBasedFormattingHeaderFontWeight,
                  columnBasedFormattingHeaderFontColor,
                  columnBasedFormattingHeaderColumnTextWrap,
                  columnBasedFormattingHeaderColumnAlign,
                  columnBasedFormattingScreenTip,
                  columnBasedFormattingColumnName,
                  columnBasedTextWrap,
                  columnBasedCellWidth
                };
              }

              return {};
            };

            const columnBasedHeaderFormatting =
              getColumnBasedHeaderFormatting();

              //will change column cell width but if columns slot is empty
            if (columnBasedHeaderFormatting.columnBasedCellWidth && this.onlyAggregationRows) {
              args.cell.column.width = columnBasedHeaderFormatting.columnBasedCellWidth + "px";
              args.cell.column.autoFit = false;
            }

            const columnHeaderDiv = args?.node.querySelectorAll(
              ".e-pivotcell-container"
            )[0];

            if (
              columnHeaderDiv &&
              columnBasedHeaderFormatting.columnBasedFormattingHeaderColumnAlign
            ) {
              columnHeaderDiv.style.justifyContent =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderColumnAlign;
            }

            const isHeaderTextWrapActive =
              columnBasedHeaderFormatting.columnBasedTextWrap
                ? columnBasedHeaderFormatting.columnBasedTextWrap
                : this.CHART_COMPUTED_VALUES
                    ?.PIVOT_TABLE_COLUMN_TITLE_TEXT_WRAP;

            if (columnHeaderSpan?.style) {
              const screenTip =
                columnBasedHeaderFormatting.columnBasedFormattingScreenTip; // Burada screen tipini alıyoruz
              const columnName =
                columnBasedHeaderFormatting.columnBasedFormattingColumnName;
              if (screenTip) {
                columnHeaderSpan.setAttribute("title", screenTip);
              }
              if (columnName) {
                columnHeaderNodeSpan[0].innerText = columnName;
              }

              columnHeaderSpan.style.whiteSpace = isHeaderTextWrapActive ? "pre-wrap" : "nowrap";
              columnHeaderSpan.style.wordBreak = isHeaderTextWrapActive ? "break-word" : "inherit";
              columnHeaderSpan.style.lineHeight = isHeaderTextWrapActive ? "normal" : "inherit";

              columnHeaderSpan.style.fontFamily =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY;
              columnHeaderSpan.style.fontSize = `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE}px`;
              columnHeaderSpan.style.fontWeight =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT;
              columnHeaderSpan.style.color =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderFontColor
                  ? columnBasedHeaderFormatting.columnBasedFormattingHeaderFontColor
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR;
              columnHeaderSpan.style.overflow = "visible";
            }
            if (columnTopHeaderSpan?.style) {
              columnTopHeaderSpan.style.whiteSpace = isHeaderTextWrapActive ? "pre-wrap" : "nowrap";
              columnTopHeaderSpan.style.wordBreak = isHeaderTextWrapActive ? "break-word" : "inherit";
              columnTopHeaderSpan.style.lineHeight = isHeaderTextWrapActive ? "normal" : "inherit";

              columnTopHeaderSpan.style.fontFamily =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderFontFamily
                  ? columnBasedHeaderFormatting.columnBasedFormattingHeaderFontFamily
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY;
              columnTopHeaderSpan.style.fontSize =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderFontSize
                  ? `${columnBasedHeaderFormatting.columnBasedFormattingHeaderFontSize}px`
                  : `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE}px`;
              columnTopHeaderSpan.style.fontWeight =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderFontWeight
                  ? columnBasedHeaderFormatting.columnBasedFormattingHeaderFontWeight
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT;
              columnTopHeaderSpan.style.color =
                columnBasedHeaderFormatting.columnBasedFormattingHeaderFontColor
                  ? columnBasedHeaderFormatting.columnBasedFormattingHeaderFontColor
                  : this.CHART_COMPUTED_VALUES
                      ?.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR;
              columnTopHeaderSpan.style.overflow = "visible";
            }
          } else if (args.node.classList.contains("e-gtot")) {
            const gtotColumnHeaderSpan = args?.node?.querySelectorAll(
              ".e-headercelldiv .e-headertext span.e-headertext"
            )[0];
            const gtotColumnHeaderDiv = args?.node?.querySelectorAll(
              ".e-headercelldiv .e-headertext"
            )[0];
            if (gtotColumnHeaderSpan?.style) {
              gtotColumnHeaderSpan.style.fontFamily =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY;
              gtotColumnHeaderSpan.style.fontSize = `${this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE}px`;
              gtotColumnHeaderSpan.style.fontWeight =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT;
              gtotColumnHeaderSpan.style.color =
                this.CHART_COMPUTED_VALUES?.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR;
              gtotColumnHeaderDiv.style.overflow = "visible";
            }
          }
        },
      };
    },
  },
  methods: {
    ...mapActions({
      createExportMetadata: ACTION_EXPORT_METADATA.CREATE_EXPORT_METADATA,
    }),
    getHeatmapTemplate(data) {
        const cellContent = DOMPurify.sanitize(this.getCellContent(data));
        
        return `<span class="template-wrap">${cellContent}</span>`;
    },
    getConditionalTemplate(data) {
        const cellConditionalContent = DOMPurify.sanitize(this.getCellConditionalContent(data));
        
        return `<span class="template-wrap">${cellConditionalContent}</span>`;
    },
    setPivotGridContent() {
      //Bu fonksiyon sadece dashboard ekranında çalışıcak
      if (!this.skipSetPivotGridContent) return;

      const pivotMainRef = this.$refs?.[`${this.panelId}ejs-pivot-container`];
      const pivotHeaderHeight = pivotMainRef?.querySelector('.e-gridheader')?.offsetHeight || 0;
      const pivotPagerHeight = pivotMainRef?.querySelector('.e-pivot-pager')?.offsetHeight || 0;
      const pivotMainRefHeight = pivotMainRef?.offsetHeight;
      const cardFooterElement = document.querySelectorAll(
        `#grid-item-${this.panelData?.id ?? this.panelData?.i} .vis-chart-card-footer`
      )?.[0];

      const footerHeightByCardFooterElement =
        cardFooterElement && this.enablePaging
          ? 16
          : cardFooterElement
          ? 10
          : 0;

      let content = pivotMainRef?.querySelector('.e-content');
      
      const sumHeight = this.enablePaging
        ? pivotHeaderHeight + pivotPagerHeight + footerHeightByCardFooterElement
        : pivotHeaderHeight + footerHeightByCardFooterElement;

      if(!content) return;
      
       const totalContentHeight = pivotMainRefHeight - sumHeight - 20;
       
      content.style.height =  totalContentHeight + 'px';
    },
    setDataSourceSettings(dataSourceSettings) {
      const firstRowName = dataSourceSettings?.rows?.[0]?.name;
      const drilledMemberItems = this.addAndGetDrilledMemberItems(
        dataSourceSettings,
        firstRowName
      );
      this.dataSourceSettings = {
        ...dataSourceSettings,
        showSubTotals:
          this.subTotals === pivotTableTotalsEnums.Show ||
          this.subTotals === pivotTableTotalsEnums.ShowRowOnly ||
          this.subTotals === pivotTableTotalsEnums.ShowColumnOnly,
        showRowSubTotals:
          this.subTotals === pivotTableTotalsEnums.ShowRowOnly ||
          this.subTotals === pivotTableTotalsEnums.Show,
        showColumnSubTotals:
          this.subTotals === pivotTableTotalsEnums.ShowColumnOnly ||
          this.subTotals === pivotTableTotalsEnums.Show,
        showGrandTotals:
          this.grandTotals === pivotTableTotalsEnums.Show ||
          this.grandTotals === pivotTableTotalsEnums.ShowRowOnly ||
          this.grandTotals === pivotTableTotalsEnums.ShowColumnOnly,
        showRowGrandTotals:
          this.grandTotals === pivotTableTotalsEnums.ShowRowOnly ||
          this.grandTotals === pivotTableTotalsEnums.Show,
        showColumnGrandTotals:
          this.grandTotals === pivotTableTotalsEnums.ShowColumnOnly ||
          this.grandTotals === pivotTableTotalsEnums.Show,
        grandTotalsPosition: this.grandTotalPosition,
        conditionalFormatSettings: this.conditionalFormatSettings,
        drilledMembers:
          dataSourceSettings?.rows?.length > 1 && this.drilledMembers
            ? [
                {
                  name: firstRowName,
                  items: drilledMemberItems,
                },
              ]
            : null,
        //FOR GRAND TOTAL
        values: this.panelDataDetailsMetrics
            .filter(m => {
                return dataSourceSettings?.values?.find(ds => ds.name === m?.field + m?.type);
            })
            .map((metric) => {
            return {
              name: metric?.field + metric?.type,
              caption: metric?.alias,
              type:
                metric?.type ===
                DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT
                  ? DatamodelContextDefaults.SUMMARIZATION_RULE.SUM
                  : metric?.type,
            };
          }),
      };

      this.refreshPivotTable();
    },
    addAndGetDrilledMemberItems(dataSourceSettings, firstRowName) {
      const items = [];
      dataSourceSettings?.dataSource?.forEach((ds) => {
        if (!items?.includes(ds[firstRowName]) && ds[firstRowName]) items.push(ds[firstRowName]?.toString());
      });
      return items;
    },
    createPivotTooltipTemplateComponent() {
      Vue.component("pivotTooltipTemplate", {
        mixins: [panelDataPropertiesStyle],
        props: ["data", "metrics"],
        template: `<div class="vis-pivot-tooltip">
              <div class="vis-pivot-tooltip-row" v-if="data.rowFields && checkAndEditHeader(row) !== null">
                <span class="pivotTooltipLabel">{{data.rowFields}}</span>
                <span class="pivotTooltipValue vis-font-bold">{{checkAndEditHeader(row)}}</span>
              </div>
              <div class="vis-pivot-tooltip-row" v-if="data.columnFields && checkAndEditHeader(column) !== null">
                <span class="pivotTooltipLabel">{{data.columnFields}}</span>
                <span class="pivotTooltipValue vis-font-bold">{{checkAndEditHeader(column)}}</span>
              </div>
              <div class="vis-pivot-tooltip-row">
                <span class="pivotTooltipLabel">{{data.valueField}}</span>
                <span class="pivotTooltipValue vis-font-bold">{{editDataValueByFormat(data)}}</span>
              </div>
            </div>`,
        data() {
          return { column: "column", row: "row" };
        },
        methods: {
          checkAndEditHeader(headerType) {
            let modifiedHeader =
              headerType === "column"
                ? this.data.columnHeaders
                : this.data.rowHeaders;
            const grandTotalsTranslation = this.$t(
              "panelProperties.grandTotals"
            );

            if (modifiedHeader.includes(` - ${this.data.valueField}`)) {
              modifiedHeader = modifiedHeader.replace(
                ` - ${this.data.valueField}`,
                ""
              );
            }
            if (modifiedHeader.includes(grandTotalsTranslation)) {
              modifiedHeader = null;
            }
            return this.formatHeaderDate(modifiedHeader);
          },
          formatHeaderDate(modifiedHeader) {
            const isoDateRegex =
              /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(?:Z|[+-]\d{2}:\d{2})/;
            const isoDateMatch = modifiedHeader?.match(isoDateRegex);
            if (isoDateMatch) {
              const dateString = isoDateMatch[0];
              return moment(dateString).format("DD/MM/YYYY");
            }
            return modifiedHeader;
          },
          editDataValueByFormat(data) {
            const selectedMetric = this.metrics?.find(
              (item) => `${item?.alias}` === data?.valueField
            );

            if (selectedMetric && selectedMetric.isPercentage)
              return data.value;
            if (
              (selectedMetric && data.value) && (data.value !== "null" &&
              data.value !== "No value")
            ) {
              return formatMeasure(data.value, selectedMetric.format);
            }
            return data.value === "null" || data.value === "No value"
              ? "-"
              : data.value;
          },
        },
      });
    },
    tooltipTemplate(args) {
      this.createPivotTooltipTemplateComponent();
      const panelDataDetailsMetrics = cloneDeep(this.panelDataDetailsMetrics);

      return [
        new Vue({
          i18n: this.$i18n,
          template: `<pivotTooltipTemplate :data="tooltipData" :metrics="metrics" />`,
          data() {
            return {
              tooltipData: args,
              metrics: panelDataDetailsMetrics,
            };
          },
        }).$mount().$el,
      ];
    },
    triggerFieldListChanged(filters) {
      const { dataSource, values, ...others } = this.option?.dataSourceSettings;
      let clonedDataSource = cloneDeep(dataSource);
      let clonedValues = cloneDeep(values);
      let parsedFilter = [];

      filters.forEach((f) => {
        parsedFilter.push(JSON.parse(f));
      });

      let parsedFilterRowsColumns = parsedFilter.filter((s) => !s.isMeasures);
      let parsedFilterMeasures = parsedFilter.filter((s) => s.isMeasures);
      const fieldOfParsedFilterMeasures = parsedFilterMeasures.map(
        (s) => s.field
      );

      if (fieldOfParsedFilterMeasures.length)
        clonedValues = clonedValues.filter((c) =>
          fieldOfParsedFilterMeasures.includes(c.name)
        );

      parsedFilterRowsColumns = _.chain(parsedFilterRowsColumns)
        .groupBy("field")
        .map((value, key) => ({ field: key, values: value }))
        .value();

      parsedFilterRowsColumns.forEach((f) => {
        clonedDataSource = cloneDeep(
          clonedDataSource.filter((d) => {
            return f.values.map((v) => v.value).includes(d[f.field]);
          })
        );
      });

      this.setDataSourceSettings(
        cloneDeep({
          ...others,
          dataSource: clonedDataSource,
          values: clonedValues,
        })
      );
    },
    openFieldListPopup() {
      let pivotViewObj = document.getElementById(
        "pivot-table-wrapper" + this.panelId
      );
      let pivotFieldListWrapper = pivotViewObj.getElementsByClassName(
        "e-pivotfieldlist-wrapper"
      );
      pivotFieldListWrapper[0].classList.remove("e-popup-close");
      pivotFieldListWrapper[0].classList.add("e-popup-open");
    },
    pivotHeight() {
      const cardContentHeight = document.querySelectorAll(
        ".vis-chart-card-content"
      );
      const cardHeight = document.querySelectorAll(".vis-chart-card");

      return (
        cardContentHeight?.[0]?.offsetHeight ||
        cardHeight?.[0]?.offsetHeight - 75
      );
    },
    aggregateCellInfo(args) {
      let dataMapped = [];
      const rowCellTypeEqualsGrandTotal = args.rowCellType === "grandTotal";
      const columnCellTypeEqualsGrandTotal = args.columnCellType === "grandTotal";

      const rowCellTypeEqualsSubTotal = args.rowCellType === "subTotal";
      const columnCellTypeEqualsSubTotal = args.columnCellType === "subTotal";

      const fieldName = args.fieldName;
      // Grantotal haricindeki Min ve max değerleri hesapla
      if (
        (rowCellTypeEqualsGrandTotal && !columnCellTypeEqualsGrandTotal) ||
        (!rowCellTypeEqualsGrandTotal && columnCellTypeEqualsGrandTotal)
      ) {
        if (args.cellSets[0]) {
          this.minValue =
            this.minValue < args.cellSets[0][fieldName] && this.minValue !== 0
              ? this.minValue
              : args.cellSets[0][fieldName];
          this.maxValue =
            this.maxValue > args.cellSets[0][fieldName]
              ? this.maxValue
              : args.cellSets[0][fieldName];
        }
      }
      if (rowCellTypeEqualsGrandTotal && args.column.actualText !== this.grandTotal) {
        this.grandTotalValueByFieldKey[args.fieldName + args.column.actualText] = args.value;
      } else if (rowCellTypeEqualsGrandTotal && args.column.actualText === this.grandTotal) {
        this.grandTotalValueByFieldKey[args.fieldName] = args.value;
      }
     
        const selectedMetric = this.panelDataDetailsMetrics.find(
          (item) => `${item?.field}${item?.type}` === args?.fieldName
        );

        const columnBasedFormattingAggregationFunction =
          this.getPanelDataColumnBasedProperties(
            selectedMetric.fieldId,
            PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION,
            PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_AGGREGATION_FUNCTION
          );

        const columnBasedFormattingFormatCell =
            this.getPanelDataColumnBasedProperties(
                selectedMetric.fieldId,
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT,
                PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FORMAT
            );

      if (columnBasedFormattingFormatCell === tableColumnFormatValues.PERCENTAGE) {
        const totalValue =
          args.column.actualText === this.grandTotal
            ? this.grandTotalValueByFieldKey[args.fieldName]
            : this.grandTotalValueByFieldKey[
                args.fieldName + args.column.actualText
              ];

        let calculate = totalValue === 0 ? 0 : (args.value / totalValue) * 100;
        args.value = calculate ? calculate : args.value;
      }

        if (columnBasedFormattingAggregationFunction &&
          columnBasedFormattingAggregationFunction !==
          DatamodelContextDefaults.SUMMARIZATION_RULE.SAME_AS_MEASURE) {

          let aggregation = tableFooterAggregrations[columnBasedFormattingAggregationFunction];
          dataMapped = args?.cellSets?.map((m) => m[args.fieldName]);

          const calculationResult = aggregation?.calculate(
            dataMapped?.map((d) => Number(d))
          );

          let finalResult = (calculationResult === Infinity || calculationResult === -Infinity) ? NaN : calculationResult;
          if (
          (rowCellTypeEqualsGrandTotal ||
            columnCellTypeEqualsGrandTotal ||
            rowCellTypeEqualsSubTotal ||
            columnCellTypeEqualsSubTotal) &&
            !isNaN(finalResult)
        ) {
          args.value = calculationResult;
        }

      }
      

    },

    // eslint-disable-next-line no-unused-vars
    openConditionalFormatting() {
      this.toolbarConditionalRenderingClicked();
    },
    toggleClassForTableSpinnerPane(statu) {
      let spinnerPane = document.querySelectorAll(".e-spinner-pane");

      spinnerPane[0].classList[statu]("vis-display-block");
    },
    callExportNotFormatted(evt) {
     this.currentExportFormatType = this.exportFormatTypes.notFormatted;
     this.isCallExportNotFormatted = true;
     this.callExport(evt);
    },
    callExport(evt) {
      this.toggleClassForTableSpinnerPane("add");
      const { filters, ...others } = this.panelData?.details;
      //Search'ten gelen response 10.000'den büyükse
      if (this.option.isMaxThreshold) {
        let exportType = null;

        if (evt == toolbarOptionsEnum.EXCEL_EXPORT)
          exportType = this.exportTypes.EXCEL;
        else if (evt == toolbarOptionsEnum.CSV_EXPORT)
          exportType = this.exportTypes.CSV;
        else if (evt == toolbarOptionsEnum.PDF_EXPORT)
          exportType = this.exportTypes.PDF;

        this.createExportMetadata({
          chartId: this.option.chartId,
          type: exportType,
          searchQuery: {
            ...others,
            filter: filters,
            dataset: this.panelData?.properties?.dataSet,
            vismetadata: this.panelData?.properties?.vismetadata,
          },
        });
      } else {
        if (!this.isCallExportNotFormatted) {
          this.currentExportFormatType = this.exportFormatTypes.formatted;
        }
        const fileName = this.panelData.name?.length
          ? this.panelData.name
          : "untitled";

        try {
          if (evt == toolbarOptionsEnum.EXCEL_EXPORT)
            this.$refs?.[this.panelId]?.excelExport({
              fileName: fileName + '.xlsx',
            });
          else if (evt == toolbarOptionsEnum.CSV_EXPORT)
            this.$refs?.[this.panelId]?.csvExport({
              fileName: fileName + '.csv',
            });
          else if (evt == toolbarOptionsEnum.PDF_EXPORT)
            this.$refs?.[this.panelId]?.pdfExport({
              fileName: fileName + '.pdf',
            });
        } catch (e) {
          Notify(e, notificationType.WARNING);
        }
      }

      setTimeout(() => {
        this.isCallExportNotFormatted = false;
        this.toggleClassForTableSpinnerPane("remove");
      }, 1000);
    },
    dataBound() {
      //normalde dataBound eventinden baska bir event ile kontrol etmek gerekir fakat api dokumanında yok.
      this.setPivotGridContent();
      this.addFormatToColHeaders();
      this.addFormatToRowHeaders();
    },
    /*beforeToolbarRender: function (args) {
      if (this.allowConditionalFormatting) {
        args.customToolbar.splice(12, 0, {
          prefixIcon: "e-pivotview-expand e-icons",
          tooltipText: "Conditional Rendering",
          click: this.toolbarConditionalRenderingClicked.bind(this),
        });
      }
    },*/
    toolbarConditionalRenderingClicked: function () {
      if (this.allowConditionalFormatting) {
        const pivotGridObj = document.getElementById(this.panelId)
          .ej2_instances[0];

        pivotGridObj.conditionalFormattingModule.showConditionalFormattingDialog();
      }
    },
    containerResizing() {
      this.isContainerResizing = true;
    },
    containerResized() {
        this.isContainerResizing = false;

        this.refreshPivotTable();
    },
    refreshPivotTable() {
        setTimeout(() => {
            const pivotGridObj = document?.getElementById(this.panelId)
                ?.ej2_instances?.[0];
            
            pivotGridObj?.pivotRefresh();
        }, 300);
    },
    modeddlOnChange(value) {
      let pivotObj = this.$refs[this.panelId].ej2Instances;
      pivotObj.gridSettings.selectionSettings.mode = value;
      pivotObj.renderModule.updateGridSettings();
    },
    typeddlOnChange(value) {
      let pivotObj = this.$refs[this.panelId].ej2Instances;
      pivotObj.gridSettings.selectionSettings.type = value;
      pivotObj.renderModule.updateGridSettings();
    },
    handleDblClickDrillThrough() {
      this.doubleClickDetected = true;
    
      if (
        this.drillThroughDashboardList?.length === 1 &&
        this.allowDrillThroughDoubleClick
      ) {
        this.addDrillThroughFilter({
          item: {
            emitFunction: "onChartDrillThroughClick",
            value: this.drillThroughDashboardList?.[0]?.id,
          },
        });
      }
    },
    handleClickDrillThrough(event) {
      this.pageCoord.pageY = event.pageY;
      this.pageCoord.pageX = event.pageX;

      const filterParams = this.pivotContextData.filterParams;

      this.$emit(
        "addFiltersToPanels",
        createChartFilter({ filterParams }, this.panelData)
      );
    },
    handlePivotCell() {
      if (!this.allowSelection || this.selectionMode !== "Cell") return;

      //FOR CELL DOUBLE CLICK
      this.pivotTableWrapper.addEventListener("dblclick", this.handleDblClickDrillThrough);
      
      //FOR CELL SINGLE CLICK
      this.pivotTableWrapper.addEventListener("click", (event) => {
        setTimeout(() => {
          if (!this.doubleClickDetected) {
              this.handleClickDrillThrough(event);
        }
        }, 300);
        this.doubleClickDetected = false;
      });
    },
    setPivotTableColumnWidthWithResize(args) {
      //Şuan sadece measures alanlar için burası çalışacak.
      const selectedField = this.panelDataDetailsMetrics.find(
        (x) => x.field + x.type === args.data.actualText
      );
      
      if (!selectedField) return;

      this.isResizingCellWithMouse = true;

      this.$emit("setPivotTableColumnBasedComponentProperties", {
        property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
        value: args.currentCell.clientWidth,
        fieldData: selectedField,
      });

      setTimeout(() => {
        this.isResizingCellWithMouse = false;
      }, 500);
    },
    cellSelecting(args) {
      this.setPivotTableColumnWidthWithResize(args);

      //single click and double click works on handlePivotCell function in mounted
      if (!this.isSelectionCellActive && (!this.allowSelection || this.selectionMode !== "Cell")) return;
      
      let columns = "",
        rows = "";
        
      if (args?.data?.axis === "value") {
        columns = args?.data?.columnHeaders;
        rows = args?.data?.rowHeaders;
      } else if (args?.data?.axis === "column") {
        columns = args?.data?.valueSort?.levelName;
      } else {
        rows = args?.data?.valueSort?.levelName;
      }

      this.pivotContextData.filterParams = {
        columnHeaders: columns?.split("."),
        rowHeaders: rows?.split("."),
      };

      this.$emit("setContextClickedChartItemId", this.panelData.i);

      const columnHeaderText =
        this.pivotContextData.filterParams.columnHeaders.join(" - ");
      const rowHeaderText =
        this.pivotContextData.filterParams.rowHeaders.join(" - ");

      this.$emit(
        "setClickedChartItemName",
        columnHeaderText === "" && rowHeaderText === ""
          ? null
          : columnHeaderText + " " + rowHeaderText
      );

      document
        .getElementById(this.panelId)
        .addEventListener("contextmenu", () => {
          window.event.returnValue = false;
          if (
            Object.keys(this.pivotContextData?.filterParams)?.length &&
            this.pageCoord.pageY
          ) {
            this.openContext(this.pageCoord.pageY, this.pageCoord.pageX);
          }
        });
    },
    selectContextItem(args) {
      if (args.item.notClicked) return null;

      let contextMenuItemClicked = {
        [chartContextMenuItemKey.INCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.EXCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.DRILL_THROUGH]: {
          serviceFn: () => this.addDrillThroughFilter(args),
        },
      };

      contextMenuItemClicked[args.item.key].serviceFn();

      this.getPivotContextMenu().close();
    },
    addDrillThroughFilter(args) {
      this.pivotContextData.drillThrough = true;
      this.pivotContextData.isActiveFilterFromMenu = true;
      const obj = createChartFilter(this.pivotContextData, this.panelData);

      const filterObj = {
        filterValue: obj.value,
        filterId: obj.panelI,
      };

      this.$emit(
        args.item.emitFunction, //emit method name
        filterObj,
        args.item.value //Context meuden seçilen dashboardId geliyor.
      );
    },
    addIncludeOrExcludeFilter(args) {
      this.pivotContextData.isActiveFilterFromMenu = true;

      const obj = createChartFilter(this.pivotContextData, this.panelData);

      obj.isDrillDown = true;
      obj.notRunTheViewChart = args?.item?.notRunTheViewChart;

      if (args.item.key == chartContextMenuItemKey.EXCLUDE)
        obj.operator = filterOperator.NOTEQ;

      this.$emit(args.item.emitFunction, obj);
    },
    getPivotContextMenu() {
      return document.getElementById(this.contextMenuId).ej2_instances[0];
    },
    openContext(y, x) {
      this.getPivotContextMenu().open(y, x);
    },
    beforeContextItemHighlight(args) {
      if (args?.item?.title) {
        args.element.innerHTML = `<span class="vis-ellipsis-context-menu vis-font-medium">${args.item.text}</span>`;
      }
    },
    onActionComplete(event) {
      if (event?.actionName?.includes("page")) {
        const { currentRowPage, currentColumnPage } =
          this.$refs?.[this.panelId]?.ej2Instances.pageSettings.properties;

        this.activeCurrentRowPage = currentRowPage;
        this.activeCurrentColumnPage = currentColumnPage;
      }

      if (!event || !this.option.isMaxThreshold) return;

      const {
        actionName,
        fieldInfo: { name },
        actionInfo: {
          valueSortInfo: { sortOrder },
        },
      } = event;

      if (actionName === ejsPivotTableActionNames.VALUE_SORTED) {
        this.sortByServerSide(sortOrder, name);
      }
    },
    sortByServerSide(direction, columnName) {
      const sortMethod = ejsTableSortTypeConversion[direction] || null;

      const clonedPanel = cloneDeep(this.panelData);

      const { metrics, aggregation } = clonedPanel.details;
      const columns = [...metrics, ...aggregation];

      const fieldToUpdate = columns.find((i) => i.field === columnName);

      // reset orders as ordering on table supports a single column at a time.
      columns.forEach((element) => {
        element.order = null;
      });

      if (fieldToUpdate) fieldToUpdate.order = sortMethod;

      this.$emit("updateAndViewSelectedPanel", clonedPanel);
    },
    addFormatToRowHeaders() {
      // Rows headerlarına format eklendiğinde ilgili başlıkları formatlı halinde göstermesi için bu kısım düzenlendi!
      let grid = document.getElementById(this.panelData.i + "_grid")
        ?.ej2_instances?.[0];

      let rows = grid?.element?.querySelectorAll(".e-rowcell.e-rowsheader");
      for (let a = 0; a < rows?.length; a++) {
        const fieldName = rows[a].getAttribute("fieldname");

        const findElement = this.allAggregations.find(
          (item) => item?.field === fieldName
        );
        if (findElement?.format) {
          let rowHeader = rows[a]?.getElementsByClassName("e-cellvalue");

          const formattedValue = formatMeasure(
            rowHeader[0]?.outerText,
            findElement?.format
          );

          if (formattedValue === "Invalid date" || !rowHeader?.[0]?.textContent) return;
          
          rowHeader[0].textContent = formattedValue;
        }
      }
    },
    addFormatToColHeaders() {
      //TODO addFormatToRowHeaders methodu ile ortak bir yapıya çıkarılacak. Sürüm sonrası bakılacak.
      let grid = document.getElementById(this.panelData.i + "_grid")
        ?.ej2_instances?.[0];

      let cols = grid?.element?.querySelectorAll(
        ".e-columnheader .e-headercell"
      );
      for (let a = 0; a < cols?.length; a++) {
        const fieldName = cols[a].getAttribute("fieldname");

        const findElement = this.allAggregations.find(
          (item) => item?.field === fieldName
        );

        if (findElement?.format) {
          let colHeader = cols[a]?.getElementsByClassName("e-headertext");

          const formattedValue = formatMeasure(
            colHeader[0]?.outerText,
            findElement?.format
          );

          if (formattedValue === "Invalid date" || !colHeader?.[0]?.textContent) return;
        
          colHeader[0].textContent = formattedValue;
        }
      }
    },
    getFieldByColumnName(fieldAlias) {
      return this.panelDataDetailsMetrics.find((item) => {
        return item.alias === fieldAlias;
      });
    },
    getHeaderFieldByColumnName(fieldAlias) {
      return this.allAggregations.find(
        (item) => item?.field === fieldAlias
      );
    },
    getColumnBasedRowHeaderFormatting(fieldName) {
      if (fieldName) {
        const field = this.getHeaderFieldByColumnName(fieldName);

        if (field) {
          const columnBasedFormattingHeaderFontFamily =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_FAMILY,
              field.slotFieldId
            );

          const columnBasedFormattingHeaderFontSize =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_SIZE,
              field.slotFieldId
            );
          const columnBasedFormattingHeaderFontWeight =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
              field.slotFieldId
            );
          const columnBasedFormattingHeaderFontColor =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_COLOR,
              field.slotFieldId
            );
          const columnBasedFormattingHeaderColumnTextWrap =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TEXT_WRAP,
              field.slotFieldId
            );
          const columnBasedFormattingHeaderColumnAlign =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
              field.slotFieldId
            );
          const columnBasedFormattingHeaderWidth =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_WIDTH,
              field.slotFieldId
            );
          const columnBasedRowMaxCharacter =
            this.getPanelDataColumnBasedProperties(
              field.fieldId,
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER,
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_MAX_CHARACTER,
            );

          return {
            columnBasedFormattingHeaderFontFamily,
            columnBasedFormattingHeaderFontSize,
            columnBasedFormattingHeaderFontWeight,
            columnBasedFormattingHeaderFontColor,
            columnBasedFormattingHeaderColumnTextWrap,
            columnBasedFormattingHeaderColumnAlign,
            columnBasedFormattingHeaderWidth,
            columnBasedRowMaxCharacter
          };
        }
      }

      return {};
    },
    cellColour(value) {
      let percentage =
        (this.maxValue - this.minValue) / this.colourScheme.length;

      let colourIndex = Math.round((value - this.minValue) / percentage);

      if (colourIndex < 0) {
        colourIndex = 0;
      } else if (colourIndex >= this.colourScheme.length) {
        colourIndex = this.colourScheme.length - 1;
      }

      return this.colourScheme[colourIndex];
    },
    getCellContent(data) {
      let content = "";

      if (data != null && data.cellInfo && data.cellInfo.axis !== "row") {
        const cellInfo = data.cellInfo;
        const columns = this.onlyAggregationColumns && cellInfo.isGrandSum && cellInfo.columnHeaders !== "";
        const rows = this.onlyAggregationRows && cellInfo.isGrandSum && cellInfo.rowHeaders !== "";

        if (cellInfo.axis === "value" && (!cellInfo.isGrandSum || columns || rows)) {
          
          // Hücrenin arka plan rengini belirle
          const cellColor = this.cellColour(cellInfo.actualValue);
          data.targetCell.classList.add(cellColor);
        }
        // Hücre değerini formatla ve içeriğe ekle
        if (cellInfo.actualValue)
          content = (cellInfo.actualValue / 1000.0).toFixed(1) + "K";
      }
      return content;
    },
    getCellConditionalContent(data) {
      const cellInfo = data.cellInfo;
      const conditionalFormattings =
        this.panelData?.properties?.conditionalFormattings;

      if (
        conditionalFormattings &&
        conditionalFormattings?.length > 0 &&
        data != null &&
        data.cellInfo &&
        data.cellInfo.axis !== "row"
      ) {
        conditionalFormattings?.forEach((formatting) => {
          const fieldInfo = this.panelDataDetailsColumns?.find(
            (x) => x.fieldId === formatting?.fieldId
          );

          if (!fieldInfo) return false;
          formatting.conditions.forEach((condition) => {
            if (
              condition.rules.some((rule) =>
                this.checkRule(rule, cellInfo, fieldInfo)
              )
            ) {
              const formatType = formatting.formatType;
              let spanChildItemIndex = 0;

              if (formatType === "FORMAT_ICON") {
                condition.iconFormat?.iconPosition ===
                iconFormatPositionValue.LEFT
                  ? (spanChildItemIndex = 1)
                  : (spanChildItemIndex = 0);
                this.applyIconFormat(
                  data.targetCell,
                  condition.iconFormat,
                  spanChildItemIndex
                );
              } else if (formatType === "FORMAT_VALUE") {
                this.applyValueFormat(condition.format, data);
              }
            }
          });
        });
      }
    },
    applyIconFormat(args, conditionFormat, spanChildItemIndex) {
      const sizeType = "px";
      const setChildNode = (iconChildItemIndex) => {
        args.childNodes[iconChildItemIndex].className =
          conditionFormat.iconName;
        args.childNodes[iconChildItemIndex].style.color =
          conditionFormat.iconColor;
        args.childNodes[iconChildItemIndex].style.fontSize =
          conditionFormat.iconSize + sizeType;
        args.childNodes[iconChildItemIndex].style.verticalAlign = "middle";
        args.childNodes[
          spanChildItemIndex
        ].style.padding = `0${sizeType} 2${sizeType}`;
        args.childNodes[spanChildItemIndex].style.verticalAlign = "middle";
        if (
          conditionFormat.iconShowOption === iconFormatShowType.ICON_ONLY &&
          conditionFormat?.customText === ""
        ) {
          args.childNodes[spanChildItemIndex].style.display = "none";
          args.style.textAlign = conditionFormat.iconPosition;
        } else if (
          conditionFormat?.customText !== "" &&
          conditionFormat.iconShowOption === iconFormatShowType.ICON_VALUE_ONLY
        ) {
          this.$nextTick(() => {
            args.childNodes[spanChildItemIndex].innerHTML =
              conditionFormat.customText;
          });
        }
      };
      let iconChildItemIndex = 1;
      let i = document.createElement("i");

      args.append(i);

      if (conditionFormat?.iconPosition === iconFormatPositionValue.LEFT) {
        const arr = Array.from(args.childNodes);
        arr.reverse();
        args.append(...arr);
        iconChildItemIndex = 0;
      } else iconChildItemIndex = 1;
      setChildNode(iconChildItemIndex);
    },

    applyValueFormat(format, data) {
      const targetCell = data?.targetCell;
      const targetCellSpan = data?.targetCell?.children[0];
      //Grand Total alanlarını boyamamak için!!
      if (targetCell?.className.includes("e-gtot")) return;

      targetCell.style.backgroundColor = format.backgroundColor;
      targetCell.style.fontFamily = format.fontFamily;
      targetCell.style.fontSize = format.fontSize + "px";
      targetCell.style.fontWeight = format.fontWeight;
      targetCell.style.color = format.fontColor;
      targetCellSpan.style.backgroundColor = format.chipColor;

      if (
        format?.customText !== "" &&
        format.valueShowOption === valueFormatShowType.TEXT_ONLY
      ) {
        this.$nextTick(() => {
          targetCellSpan.innerHTML = format.customText;
        });
      } else if (
        format?.customText !== "" &&
        format.valueShowOption === valueFormatShowType.VALUE_AND_TEXT
      ) {
        this.$nextTick(() => {
          targetCellSpan.innerHTML = targetCellSpan.innerHTML + " " + format.customText;
        });
      }
    },

    checkRule(rule, cellInfo, fieldInfo) {
      let rowValue = cellInfo?.actualValue;
      let firstValue = rule.value1;
      let secondValue = rule.value2;

      if (cellInfo.actualValue === "" || null) return false;
      if (
        fieldInfo?.fieldUsageType ===
        DatamodelContextDefaults.USAGE_TYPES.MEASURE
      ) {
        const cellItem = fieldInfo?.field + fieldInfo?.type;
        if (cellInfo.actualText !== cellItem) return false;
      }
      if (
        !rule?.usageType &&
        fieldInfo?.fieldUsageType ===
          DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      ) {
        const dataItem = rule.dataItem.replace(/undefined/g, "");
        if (cellInfo.actualText !== dataItem) return false;
      }

      if (rule?.usageType === DatamodelContextDefaults.USAGE_TYPES.DATE) {
        const formatType = "YYYY/MM/DD";
        const dataItem = rule.dataItem.replace(/undefined/g, "");
        const dataItemInfo = this.panelDataDetailsColumns?.find(
          (x) => x.field + x.type === dataItem
        );

        if (dataItemInfo?.boxKey === "row") {
          rowValue = cellInfo?.rowHeaders;
        } else {
          rowValue = cellInfo?.columnHeaders;
        }
        const dateRowFormatted = moment(rowValue).format(formatType);
        const dateFirstValFormatted = moment(firstValue).format(formatType);
        const dateSecondValFormatted = moment(secondValue).format(formatType);

        rowValue = new Date(dateRowFormatted).getTime();
        firstValue = new Date(dateFirstValFormatted).getTime();
        secondValue = new Date(dateSecondValFormatted).getTime();
      } else if (
        rule?.usageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      ) {
        const dataItem = rule.dataItem.replace(/undefined/g, "");
        const dataItemInfo = this.panelDataDetailsColumns?.find(
          (x) => x.field === dataItem
        );

        if (dataItemInfo?.boxKey === "row") {
          rowValue = cellInfo?.rowHeaders;
        } else if (dataItemInfo?.boxKey === "column") {
          rowValue = cellInfo?.columnHeaders;
        }
      }

      const operators = {
        [conditionalOperatorValues.QT]: rowValue > firstValue,
        [conditionalOperatorValues.LT]: rowValue < firstValue,
        [conditionalOperatorValues.EQ]: rowValue == firstValue,
        [conditionalOperatorValues.NOT_EQ]: rowValue != firstValue,
        [conditionalOperatorValues.QTE]: rowValue >= firstValue,
        [conditionalOperatorValues.LTE]: rowValue <= firstValue,
        [conditionalOperatorValues.BETWEEN]:
          rowValue >= firstValue && rowValue <= secondValue,
        [conditionalOperatorValues.NOT_BETWEEN]:
          rowValue <= firstValue || rowValue >= secondValue,
        [conditionalOperatorValues.IN]: (Array.isArray(firstValue)
          ? firstValue
          : [firstValue]
        ).some((val) => rowValue?.toString()?.includes(val)),
        [conditionalOperatorValues.NOT_IN]: !(
          Array.isArray(firstValue) ? firstValue : [firstValue]
        ).some((val) => rowValue?.toString()?.includes(val)),
      };

      if (operators[rule.operator]) {
        return true;
      }
    },
    updateCellValue(cellElement, headerText) {
      let selectedMetricFormat = null;

      if (this.panelDataDetailsMetrics?.length == 1) {
        selectedMetricFormat = this.panelDataDetailsMetrics[0]?.format;
      } else {
        selectedMetricFormat = this.getFieldByColumnName(headerText)?.format;
      }

      if (
        selectedMetricFormat &&
        cellElement?.innerText &&
        cellElement?.innerText !== "null" &&
        !this.selectedColumnFormat?.cellPercentage
      ) {
        cellElement.innerText = formatMeasure(
          cellElement?.innerText,
          selectedMetricFormat
        );
      } else if (cellElement?.innerText === "null") {
        cellElement.innerText = "";
      }
    }
  },
};
</script>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/bootstrap5.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
</style>

<style scoped>
::v-deep .e-pivotview .e-grid .e-gridheader {
  padding-right: 10px !important;
}
::v-deep .e-grid .e-table {
  border-right: 1px solid;
  border-right-color: #dee2e6;
}
::v-deep .e-pivotview .e-pivot-pager.e-device {
  overflow: hidden !important;
}
::v-deep .e-pivotview .e-pivot-pager.e-device .e-pivot-pager-maindiv {
  flex-direction: row !important;
}
::v-deep .e-toolbar.e-control {
  width: 100% !important;
}
::v-deep .e-pivotview,
::v-deep .e-pivotview .e-grid {
  min-width: auto !important;
}
::v-deep .e-pivotview .e-valuescontent {
  opacity: 1 !important;
}

.e-pivotview {
  height: 100%;
}
::v-deep .e-field-list-filters {
  display: none !important;
}
::v-deep .e-field-list-values {
  display: none !important;
}
::v-deep .e-field-list-rows {
  margin-top: 0 !important;
  height: 100% !important;
}
::v-deep .e-field-list-columns {
  height: 100% !important;
}
::v-deep .e-calculated-field {
  display: none !important;
}
::v-deep .e-toggle-field-list {
  display: none !important;
}
::v-deep .e-pivotfieldlist-wrapper {
  position: fixed !important;
}
::v-deep .e-dlg-header-content {
  cursor: default !important;
}
::v-deep .e-field-table {
  display: none !important;
}
::v-deep .e-axis-table {
  width: 100% !important;
}
::v-deep .e-columnheader:first-child .e-sortfilterdiv {
  font-size: 0.813rem !important;
  padding: 10px 0 !important;
}
::v-deep .e-headercell .e-sortfilterdiv {
  padding: 18px 0 !important;
  font-size: 0.813rem !important;
}
::v-deep .vis-ellipsis-context-menu {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
::v-deep .e-contextmenu-wrapper ul .e-menu-item {
  padding: 0 !important;
}
::v-deep .e-blankicon {
  padding-left: 10px !important;
}
::v-deep .e-icons {
  font-size: 9px !important;
}
::v-deep .e-pivotview-row-pagerString,
::v-deep .e-pivotview-column-pagerString {
  display: flex;
  margin-left: 1rem;
  align-items: center;
}

::v-deep .sb-sample-content-area {
  min-height: 255px !important;
}

::v-deep .control-section {
  min-height: 255px !important;
}

::v-deep #PivotView-Heatmap .e-collapse {
  display: none;
}

::v-deep .range1 {
  background-color: #fb6262 !important;
  border-color: #f26666 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range2 {
  background-color: #ff6c6c !important;
  border-color: #ff6c6c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range3 {
  background-color: #ff8474 !important;
  border-color: #ff8474 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range4 {
  background-color: #ffa474 !important;
  border-color: #ffa474 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range5 {
  background-color: #ffac7c !important;
  border-color: #ffac7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range6 {
  background-color: #ffcc7c !important;
  border-color: #ffcc7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range7 {
  background-color: #ffe484 !important;
  border-color: #ffe484 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range8 {
  background-color: #f8e484 !important;
  border-color: #f8e484 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range9 {
  background-color: #e0dc84 !important;
  border-color: #e0dc84 !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range10 {
  background-color: #b8d47c !important;
  border-color: #b8d47c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range11 {
  background-color: #a8d47c !important;
  border-color: #a8d47c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range12 {
  background-color: #8bc97d !important;
  border-color: #8bc97d !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range13 {
  background-color: #68bc7c !important;
  border-color: #68bc7c !important;
  font-weight: 500;
  color: black !important;
  text-shadow: 0px 0px black;
}

::v-deep .range14 {
  background-color: #66bb71 !important;
  color: black !important;
  font-weight: 500;
  border-color: #66bb71 !important;
  text-shadow: 0px 0px black;
}
</style>
