<template>
  <div
    @click.stop="$emit('onTableWrapperClicked')"
    class="vis-height-100"
    :class="['ejs-table-container' + panelData.i]"
    :id="containerId"
  >
    <ejs-grid
      class="isDisableEmptyRow no-drag"
      v-if="tableRows.length || tableColumns.length"
      ref="grid"
      :id="panelData.i"
      :dataSource="tableRows"
      :allowKeyboard="false"
      :allowExportMenu="datatableEnableExportMenu"
      :allowExcelExport="datatableEnableExcelExport"
      :allowCSVExport="datatableEnableCSVExport"
      :allowPdfExport="datatableEnablePDFExport"
      :allowPaging="datatableEnablePaging"
      :allowSorting="datatableEnableSorting"
      :allowFiltering="datatableEnableFiltering"
      :allowResizing="datatableEnableResizing"
      :allowReordering="datatableEnableReordering"
      :allowSelection="datatableEnableSelection"
      :allowTextWrap="datatableEnableTextWrap"
      :allowContextMenu="datatableEnableContextMenu"
      :rowHeight="datatableRowHeight"
      :pageSettings="pageSettings"
      :filterSettings="filterSettings"
      :toolbar="toolbarOptions"
      :allowGrouping="isGroupingAllowed"
      :groupSettings="groupOptions"
      :headerCellInfo="customiseHeaderCell"
      :queryCellInfo="customiseCell"
      :exportGroupCaption="exportGroupCaption"
      :excelQueryCellInfo="customiseCellExport"
      :pdfQueryCellInfo="customiseCellExport"
      :gridLines="datatableGridLines"
      :actionComplete="tableActionComplete"
      :actionFailure="actionFailure"
      :actionBegin="actionBeginSettings"
      :recordClick="recordClick"
      :recordDoubleClick="recordDoubleClick"
      :rowDataBound="rowDataBound"
      :dataBound="dataBound"
      :excelExportComplete="exportComplete"
      :pdfExportComplete="exportComplete"
      :resizeStop="resizeStop"
      :pdfHeaderQueryCellInfo="pdfHeaderQueryCellInfo"
    >
      <e-columns>
        <e-column
          v-for="(item, index) in tableColumns"
          :key="index"
          :field="item.field"
          :headerTemplate="() => customHeaderCell(item.field, item.slotFieldId)"
          :template="customCellTemplate"
          :headerText="setHeaderColumnBasedFormattingRename(item)"
          :slotFieldId="item.slotFieldId"
          :width="isExporting ? null : columnWidths[item.slotFieldId]"
          :textAlign="item.align"
          :headerTextAlign="
            getHeaderColumnBasedFormattingAlign(
              item.field,
              datatableColumnAlign,
              item.align,
              item.slotFieldId
            )
          "
          :type="getFieldType(item.fieldType)"
        >
        </e-column>
      </e-columns>
      <e-aggregates v-if="isActiveAggregate">
        <e-aggregate
          v-for="aggregate in tableAggregateColumns"
          :key="aggregate.id"
        >
          <e-columns>
            <e-column
              v-for="(item, index) in tableColumnsNumeric"
              :key="index"
              :field="item.field"
              type="Custom"
              :customAggregate="
                (data, field) => formatFooterValues(data, field, item)
              "
              :[aggregate.footerTemplate]="() => footerAggregations(item)"
              :[aggregate.groupFooterTemplate]="subFooterAggregations"
              :[aggregate.groupCaptionTemplate]="captionAggregations"
            ></e-column>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    </ejs-grid>
    <div class="vis-text--center" v-if="!panelData.details.columns.length">
      <img
        :class="{ 'vis-chart-placeHolder': isPanelPage }"
        src="../../../assets/images/chartEmptyStates/TABLE_CHART.svg"
        alt="TABLE_CHART"
      />
    </div>
    <ejs-contextmenu
      class="chart-context"
      :id="contextMenuId"
      :items="contextMenuItems"
      :select="selectContextItem"
    ></ejs-contextmenu>
  </div>
</template>
<script>
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import {
  GridPlugin,
  Page,
  Sort,
  Filter,
  Resize,
  Reorder,
  Edit,
  ExcelExport,
  PdfExport,
  Toolbar,
  Group,
  Aggregate,
  Search,
  ContextMenu,
} from "@syncfusion/ej2-vue-grids";
import { Workbook } from '@syncfusion/ej2-excel-export';
import {
  toolbarOptions as toolbarOptionsEnum,
  ejsTableOperatorConversion,
  ejsTableSortTypeConversion,
  ejsTablePropertyType,
  tableFooterAggregrations,
  tableFooterAggregrationsForAttributes,
} from "../../../commons/tableData";
import {
  allPropertiesKeys,
  datatablePropertiesEnum,
  tableColumnFormatValues,
  tableSubTotalPositionsEnums,
} from "../../../commons/dashboardProperties.js";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { formatMeasure } from "../../panel/measures/js/formatMeasures";
import { measureTypes } from "../../panel/measures/js/measures";
import {
  DatamodelContextDefaults,
  alignItems,
} from "../../../commons/dataModelTypes";
import { getPanelDataPropertiesStyle } from "../../../util/panelDataPropertiesStyle";
import { ACTION as ACTION_EXPORT_METADATA } from "../../../store/modules/Visualize/ExportMetaData/types";
import { ACTION as ACTION_IMAGES } from "../../../store/modules/Visualize/UploadImages/types.js"
import { GETTER as GETTER_USER } from "../../../store/modules/Visualize/User/types";
import { mapActions, mapGetters } from "vuex";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import {
  getTableSelectedRowValue,
  aggregateColumnProps,
} from "../../../util/data-table/tableFilterHelper";
import deepEqual from "deep-equal";
import cloneDeep from "clone-deep";
import { chartTypes, detailsKeys } from "../../../commons/dashboardAndPanel";
import { createFilterObj } from "../../../util/dashboard-filter/dashboardFilter";
import { operator } from "../../panel/filters/js/filters";
import { PdfTrueTypeFont, PdfSolidBrush, PdfColor } from "@syncfusion/ej2-pdf-export";
import { arialFont } from "./datasource";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { createChartFilter } from "../../../util/chart-filter/filterValueSetters";
import {
  conditionalOperatorValues,
  iconFormatPositionValue,
  iconFormatShowType,
  valueFormatShowType
} from "../../panel/conditionalFormatting/conditionalFormattingHelper";
import { datatableColumnsDefaultConfigurations } from "../../../mocks/datatableColumnsDefaultProperties";
import { conditionFormatType } from "../../panel/conditionalFormatting/conditionalFormattingHelper";
import { changeVisTypeForSearchParamByAggregateResult, SLOT_FIELD_ID_PREFIX } from "../../../util/data-table/tableCommonsHelper";
import { chartContextMenuItemKey } from "../../../util/chart-context-menu/chartContextMenuHelper";
import { filterOperator } from "../../../commons/filterComponents";
import moment from "moment";
import { includesValues } from "../../../commons/Enum.js";
import { hexColorToRGBConvertWithObject } from "../../../util/chartOptionsHelper.js";
import _ from "lodash";

Vue.use(GridPlugin);

export default {
  mixins: [panelDataPropertiesStyle],
  provide: {
    grid: [
      Page,
      Sort,
      Filter,
      Resize,
      Reorder,
      Edit,
      ExcelExport,
      PdfExport,
      Toolbar,
      Group,
      Aggregate,
      Search,
      ContextMenu,
    ],
  },
  props: {
    option: {
      type: Object,
      default() {
        return { arr: [] };
      },
    },
    panelData: {
      type: Object,
    },
    dashboardName: {
      type: String,
      default: "",
    },
    isChartRefreshTriggerActive: {
      type: Boolean,
      default: false,
    },
    isPanelPage: {
      type: Boolean,
    },
    contextMenuItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterChartList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pageOrientationTypes: {
        Portrait: "Portrait",
        Landscape: "Landscape",
      },
      pageSizeTypes: {
        A4: "A4",
        A3: "A3",
        A2: "A2",
        A1: "A1",
        A0: "A0",
      },
      exportTypes: {
        EXCEL: "EXCEL",
        CSV: "CSV",
        PDF: "PDF",
      },
      formatType: {
        decimal: "decimal",
      },
      exportableObject: "CHART",
      allPropertiesKeys: allPropertiesKeys,
      filterSettings: { type: "CheckBox" },
      containerId: "",
      currentExportFormatType: null,
      exportFormatTypes: {
        formatted: "formatted",
        notFormatted: "notFormatted",
      },
      localizationMapping: {
        en: "en-US",
        tr: "tr-TR",
      },
      gridContentStyleCounter: 0,
      tableAggregateItems: {},
      conditionalFormattingByRulesAndCell: {},
      isActiveAggregate: true,
      isExporting: false,
      tableCalculationResult: {},
      tableContextMenuData: {},
      pageCoord: { pageX: "", pageY: "" },
      tableFilterValue: [],
      clickTimeout: null,
      conditionalFormattingByRow: {},
    };
  },
  watch: {
    userPreferences: {
      deep: true,
      handler() {
        this.setCulture();
      },
    },
    "panelData.w": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        
        setTimeout(() => {
          this.setTableGridContentStyle();
        }, 500);
      },
    },
    "panelData.h": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        
        setTimeout(() => {
          this.setTableGridContentStyle();
        }, 500);
      },
    },
    isChartRefreshTriggerActive: {
      handler() {
        this.setTableGridContentStyle();
      },
    },
    "panelData.properties.style": {
      deep: true,
      handler() {
        this.isActiveAggregate = false;
        setTimeout(() => {
          this.isActiveAggregate = true;
        }, 300);
      },
    },
    "option.isMaxThreshold": {
      handler() {
        this.setLocalizations();
      },
    },
    "option.arr": {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal || _.isEqual(newVal, oldVal)) return;
        
        this.setTableConditionalFormatting();
      },
    },
  },

  mounted() {
    if (!this.isPanelPage) this.checkAndSetAggregateResult();
    this.containerId = uuidv4();
    this.setLocalizations();
    this.setCulture();
    this.setPageCoordAndOpenContextMenu();

    //for backend schedule screen shot
    const gridItem = document.getElementById(
      this.panelData.id || this.panelData.i
    );

    if (gridItem) gridItem.setAttribute("draw-finished", true);
  },
  computed: {
    ...mapGetters({ userPreferences: GETTER_USER.GET_USER_PREFERENCES }),
    columnWidths() {
      const widths = {};

      this.panelDataDetailsColumns.forEach((column) => {
        const columnBasedFormattingColumnWidth =
          this.getPanelDataColumnBasedProperties(
            column.fieldId,
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_WIDTH,
            column.slotFieldId
          );

        if (
          column.fieldUsageType ===
            DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
          getPanelDataPropertiesStyle(
            this.panelData,
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
          )
        ) {
          widths[column.slotFieldId] = columnBasedFormattingColumnWidth ?? this.datatableMinimumColumnWidth;
        } else
          widths[column.slotFieldId] = columnBasedFormattingColumnWidth ?? this.datatableMinimumColumnWidth;
      });

      return widths;
    },
    contextMenuId() {
      return `chartMenu_${this.chartId}`;
    },
    chartId() {
      return this.option?.chartId ? this.option?.chartId : this.panelData.id;
    },
    toolbarOptions() {
      const options = [
        this.datatableEnableSearch ? toolbarOptionsEnum.SEARCH : null,
      ].filter((x) => x);

      return options?.length ? options : null;
    },
    pageSettings() {
      return {
        pageSize: this.datatablePageSize,
      };
    },
    tableRows() {
      return this.totalItems ? this.percentCalculatedOptionArr() : [];
    },
    totalItems() {
      return this.option?.arr?.length;
    },
    tableColumns() {
      return getPanelDataPropertiesStyle(
        this.panelData,
        datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
      )
        ? this.aggregateResultColumns
        : this.notAggregateResultColumns;
    },
    aggregateResultColumns() {
      return (
        this.panelDataDetailsColumns
          ?.map((a) => {
            const fieldMeasure = a.slotFieldId
              ? a.field + a.type + a.slotFieldId
              : a.field + a.type;
            const fieldAgg = a.slotFieldId ? a.field + a.slotFieldId : a.field;
            return DatamodelContextDefaults.USAGE_TYPES.MEASURE ===
              a.fieldUsageType
              ? {
                  field: fieldMeasure,
                  alias: a.alias,
                  align: this.getColumnBasedFormattingAlign(
                    fieldMeasure,
                    this.datatableColumnAlign,
                    alignItems.RIGHT,
                    a?.slotFieldId
                  ),
                  fieldType: a?.fieldType,
                  slotFieldId: a?.slotFieldId,
                }
              : {
                  field: fieldAgg,
                  alias: a.alias,
                  align: this.getColumnBasedFormattingAlign(
                    fieldAgg,
                    this.datatableColumnAlign,
                    alignItems.LEFT,
                    a?.slotFieldId
                  ),
                  fieldType: a?.fieldType,
                  slotFieldId: a?.slotFieldId,
                };
          })
          .filter((a) => a) ?? []
      );
    },
    notAggregateResultColumns() {
      return (
        this.panelDataDetailsColumns
          ?.map((a) => ({
            field: a.slotFieldId ? a.field + a.slotFieldId : a.field,
            alias: a.alias,
            fieldType: a?.fieldType,
            slotFieldId: a.slotFieldId,
            align:
              DatamodelContextDefaults.USAGE_TYPES.MEASURE === a.fieldUsageType
                ? this.getColumnBasedFormattingAlign(
                    a.slotFieldId ? a.field + a.slotFieldId : a.field,
                    this.datatableColumnAlign,
                    alignItems.RIGHT,
                    a?.slotFieldId
                  )
                : this.getColumnBasedFormattingAlign(
                    a.slotFieldId ? a.field + a.slotFieldId : a.field,
                    this.datatableColumnAlign,
                    alignItems.LEFT,
                    a?.slotFieldId
                  ),
          }))
          .filter((a) => a) ?? []
      );
    },
    tableColumnsNumeric() {
      return (
        this.panelDataDetailsColumns
          ?.filter(
            (item) =>
              item.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE ||
              item.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
          )
          .map((item) => ({
            field:
              item.fieldUsageType ===
                DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
              getPanelDataPropertiesStyle(
                this.panelData,
                datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
              )
                ? item.field + item.type + item.slotFieldId
                : item.field + item.slotFieldId,
            type: item.type,
            fieldUsageType: item.fieldUsageType,
          })).filter((a) => a) ?? []
      );
    },
    isGroupingAllowed() {
      return this.panelDataDetailsSections?.length > 0;
    },
    groupOptions(a) {
      const columns =
        this.panelDataDetailsSections
          ?.map((item) => item?.field + item?.slotFieldId)
          .filter((a) => a) ?? [];
          
      return {
        showDropArea: false,
        columns,
        showGroupedColumn: true,
        disablePageWiseAggregates: true,
        captionTemplate: () => ({
          template: Vue.component("captionTemplate", {
            template: `<span title=""  style="color: ${this.datatableSectionFontColor};
                             font-family: ${this.datatableSectionFontFamily};
                             font-size: ${this.datatableSectionFontSize}px;
                             font-weight: ${this.datatableSectionFontWeight};
                             margin-left: 5px;"
                          >{{data.headerText}}: {{data.key}}</span>`,
            data() {
              return { data: {} };
            },
            mounted() {
              let fieldFormat = a.panelDataDetailsSections?.find(x=> x.field + x.slotFieldId === this.data.field).format;
              this.data.key = formatMeasure(this.data.key, fieldFormat);
            }
          }),
        }),
      };
    },
    hasPanelDataServerSideFilters() {
      return !this.option.isMaxThreshold &&
        this.panelDataDetailsServersideFilters?.length
        ? true
        : false;
    },
    showSubtotalInBottom() {
      return (
        this.datatableSubTotalPosition === tableSubTotalPositionsEnums.Below
      );
    },
    showSubtotalInTop() {
      return (
        this.datatableSubTotalPosition === tableSubTotalPositionsEnums.Above
      );
    },
    showSubtotalInBoth() {
      return (
        this.datatableSubTotalPosition === tableSubTotalPositionsEnums.Both
      );
    },
    tableAggregateColumns() {
      const isTypeFirstColumnsInTheListNotMeasure =
        this.panelData?.details?.columns?.[0]?.fieldUsageType !==
        DatamodelContextDefaults.USAGE_TYPES.MEASURE && this.panelData?.details?.columns?.[0]?.fieldUsageType !==
        DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE ;

      return [
        {
          id: uuidv4(),
          isShow:
            this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInBoth,
          footerTemplate: aggregateColumnProps.footerTemplate,
          groupFooterTemplate: aggregateColumnProps.groupFooterTemplate,
          groupCaptionTemplate: isTypeFirstColumnsInTheListNotMeasure
            ? aggregateColumnProps.groupCaptionTemplate
            : "",
        },
        {
          id: uuidv4(),
          isShow:
            this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInBottom,
          footerTemplate: aggregateColumnProps.footerTemplate,
          groupFooterTemplate: aggregateColumnProps.groupFooterTemplate,
          groupCaptionTemplate: "",
        },
        {
          id: uuidv4(),
          isShow:
            this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInTop,
          footerTemplate: aggregateColumnProps.footerTemplate,
          groupCaptionTemplate: isTypeFirstColumnsInTheListNotMeasure
            ? aggregateColumnProps.groupCaptionTemplate
            : "",
          groupFooterTemplate: "",
        },
        {
          id: uuidv4(),
          isShow: this.datatableShowTotal && !this.datatableShowSubTotal,
          footerTemplate: aggregateColumnProps.footerTemplate,
          groupFooterTemplate: "",
          groupCaptionTemplate: "",
        },
        {
          id: uuidv4(),
          isShow:
            !this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInBottom,
          footerTemplate: "",
          groupFooterTemplate: aggregateColumnProps.groupFooterTemplate,
          groupCaptionTemplate: "",
        },
        {
          id: uuidv4(),
          isShow:
            !this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInTop,
          footerTemplate: "",
          groupFooterTemplate: "",
          groupCaptionTemplate: isTypeFirstColumnsInTheListNotMeasure
            ? aggregateColumnProps.groupCaptionTemplate
            : "",
        },
        {
          id: uuidv4(),
          isShow:
            !this.datatableShowTotal &&
            this.datatableShowSubTotal &&
            this.showSubtotalInBoth,
          footerTemplate: "",
          groupFooterTemplate: aggregateColumnProps.groupFooterTemplate,
          groupCaptionTemplate: isTypeFirstColumnsInTheListNotMeasure
            ? aggregateColumnProps.groupCaptionTemplate
            : "",
        },
      ].filter((x) => x.isShow);
    },
  },
  methods: {
    ...mapActions({
      createExportMetadata: ACTION_EXPORT_METADATA.CREATE_EXPORT_METADATA,
      fetchImageBase64: ACTION_IMAGES.FETCH_IMAGE_BASE64
    }),
    pdfHeaderQueryCellInfo(args) {
      const columnBasedFormattingColumnWidth =
        this.getPanelDataColumnBasedProperties(
          null,
          datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_WIDTH,
          args?.gridCell?.column?.slotFieldId
        );
        
      args.gridCell.column.width = columnBasedFormattingColumnWidth ?? 150;
      
      const convertedColor = hexColorToRGBConvertWithObject(this.datatableColumnBackgroundColor);

      if (convertedColor) {
        args.cell.style.backgroundBrush = new PdfSolidBrush(new PdfColor(convertedColor.r, convertedColor.g, convertedColor.b));
      }

      if (!this.datatablePdfExportRepeatColumnHeader) return;
      
      args.cell.row.pdfGrid.repeatHeader = true;
    },
    getFieldType(fieldType) {
      // burada table export için fieldType kullanıyoruz.
      return fieldType === DatamodelContextDefaults.USAGE_TYPES.DATE ? DatamodelContextDefaults.USAGE_TYPES.TEXT.toLowerCase() : fieldType
    },
    getColumnsTotal(optionArr) {
      const newObj = {};
      optionArr?.forEach((element) => {
        const metricFields = this.panelDataDetailsMetrics.filter(
          (x) => element[x.field + x.type + x.slotFieldId]
        );
        metricFields.forEach((metricField) => {
          const field =
            metricField?.field + metricField?.type + metricField?.slotFieldId;
          if (!newObj?.[field]?.length) {
            newObj[field] = [];
            newObj[field].push(element[field]);
          } else {
            newObj[field].push(element[field]);
          }
        });
      });
      return newObj;
    },
    percentCalculatedOptionArr() {
      const clonedPanelData = cloneDeep(this.panelData);
      const optionArr = cloneDeep(this.option?.arr);
      const columnsTotal = this.getColumnsTotal(optionArr);
      optionArr?.forEach((element) => {
        const metricFields = this.panelDataDetailsMetrics.filter(
          (x) => element[x.field + x.type + x.slotFieldId]
        );

        metricFields.forEach((metricField) => {
          const field =
            metricField.field + metricField.type + metricField.slotFieldId;
          const columnBasedFormattingFormatCell =
            this.getPanelDataColumnBasedProperties(
              metricField?.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FORMAT,
              metricField?.slotFieldId
            );
          if (
            columnBasedFormattingFormatCell !==
            tableColumnFormatValues.PERCENTAGE
          ) {
            if (
              metricField?.format?.type === measureTypes.RATIO &&
              metricField?.format?.columnBasedFeatureActive
            )
              this.setFormatToColumn(
                metricField,
                clonedPanelData,
                measureTypes.NUMBER
              );
            return;
          }

          if (metricField?.format?.type !== measureTypes.RATIO)
            this.setFormatToColumn(metricField, clonedPanelData, measureTypes.RATIO);

          let sum = columnsTotal?.[field]?.reduce((accumulator, value) => {
            return accumulator + value;
          }, 0);

          element[field] = (element[field] / sum) * 100;
        });
      });
      return optionArr;
    },
    //Methodun yazılma sebebi daha önceden oluşturulmuş tablolardaki aggregate result uyumsuzluğunu gidermek için.
    checkAndSetAggregateResult() {
      const clonedPanelData = cloneDeep(this.panelData);
      if (
        getPanelDataPropertiesStyle(
          clonedPanelData,
          datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
        ) &&
        clonedPanelData.properties.vismetadata.vistype === chartTypes.TABLE
      ) {
        const updatedClonedPanelData =
          changeVisTypeForSearchParamByAggregateResult(clonedPanelData);

        this.$emit("updateSelectedPanel", updatedClonedPanelData);
      }
    },

    setFormatToColumn(metricField, clonedPanelData, formatType) {
      const selectedMetricField = clonedPanelData.details.metrics.find((x) =>
        metricField?.slotFieldId
          ? x.slotFieldId === metricField?.slotFieldId
          : x.fieldId === metricField?.fieldId
      );
      selectedMetricField.format.type = formatType;
      selectedMetricField.format.columnBasedFeatureActive = true;
      const selectedColumnField = clonedPanelData.details.columns.find((x) =>
        metricField?.slotFieldId
          ? x.slotFieldId === metricField?.slotFieldId
          : x.fieldId === metricField?.fieldId
      );
      selectedColumnField.format.type = formatType;
      selectedColumnField.format.columnBasedFeatureActive = true;

      this.$emit("updateSelectedPanel", clonedPanelData);
    },
    getColumnBasedFormattingAlign(
      fieldName,
      selectedTableAlignValue,
      defaultValue,
      slotFieldId
    ) {
      const field = this.getFieldByColumnName(fieldName, slotFieldId);
      const columnBasedFormattingAlign = this.getPanelDataColumnBasedProperties(
        field?.fieldId,
        datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN,
        datatableColumnsDefaultConfigurations.CHART_SELECT_ROW_ALIGN,
        field.slotFieldId
      );

      return (
        columnBasedFormattingAlign ?? selectedTableAlignValue ?? defaultValue
      );
    },
    getHeaderColumnBasedFormattingAlign(
      fieldName,
      selectedTableAlignValue,
      defaultValue,
      slotFieldId
    ) {
      const field = this.getFieldByColumnName(fieldName, slotFieldId);
      const columnBasedFormattingAlign = this.getPanelDataColumnBasedProperties(
        field?.fieldId,
        datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
        datatableColumnsDefaultConfigurations.CHART_SELECT_COLUMN_ALIGN,
        field?.slotFieldId
      );

      return (
        columnBasedFormattingAlign ?? selectedTableAlignValue ?? defaultValue
      );
    },
    setHeaderColumnBasedFormattingRename(item) {
      const fieldObj = cloneDeep(
        this.getFieldByColumnName(item.field, item.slotFieldId)
      );
      const columnBasedFormattingColumnName =
        this.getPanelDataColumnBasedProperties(
          fieldObj.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME,
          item.alias,
          fieldObj.slotFieldId
        );
      const columnBasedFormattingScreenTip =
        this.getPanelDataColumnBasedProperties(
          fieldObj.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP,
          fieldObj.screenTip,
          fieldObj.slotFieldId
        );

      if (
        columnBasedFormattingColumnName &&
        columnBasedFormattingColumnName !== ""
      )
        fieldObj.alias = columnBasedFormattingColumnName;
      if (columnBasedFormattingScreenTip)
        fieldObj.screenTip = columnBasedFormattingScreenTip;

      return fieldObj.alias;
    },

    setDataTableColumnBasedComponentProperties(params) {
      this.$emit("setDataTableColumnBasedComponentProperties", params);
    },
    openColumnBasedFormattingProperties(fieldObj) {
      this.$emit("onSelectColumnBasedPropertiesColumn", fieldObj);
    },
    resizeStop(args) {
      const field = this.getFieldByColumnName(
        args.column.field,
        args.column.slotFieldId
      );

      this.openColumnBasedFormattingProperties(field);
      this.setDataTableColumnBasedComponentProperties({
        property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
        value: args.column.width,
      });
    },
    setCulture() {
      const selectedLanguage = this.userPreferences?.find(
        (v) => v.key === "language"
      )?.value;

      if (!selectedLanguage) return;

      setCulture(
        this.localizationMapping?.[selectedLanguage] ??
          this.localizationMapping.en
      );
    },
    setLocalizations() {
      L10n.load({
        "en-US": {
          pager: {
            currentPageInfo: "{0}/{1} pages",
            totalItemsInfo: `${
              this.option.isMaxThreshold
                ? "(First 10,000 items)"
                : "({0} items)"
            }`,
            firstPageTooltip: "first page",
            lastPageTooltip: "last page",
            nextPageTooltip: "next page",
            previousPageTooltip: "previous page",
          },
        },
        "tr-TR": {
          pager: {
            currentPageInfo: "{0}/{1} sayfa",
            totalItemsInfo: `${
              this.option.isMaxThreshold ? "(İlk 10,000 öğe)" : "({0} öğe)"
            }`,
            firstPageTooltip: "ilk sayfa",
            lastPageTooltip: "son sayfa",
            nextPageTooltip: "bir sonraki sayfa",
            previousPageTooltip: "bir önceki sayfa",
          },
        },
      });
    },
    exportGroupCaption: function (args) {
      args.captionText = "";
      args.captionText = `${args.data.field}: ${args.data.key}`;
    },
    customiseCellExport(args) {
      if (this.currentExportFormatType === this.exportFormatTypes.formatted) {
        this.formatCell(args, (args, value) => (args.value = value));
        //Kolon bazlı background değiştirmek için
        // args.style = {backgroundColor: '#99ffcc'};
      }
    },
    customiseHeaderCell(args) {
      args.node.style.backgroundColor = this.datatableColumnBackgroundColor;
    },
    // conditional formatting > column based formatting > datatable formatting
    // in order to make this work we need to set style reverse order
    // because the styles override each other
    customiseCell(args) {
      // first set datatable properties style
      args.cell.style.fontFamily = this.datatableRowFontFamily;
      args.cell.style.fontSize = `${this.datatableRowFontSize}px`;
      args.cell.style.fontWeight = this.datatableRowFontWeight;
      args.cell.style.backgroundColor = this.datatableBackgroundColor;
      args.cell.style.color = this.datatableFontColor;

      this.formatCell(args, (a, value) => {
        return (a.cell.innerHTML = `<span> ${value}</span>`);
      });

      // second set column based properties style
      this.setColumnBasedFormattingTableCellStyle(args);

      // third set conditional properties style
      this.setConditionalFormattingTableCellStyle(args);

      if (this.gridContentStyleCounter === 0)
        setTimeout(() => {
          this.setTableGridContentStyle();
        }, 200);

      this.gridContentStyleCounter++;
    },
    getFieldByColumnName(field, slotFieldId) {
      return this.panelDataDetailsColumns.find((i) => {
        if (
          i.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
          getPanelDataPropertiesStyle(
            this.panelData,
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
          )
        ) {
          return slotFieldId
            ? i.slotFieldId === slotFieldId
            : i.field + i.type === field;
        } else
          return slotFieldId
            ? i.slotFieldId === slotFieldId
            : i.field === field;
      });
    },
    setColumnBasedFormattingTableCellStyle(args) {
      const field = this.getFieldByColumnName(
        args.column.field,
        args.column.slotFieldId
      );
      const columnBasedFormattingFontFamily =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_FAMILY,
          field.slotFieldId
        );
      const columnBasedFormattingFontSize =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_SIZE,
          field.slotFieldId
        );
      const columnBasedFormattingFontWeight =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
          field.slotFieldId
        );
      const columnBasedFormattingFontColor =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_FONT_COLOR,
          field.slotFieldId
        );
      const columnBasedFormattingBackgroundColor =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR,
          field.slotFieldId
        );
      const columnBasedFormattingRowTextWrap =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_TEXT_WRAP,
          field.slotFieldId
        );

      const columnBasedFormattingRowMaxCharacter =
        this.getPanelDataColumnBasedProperties(
          field.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_MAX_CHARACTER,
          field.slotFieldId
        );
      const content = args.data[args.column.field];
      
      if (columnBasedFormattingRowMaxCharacter) {
        
        if (!content) return;

        const lastPoint =
          content.length > columnBasedFormattingRowMaxCharacter ? "..." : "";
        const truncatedContent =
          content.substring(0, columnBasedFormattingRowMaxCharacter) +
          lastPoint;
        //customiseCell methodundaki this.formatCell içerisinde innerHTML <span> arasında yazılıyor. Bunun dışına çıkılmaması lazım.
        args.cell.innerHTML = `<span> ${truncatedContent} </span>`;
      }
      if (columnBasedFormattingFontFamily) {
        args.cell.style.fontFamily = columnBasedFormattingFontFamily;
      }
      if (columnBasedFormattingFontSize) {
        args.cell.style.fontSize = columnBasedFormattingFontSize + "px";
      }
      if (columnBasedFormattingFontWeight) {
        args.cell.style.fontWeight = columnBasedFormattingFontWeight;
      }
      if (columnBasedFormattingFontColor) {
        args.cell.style.color = columnBasedFormattingFontColor;
      }
      
      const isActiveColumnBasedRowTextWrap =
        (!this.datatableEnableTextWrap && columnBasedFormattingRowTextWrap) ||
        (this.datatableEnableTextWrap && columnBasedFormattingRowTextWrap) ||
        (this.datatableEnableTextWrap &&
          columnBasedFormattingRowTextWrap === null);

      args.cell.style.whiteSpace = isActiveColumnBasedRowTextWrap
        ? "pre-wrap"
        : "nowrap";
      args.cell.style.lineHeight = isActiveColumnBasedRowTextWrap
        ? "21px"
        : "inherit";
      args.cell.style.wordBreak = isActiveColumnBasedRowTextWrap
        ? "break-word"
        : "inherit";
      args.cell.style.textOverflow = isActiveColumnBasedRowTextWrap
        ? "inherit"
        : "ellipsis";
      
      if (columnBasedFormattingBackgroundColor) {
        args.cell.style.backgroundColor = columnBasedFormattingBackgroundColor;
      }
    },
    setTableCellIconStyle(args, conditionFormat, spanChildItemIndex) {
      const sizeType = "px";
      const setChildNode = (iconChildItemIndex) => {
        args.cell.childNodes[iconChildItemIndex].className =
          conditionFormat.iconName;
        args.cell.childNodes[iconChildItemIndex].style.color =
          conditionFormat.iconColor;
        args.cell.childNodes[iconChildItemIndex].style.fontSize =
          conditionFormat.iconSize + sizeType;
        args.cell.childNodes[iconChildItemIndex].style.verticalAlign = "middle";
        args.cell.childNodes[
          spanChildItemIndex
        ].style.padding = `0${sizeType} 2${sizeType}`;
        args.cell.childNodes[spanChildItemIndex].style.verticalAlign = "middle";
        if (
          conditionFormat.iconShowOption === iconFormatShowType.ICON_ONLY &&
          conditionFormat?.customText === ""
        ) {
          args.cell.childNodes[spanChildItemIndex].style.display = "none";
          args.cell.style.textAlign = conditionFormat.iconPosition;
        } else if (
          conditionFormat?.customText !== "" &&
          conditionFormat.iconShowOption === iconFormatShowType.ICON_VALUE_ONLY
        ) {
          args.cell.childNodes[spanChildItemIndex].innerHTML =
            conditionFormat.customText;
        }
      };
      let iconChildItemIndex = 1;
      let i = document.createElement("i");

      args.cell.append(i);

      if (conditionFormat?.iconPosition === iconFormatPositionValue.LEFT) {
        const arr = Array.from(args.cell.childNodes);
        arr.reverse();
        args.cell.append(...arr);
        iconChildItemIndex = 0;
      } else iconChildItemIndex = 1;
      setChildNode(iconChildItemIndex);
    },
    setConditionalFormattingTableCellStyle(args) {
      const conditionFormat =
        this.conditionalFormattingByRulesAndCell[args.column.field]?.[
          Object.values(args.data).sort().join("-")
        ];

      // Satır bazlı background kontrolü
      const rowKey = Object.values(args.data).sort().join("-");
      const rowBackgroundColor = this.conditionalFormattingByRow[rowKey];

      if (!conditionFormat && !rowBackgroundColor) return;

      let spanChildItemIndex = 0;

      // Eğer icon formatı varsa
      if (conditionFormat?.iconName) {
        conditionFormat?.iconPosition === iconFormatPositionValue.LEFT
          ? (spanChildItemIndex = 1)
          : (spanChildItemIndex = 0);
        this.setTableCellIconStyle(args, conditionFormat, spanChildItemIndex);
      } else {
        const sizeType = "px";
        // Chip rengi ayarla
        if (conditionFormat?.chipColor) {
          args.cell.childNodes[spanChildItemIndex].style.backgroundColor =
            conditionFormat.chipColor;
          args.cell.childNodes[
            spanChildItemIndex
          ].style.borderRadius = `5${sizeType}`;
          args.cell.childNodes[
            spanChildItemIndex
          ].style.padding = `2${sizeType} 5${sizeType}`;
        }

        // Hücre bazlı format
        if (conditionFormat) {
          args.cell.style.backgroundColor = conditionFormat.backgroundColor;
          args.cell.style.fontSize = conditionFormat.fontSize + sizeType;
          args.cell.style.fontWeight = conditionFormat.fontWeight;
          args.cell.style.color = conditionFormat.fontColor;
          args.cell.style.fontFamily = conditionFormat.fontFamily;

          if (
            conditionFormat?.customText !== "" &&
            conditionFormat.valueShowOption === valueFormatShowType.TEXT_ONLY
          ) {
            args.cell.childNodes[spanChildItemIndex].innerHTML =
              conditionFormat.customText;
          } else if (
            conditionFormat?.customText !== "" &&
            conditionFormat.valueShowOption === valueFormatShowType.VALUE_AND_TEXT
          ) {
            args.cell.childNodes[spanChildItemIndex].innerHTML = 
              args.cell.childNodes[spanChildItemIndex].innerHTML + " " + conditionFormat.customText;
          }
        }

        // Satır bazlı background color uygulama
        if (rowBackgroundColor) {
          args.cell.style.backgroundColor = rowBackgroundColor;
        }
      }
    },

    setTableConditionalFormatting() {
      this.tableColumns.forEach((column) => {
        this.conditionalFormattingByRulesAndCell[column.field] = {};
      });
     
      if (!this.panelData?.properties?.conditionalFormattings?.length) return;

      this.panelData?.properties?.conditionalFormattings?.forEach(
        (conditionalFormatting) => {
          const selectedField = this.panelDataDetailsColumns?.find((x) =>
            conditionalFormatting?.slotFieldId
              ? x.slotFieldId === conditionalFormatting?.slotFieldId
              : x.fieldId === conditionalFormatting.fieldId
          );
          const usedMeasureField = selectedField?.slotFieldId
            ? selectedField.field +
              selectedField.type +
              selectedField.slotFieldId
            : selectedField.field + selectedField.type;
          const usedAggField = selectedField?.slotFieldId
            ? selectedField.field + selectedField?.slotFieldId
            : selectedField.field;

          const field =
            selectedField.fieldUsageType ===
              DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
            getPanelDataPropertiesStyle(
              this.panelData,
              datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
            )
              ? usedMeasureField
              : usedAggField;

          if (!this.tableRows.length) return;

          this.tableColumns.forEach((column) => {
            this.tableRows.forEach((row) => {
              cloneDeep(conditionalFormatting.conditions)
                .reverse()
                .forEach((condition) => {
                  condition.rules.forEach((rule) => {
                    const conditionFormat =
                      conditionalFormatting?.formatType ===
                      conditionFormatType.FORMAT_VALUE
                        ? condition.format
                        : condition.iconFormat;
                    this.setTableCellByRule(
                      field,
                      column.field,
                      row,
                      conditionFormat,
                      rule
                    );
                  });
                });
            });
          });
        }
      );
    },
    setTableCellByRule(field, column, row, conditionFormat, rule) {
      rule.dataItem = this.checkRuleDataItemByAggregateResult(rule.dataItem);
      let rowValue = row[rule.dataItem];
      let firstValue = rule.value1;
      let secondValue = rule.value2;

      if (rule?.usageType === DatamodelContextDefaults.USAGE_TYPES.DATE) {
        const formatType = "YYYY/MM/DD";
        const dateRowFormatted = moment(rowValue).format(formatType);
        const dateFirstValFormatted = moment(firstValue).format(formatType);
        const dateSecondValFormatted = moment(secondValue).format(formatType);
        
        rowValue = new Date(dateRowFormatted).getTime();
        firstValue = new Date(dateFirstValFormatted).getTime();
        secondValue = new Date(dateSecondValFormatted).getTime();
      }
      const operators = {
        [conditionalOperatorValues.QT]: rowValue > firstValue,
        [conditionalOperatorValues.LT]: rowValue < firstValue,
        [conditionalOperatorValues.EQ]: rowValue == firstValue,
        [conditionalOperatorValues.NOT_EQ]: rowValue != firstValue,
        [conditionalOperatorValues.QTE]: rowValue >= firstValue,
        [conditionalOperatorValues.LTE]: rowValue <= firstValue,
        [conditionalOperatorValues.BETWEEN]:
          rowValue >= firstValue && rowValue <= secondValue,
        [conditionalOperatorValues.NOT_BETWEEN]:
          rowValue <= firstValue || rowValue >= secondValue,
        [conditionalOperatorValues.IN]: firstValue
          .toString()
          .includes(rowValue),
        [conditionalOperatorValues.NOT_IN]: !firstValue
          .toString()
          .includes(rowValue),
      };

      if (operators[rule.operator] && column === field) {
        this.conditionalFormattingByRulesAndCell[column][
          Object.values(row).sort().join("-")
        ] = conditionFormat;

        if (conditionFormat?.rowBackgroundColor) {
          const rowKey = Object.values(row).sort().join("-");
          this.conditionalFormattingByRow[rowKey] =
            conditionFormat.rowBackgroundColor;
        }
      }
    },
    checkRuleDataItemByAggregateResult(ruleDataItem) {
      const selectedField = this.panelDataDetailsColumns?.find(
        (x) => x.field === ruleDataItem
      );

      if (!selectedField) return ruleDataItem;

      if (
        selectedField.fieldUsageType !==
        DatamodelContextDefaults.USAGE_TYPES.MEASURE
      ) {
        return ruleDataItem === selectedField.field
          ? ruleDataItem + selectedField.slotFieldId
          : ruleDataItem;
      }

      if (
        getPanelDataPropertiesStyle(
          this.panelData,
          datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
        ) &&
        !Object.values(DatamodelContextDefaults.SUMMARIZATION_RULE).includes(
          ruleDataItem
        )
      ) {
        return (
          selectedField.field + selectedField.type + selectedField?.slotFieldId
        );
      } else return ruleDataItem;
    },
    formatCell(args, valueSetter) {
      //ATTRIBUTE
      const selectedColumn = this.panelDataDetailsColumns.find(
        (item) => item?.field + item?.slotFieldId == args?.column?.field
      );
      //MEASURES
      const selectedMetric = this.panelDataDetailsMetrics.find(
        (item) =>
          item?.field + item?.type + item?.slotFieldId == args?.column?.field
      );

      const selectedColumnFormat = selectedColumn?.format;
      const selectedMetricFormat = selectedMetric?.format;

      const aggregateFieldKey =
        selectedColumn?.field + selectedColumn?.slotFieldId;
      if (selectedColumnFormat && args?.data[aggregateFieldKey]) {
        valueSetter(
          args,
          formatMeasure(args?.data[aggregateFieldKey], selectedColumnFormat)
        );
      }
      const metricFieldKey =
        selectedMetric?.field +
        selectedMetric?.type +
        selectedMetric?.slotFieldId;

      if (selectedMetricFormat && args?.data[metricFieldKey]) {
        valueSetter(
          args,
          formatMeasure(args?.data?.[metricFieldKey], selectedMetricFormat)
        );
      }
    },
    filterByNumberField(arr, field) {
      return arr.filter((i) => typeof i[field] === "number");
    },
    footerAggregations(field) {
      return {
        template: Vue.component("sumTemplate", {
          template: `<span v-if="isValidCustomData" class="vis-sumTemplate-span" style="color: ${
            this.datatableTotalFontColor
          };
                             font-family: ${this.datatableTotalFontFamily};
                             font-size: ${this.datatableTotalFontSize}px;
                             font-weight: ${this.datatableTotalFontWeight};"
                          >{{data.Custom}}
                          <el-tooltip
                              v-if="${
                                this.option.isMaxThreshold &&
                                (field.fieldUsageType !==
                                  DatamodelContextDefaults.USAGE_TYPES.DATE)
                              }"
                              class="item"
                              effect="dark"
                              content="${this.$t("table.maxRowWarning")}"
                              placement="bottom"
                          >
                               <i class="vis-icon icon-information vis-sumTemplate-icon"></i>
                           </el-tooltip>
                      </span>`,
          data() {
            return { data: {} };
          },
          computed: {
            isValidCustomData() {
              return !this.data.Custom.includes('Invalid') && !this.data.Custom.includes('NaN');
            }
          }
        }),
      };
    },
    subFooterAggregations: function () {
      return {
        template: Vue.component("sumTemplate", {
          template: `<span style="color: ${this.datatableSubTotalFontColor};
                             font-family: ${this.datatableSubTotalFontFamily};
                             font-size: ${this.datatableSubTotalFontSize}px;
                             font-weight: ${this.datatableSubTotalFontWeight};"
                          >{{data.Custom}}</span>`,

          data() {
            return { data: {} };
          },
        }),
      };
    },
    captionAggregations: function () {
      return {
        template: Vue.component("sumTemplate", {
          template: `<span style="color: ${this.datatableSubTotalFontColor};
                             font-family: ${this.datatableSubTotalFontFamily};
                             font-size: ${this.datatableSubTotalFontSize}px;
                             font-weight: ${this.datatableSubTotalFontWeight};"
                           >{{data.Custom}}</span>`,
          data() {
            return { data: {} };
          },
        }),
      };
    },
    customHeaderCell: function (field, slotFieldId) {
      // COLUMN BASED FORMATTING > TABLE FORMATTING
      const getColumnBasedHeaderFormatting = () => {
        const fieldObj = this.getFieldByColumnName(field, slotFieldId);

        if (fieldObj) {
          const columnBasedFormattingFontFamily =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
              fieldObj.slotFieldId
            );
          const columnBasedFormattingFontSize =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
              fieldObj.slotFieldId
            );
          const columnBasedFormattingFontWeight =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
              fieldObj.slotFieldId
            );
          const columnBasedFormattingFontColor =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
              fieldObj.slotFieldId
            );
          const columnBasedFormattingTextWrap =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP,
              datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_TEXT_WRAP,
              fieldObj.slotFieldId
            );
          const columnBasedFormattingScreenTip =
            this.getPanelDataColumnBasedProperties(
              fieldObj.fieldId,
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP,
              fieldObj.screenTip,
              fieldObj.slotFieldId
            );
          return {
            columnBasedFormattingFontFamily,
            columnBasedFormattingFontSize,
            columnBasedFormattingFontWeight,
            columnBasedFormattingFontColor,
            columnBasedFormattingTextWrap,
            columnBasedFormattingScreenTip,
          };
        }

        return {};
      };

      const columnBasedHeaderFormatting = getColumnBasedHeaderFormatting();
      const computedColor =
        columnBasedHeaderFormatting?.columnBasedFormattingFontColor ??
        this.datatableColumnFontColor;
      const computedFontFamily =
        columnBasedHeaderFormatting?.columnBasedFormattingFontFamily ??
        this.datatableColumnFontFamily;
      const computedFontSize =
        columnBasedHeaderFormatting?.columnBasedFormattingFontSize ??
        this.datatableColumnFontSize;
      const computedFontWeight =
        columnBasedHeaderFormatting?.columnBasedFormattingFontWeight ??
        this.datatableColumnFontWeight;
      const computedTextWrap =
        columnBasedHeaderFormatting?.columnBasedFormattingTextWrap
      const computedScreenTip =
        columnBasedHeaderFormatting?.columnBasedFormattingScreenTip ??
        this.columnBasedFormattingScreenTip;

      return {
        template: Vue.component("e-headercelldiv", {
          template: `<span
          title="${computedScreenTip ? computedScreenTip : ""}"
          style="
                color: ${computedColor};
                font-family: ${computedFontFamily};
                font-size: ${computedFontSize}px;
                font-weight: ${computedFontWeight};
                white-space: ${
                  computedTextWrap ? "normal" : "nowrap"
                } !important;
                line-height: ${
                  computedTextWrap ? "21px" : "inherit"
                } !important;
                word-break: ${
                  computedTextWrap ? "break-word" : "inherit"
                } !important;
                ">
                    {{ data.headerText }}
            </span>`,
          data() {
            return { data: {} };
          },
        }),
      };
    },
    customCellTemplate: function () {
      return {
        template: Vue.component("customCellTemplate", {
          template: `<span>{{data[data.column.field]}}</span>`,
          data() {
            return { data: {} };
          },
        }),
      };
    },
    actionFailure() {
      // console.log(args);
    }, 
    // eslint-disable-next-line no-unused-vars
    formatFooterValues: function (data, aggColumn, column) {
      const field = this.panelDataDetailsColumns.find((i) => {
        if (
          column.fieldUsageType ===
            DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
          getPanelDataPropertiesStyle(
            this.panelData,
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
          )
        ) {
          return i.field + i.type + i.slotFieldId === column.field;
        } else return i.field + i.slotFieldId === column.field;
      });
      const columnBasedFormattingMeasureShowAggregationFunction =
        this.getPanelDataColumnBasedProperties(
          field?.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_MEASURE_SHOW_TOTAL,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_MEASURE_SHOW_TOTAL,
          field?.slotFieldId
        );
      const columnBasedFormattingAggregationFunction =
        this.getPanelDataColumnBasedProperties(
          field?.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_AGGREGATION_FUNCTION,
          field?.slotFieldId
        );
      const columnBasedFormattingAttributeShowAggregationFunction =
        this.getPanelDataColumnBasedProperties(
          field?.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ATTRIBUTE_SHOW_TOTAL,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ATTRIBUTE_SHOW_TOTAL,
          field?.slotFieldId
        );
      
      const columnBasedFormattingAttributeAggregationFunction =
        this.getPanelDataColumnBasedProperties(
          field?.fieldId,
          datatablePropertiesEnum.CHART_DATA_TABLE_ATTRIBUTE_AGGREGATION_FUNCTION,
          datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ATTRIBUTE_AGGREGATION_FUNCTION,
          field?.slotFieldId
        );
      let dataMapped = [];
      let filterValue = [];
      if (data.currentPage) {
        filterValue = this.tableFilterValue;
      }
      data?.columns?.forEach((element) => {
        if (!filterValue[element.field]) {
          filterValue = {
            ...filterValue,
            ...{ [element.field]: [] },
          };
        }
        if (!filterValue[element.field].includes(element.value))
          filterValue[element.field].push(element.value);

        this.tableFilterValue = filterValue;
      });
      // total kısmı
      if (data.result) {
        if (Object.keys(this.tableFilterValue).length) {
          let filteredData = this.tableRows;
          // Her anahtar için filtreleme yap
          Object.keys(this.tableFilterValue).forEach((key) => {
            const filterValues = this.tableFilterValue[key];
            filteredData = filteredData.filter((row) => {
              if (Array.isArray(filterValues)) {
                // Eğer anahtar bir dizi ise, diziye uygun bir kontrol yap
                return filterValues.includes(row[key]);
              }
              // Eğer anahtar bir dizi değilse, sadece eksiksiz olanları kabul et
              return row[key] === filterValues;
            });
          });
          dataMapped = filteredData?.map((m) => m[aggColumn.columnName]);
        } else {
          dataMapped = this.tableRows?.map((m) => m[aggColumn.columnName]);
        }
      } else if (data.items && data.items.records) {
        dataMapped = data.items.records.map(
          (rowData) => rowData[aggColumn.columnName]
        );
      }
      // subtotal kısmı
      else if (data.items) {
        const dataKey = data.key + this.panelData.i;

        if (
          this.tableAggregateItems[dataKey] &&
          deepEqual(this.tableAggregateItems[dataKey], data.items) &&
          this.panelDataDetailsSections.length <= 1
        ) {
          this.tableAggregateItems[dataKey] = [
            ...this.tableAggregateItems[dataKey],
          ];
        } else {
          this.tableAggregateItems[dataKey] = data.items;
        }
      
        dataMapped = this.tableAggregateItems[dataKey].map(
          (rowData) => rowData[aggColumn.columnName]
        );
      } else {
        dataMapped = data?.map((rowData) => rowData[aggColumn.columnName]);
      }

      let aggregation = tableFooterAggregrations[column.type];

      if (
        columnBasedFormattingMeasureShowAggregationFunction &&
        columnBasedFormattingAggregationFunction !==
          DatamodelContextDefaults.SUMMARIZATION_RULE.SAME_AS_MEASURE
      ) {
        aggregation =
          tableFooterAggregrations[columnBasedFormattingAggregationFunction];
      } 
      if (columnBasedFormattingAttributeShowAggregationFunction) {
        aggregation =
        tableFooterAggregrationsForAttributes[columnBasedFormattingAttributeAggregationFunction];
      }
      if (filterValue.length) {
        dataMapped = dataMapped.filter((x) => filterValue.includes(x));
      }
      const calculationResult = aggregation?.calculate(
        dataMapped?.map((d) =>
          column.fieldUsageType ===
            DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE &&
          columnBasedFormattingAttributeShowAggregationFunction
            ? String(d)
            : columnBasedFormattingMeasureShowAggregationFunction ?
            Number(d) : ""
        )
      );
      
      if (!calculationResult) return;

      // this.tableCalculationResult = so that we can use the grand total in other functions.
      this.tableCalculationResult[aggColumn.columnName] = calculationResult;
      const fieldFormat = this.panelDataDetailsSections.find((x) => x.field + x.slotFieldId === data.field)?.format;

      return !this.option.isMaxThreshold && columnBasedFormattingMeasureShowAggregationFunction &&
        (aggregation?.text)
        ? `${data?.key ? formatMeasure(data?.key, fieldFormat) : ""} ${this.$t(
            aggregation?.text
          )}: ${formatMeasure(calculationResult, field.format)}`
        : null;
    },
    selectedRowHighlight(args) {
      const filterObj = getTableSelectedRowValue(
        this.panelDataDetailsAggs,
        args
      );

      if (deepEqual(this.filterValue, filterObj)) {
        args.row.style.backgroundColor = "#e5e5e5";
      }
    },
    setTableGridContentStyle() {
      if (this.totalItems) {
        const tableClass = `.ejs-table-container${this.panelData.i}`;
        const columnHeader = document.querySelectorAll(
          `${tableClass} .e-columnheader`
        );
        const summaryContent = document.querySelectorAll(
          `${tableClass} .e-summarycontent`
        );
        const gridPager = document.querySelectorAll(
          `${tableClass} .e-gridpager`
        );
        const toolBar = document.querySelectorAll(
          `${tableClass} .e-toolbar-items`
        );

        let gridContent = document.querySelectorAll(
          `${tableClass} .e-gridcontent`
        );

        const toolBarHeight = toolBar?.[0]?.offsetHeight || 1;
        const paginationHeight = gridPager?.[0]?.offsetHeight || 0;
        const columnHeaderHeight = columnHeader?.[0]?.offsetHeight || 0;
        const summaryContentHeight = summaryContent?.[0]?.offsetHeight || 0;

        const sumHeight =
          columnHeaderHeight +
          summaryContentHeight +
          paginationHeight +
          toolBarHeight + 2;

        if(!gridContent[0]) return;
        
        gridContent[0].style.height = `calc(100% - ${sumHeight}px)`;
      }
    },
    dataBound() {
      let datatableSectionBackgroundColor =
        this.datatableSectionBackgroundColor;
      let subTotalBackgroundColor = this.datatableSubTotalBackgroundColor;

      if (this.isGroupingAllowed) {
        let grid =
          document.getElementsByClassName("e-grid")[0]?.ej2_instances?.[0];
        let groupcol = grid.groupModule?.groupSettings?.columns;
        let rows = grid?.element?.querySelectorAll(".e-row,.e-summaryrow");
        let grouprows = grid?.element?.querySelectorAll(
          ".e-recordpluscollapse,.e-recordplusexpand"
        );
        let summaryRows = grid?.element?.querySelectorAll(".e-summaryrow");
        let headerGroupTopLeftCel = grid?.element?.querySelectorAll(
          ".e-grouptopleftcell"
        );

        //Header kısmında Section ekleyince solda oluşan boşluk için
        for (let h = 0; h < headerGroupTopLeftCel?.length; h++) {
          headerGroupTopLeftCel[
            h
          ].style.backgroundColor = `${this.datatableColumnBackgroundColor}`;
          headerGroupTopLeftCel[h].style.borderTop = `none`;
        }

        for (let a = 0; a < rows.length - 1; a++) {
          for (let b = 0; b < groupcol?.length; b++) {
            rows[a].getElementsByClassName("e-indentcell")[
              b
            ].style.backgroundColor = `#ffffff`;
            if (this.datatableSectionIndentCell) {
              rows[a].getElementsByClassName("e-indentcell")[
                b
              ].style.borderColor = `#ffffff`;
            }
          }
        }

        for (let i = 0; i < grouprows.length; i++) {
          for (let j = 0; j < groupcol.length; j++) {
            if (
              grouprows[i].closest("tr").getElementsByClassName("e-indentcell")
                .length == j
            ) {
              let successResults = this.panelDataDetailsSections.filter(
                (item) => item.field + item.slotFieldId === groupcol[j]
              );
              datatableSectionBackgroundColor =
                successResults[0]?.sectionBackgroundColor;

              if (
                !datatableSectionBackgroundColor &&
                this.datatableSectionBackgroundColor
              )
                datatableSectionBackgroundColor =
                  this.datatableSectionBackgroundColor;
              grouprows[
                i
              ].style.backgroundColor = `${datatableSectionBackgroundColor}`;

              let cells = grouprows[i].closest("tr").children;

              for (let k = 0; k < cells.length; k++) {
                if (!cells[k].classList.contains("e-indentcell")) {
                  cells[
                    k
                  ].style.backgroundColor = `${datatableSectionBackgroundColor}`;
                }
              }
              let groupindent = grouprows[i]
                .closest("tr")
                .getElementsByClassName("e-indentcell");

              for (let c = 0; c < groupindent.length; c++) {
                groupindent[c].style.backgroundColor = `#ffffff`;
                if (this.datatableSectionIndentCell)
                  groupindent[c].style.borderColor = `#ffffff`;
              }

              //Subtotal Kısmı
              if (this.showSubtotalInBottom || this.showSubtotalInBoth) {
                for (let d = 0; d < summaryRows.length - 1; d++) {
                  let summaryCells = summaryRows[d].closest("tr").children;

                  for (let s = 0; s < summaryCells.length; s++) {
                    let summaryCellValues = summaryCells[s]
                      .getAttribute("aria-label")
                      ?.split(` ${this.$t("generalPages.total")}:`, 1);

                    let groupRowValue =
                      grouprows[i].getAttribute("ej-mappingvalue");
                    if (summaryCellValues?.includes(groupRowValue)) {
                      let groupRowName =
                        grouprows[i].getAttribute("ej-mappingname");

                      let successSubTotalResult =
                        this.panelDataDetailsSections.filter(
                          (item) => item.field + item.slotFieldId === groupRowName
                        );

                      subTotalBackgroundColor =
                        successSubTotalResult[0]?.subtotalBackgroundColor;

                      if (
                        !subTotalBackgroundColor &&
                        this.datatableSubTotalBackgroundColor
                      )
                        subTotalBackgroundColor =
                          this.datatableSubTotalBackgroundColor;

                      let subTotalRows = summaryCells[s].closest("tr").children;

                      for (let se = 0; se < subTotalRows.length; se++) {
                        if (
                          !summaryCells[se]?.classList.contains("e-indentcell")
                        ) {
                          summaryCells[se].style.backgroundColor =
                            subTotalBackgroundColor;
                          summaryCells[
                            se
                          ].style.height = `${this.datatableRowHeight}px`;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          grouprows[i].querySelectorAll(
            ".e-icon-grightarrow, .e-icon-gdownarrow"
          )[0].style.color = "#000000";
        }

        //Total Kısmı
        let totalIndex = summaryRows?.length - 1;
        let summaryTotalCells = summaryRows[totalIndex]?.closest("tr").children;
        for (let t = 0; t < summaryTotalCells?.length; t++) {
          summaryTotalCells[
            t
          ].style.backgroundColor = `${this.datatableTotalBackgroundColor}`;
          summaryTotalCells[t].style.height = `${this.datatableRowHeight}px`;
        }
      }
    },
    selectContextItem(args) {
      if (args.item.notClicked) return null;

      let contextMenuItemClicked = {
        [chartContextMenuItemKey.INCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.EXCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.DRILL_THROUGH]: {
          serviceFn: () => this.addDrillThroughFilter(args),
        },
      };

      contextMenuItemClicked[args.item.key].serviceFn();

      this.getTableContextMenu().close();
    },
    addDrillThroughFilter(args) {
      this.tableContextMenuData.drillThrough = true;
      this.tableContextMenuData.isActiveFilterFromMenu = true;
      const obj = createChartFilter(this.tableContextMenuData, this.panelData);

      const filterObj = {
        filterValue: obj.value,
        filterId: obj.panelI,
      };

      this.$emit(
        args.item.emitFunction, //emit method name
        filterObj,
        args.item.value //Context meuden seçilen dashboardId geliyor.
      );
    },
    handleDblClickDrillThrough() {
      this.addDrillThroughFilter({
        item: {
          emitFunction: "onChartDrillThroughClick",
          value: this.datatableDrillThroughDashboardList?.[0]?.id,
        },
      });
    },
    addIncludeOrExcludeFilter(args) {
      this.tableContextMenuData.isActiveFilterFromMenu = true;

      const obj = createChartFilter(this.tableContextMenuData, this.panelData);

      obj.isDrillDown = true;
      obj.notRunTheViewChart = args?.item?.notRunTheViewChart;

      if (args.item.key == chartContextMenuItemKey.EXCLUDE)
        obj.operator = filterOperator.NOTEQ;

      this.$emit(args.item.emitFunction, obj);
    },
    getTableContextMenu() {
      return document.getElementById(this.contextMenuId).ej2_instances[0];
    },
    openContext(y, x) {
      this.getTableContextMenu().open(y, x);
    },
    handleSingleClick(filterParams) {
      if (this.datatableEnableSelection) {
        if (deepEqual(this.tableContextMenuData.filterParams, filterParams)) {
          this.tableContextMenuData.filterParams = null;
        } else {
          this.tableContextMenuData.filterParams = filterParams;
        }
        let filterObj = createChartFilter({ filterParams }, this.panelData);
        this.tableContextMenuData.contextTitle = Object.keys(
          filterObj.value
        ).join(" | ");
        this.$emit("addFiltersToPanels", filterObj);
      }
    },
    handleClickData(args) {
      //It will run when you click on the table row
      let rowData = null;

      if (args?.column?.field.includes(SLOT_FIELD_ID_PREFIX.MEA)) {
        // The column clicked in the row is MEASURE
        rowData = args.rowData;
      } else {
        // The column clicked in the row is ATTRIBUTE or DATE
        rowData = {
          [args?.column?.field]: args.rowData?.[args?.column?.field],
        };
      }

      return {
        rowData,
        data: args.data,
      };
    },
    recordClick(args) {
      const filterParams = this.handleClickData(args);

      this.$emit("setContextClickedChartItemId", this.panelData.i);

      if (this.clickTimeout) {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      } else {
        this.clickTimeout = setTimeout(() => {
          this.handleSingleClick(filterParams);
          this.clickTimeout = null;
        }, 300);
      }
    },
    recordDoubleClick(args) {
      const filterParams = this.handleClickData(args);

      this.tableContextMenuData.filterParams = filterParams;

      this.$emit("setContextClickedChartItemId", this.panelData.i);
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
      if (
        this.datatableDrillThroughDashboardList?.length === 1 &&
        this.datatableDrillThroughOnDoubleClick
      ) {
        this.handleDblClickDrillThrough();
      }
    },
    setPageCoordAndOpenContextMenu() {
      document
        .getElementById(this.panelData?.i)
        ?.addEventListener("click", (params) => {
          this.pageCoord.pageY = params.pageY;
          this.pageCoord.pageX = params.pageX;
        });

      document
        .getElementById(this.panelData?.i)
        ?.addEventListener("contextmenu", () => {
          window.event.returnValue = false;
          if (this.tableContextMenuData?.filterParams) {
            this.$emit(
              "setClickedChartItemName",
              this.tableContextMenuData.contextTitle
            );

            this.openContext(this.pageCoord?.pageY, this.pageCoord?.pageX);
          }
        });
    },
    rowDataBound(args) {
      if (
        this.filterValue &&
        !this.isPanelPage &&
        this.datatableEnableSelection
      )
        this.selectedRowHighlight(args);
    },
    actionBeginSettings(event) {
      event.filterChoiceCount = this.totalItems;
    },

    tableActionComplete(event) {
      if (!event) return;
      const { direction, requestType, columnName, columns } = event;
      if (
        requestType === ejsTablePropertyType.SORTING &&
        this.option.isMaxThreshold
      ) {
        this.sortByServerSide(direction, columnName);
      } else if (requestType === ejsTablePropertyType.FILTERING) {
        this.option.isMaxThreshold || this.hasPanelDataServerSideFilters
          ? this.filterByServerSide(columns)
          : null;
      }
    },

    filterByServerSide(paramColumns) {
      const newColumns = this.getTableNewColumns(paramColumns);

      const clonedPanel = cloneDeep(this.panelData);

      let { columns } = clonedPanel.details;

      const filters = this.getNotServerSideTableFilters(
        clonedPanel.details[detailsKeys.FILTERS]
      );

      if (newColumns.length) {
        newColumns.forEach((element) => {
          const panelDataField = columns.find((x) => x.field == element.field);

          filters.push(
            createFilterObj(
              panelDataField,
              {
                operator: element.operator,
                panelI: clonedPanel.i,
                isActiveDashboardFilter: false,
                isServerSideTableFilter: true,
              },
              element.value
            )
          );
        });
      }

      clonedPanel.details[detailsKeys.FILTERS] = filters;

      this.$emit("callChartService", { chart: clonedPanel, isMandatory: true });

      //Eklenen filtreyi serverside_filters listesinde tutma sebebimiz isMaxThreshold false geldiğinde eğer bu liste doluysa yine backend'e istek atabilmek için.
      clonedPanel.details[detailsKeys.SERVERSIDE_FILTERS] = filters.filter(
        (x) => x.isServerSideTableFilter
      );

      //Buradan eklenen filtreyi filters array'inde tutmak istemiyoruz. Ondan kaynaklı filters filtrelenip o şekilde store güncelleniyor.
      clonedPanel.details[detailsKeys.FILTERS] =
        this.getNotServerSideTableFilters(filters);

      this.$emit("updateSelectedPanel", clonedPanel);
    },
    getTableNewColumns(columns) {
      const newColumns = [];

      columns?.forEach((element) => {
        let selectedFilterValue = newColumns.length
          ? newColumns?.find((x) => x.field == element.field)
          : null;

        if (selectedFilterValue) {
          selectedFilterValue.value.push(element.value);
          selectedFilterValue.operator = operator.IN;
        } else {
          let value = [];
          value.push(element.value);
          newColumns.push({
            field: element.field,
            value,
            operator: ejsTableOperatorConversion[element.operator],
          });
        }
      });
      return newColumns;
    },

    getNotServerSideTableFilters(filters) {
      return filters.filter(
        (x) => x.isActiveDashboardFilter || !x.isServerSideTableFilter
      );
    },

    sortByServerSide(direction, columnName) {
      const sortMethod = ejsTableSortTypeConversion[direction] || null;

      const clonedPanel = cloneDeep(this.panelData);

      let { columns } = clonedPanel.details;

      // reset orders as ordering on table supports a single column at a time.
      columns.forEach((element) => {
        element.order = null;
      });

      const fieldToUpdate = columns.find((i) => i.field === columnName);

      if (fieldToUpdate) fieldToUpdate.order = sortMethod;

      this.$emit("updateAndViewSelectedPanel", clonedPanel);
    },
    getFileNameByClientSide() {
      let fileName;

      if (this.panelData.name === "" || !this.panelData.name) {
        this.dashboardName === ""
          ? (fileName = `Untitled/${new Date().getFullYear()}`)
          : (fileName = this.dashboardName);
      } else fileName = this.panelData.name;

      return fileName.substring(0, 30);
    },
    toggleClassForTableSpinnerPane(statu) {
      let spinnerPane = document.querySelectorAll(".e-spinner-pane");

      spinnerPane[0].classList[statu]("e-spinner-pane-show");
    },
    exportComplete() {
      this.isExporting = false;
      this.$refs.grid.hideSpinner();
      this.toggleClassForTableSpinnerPane("remove");
    },
    callExportNotFormatted(evt) {
      this.isExporting = true;
      this.$refs.grid.showSpinner();
      this.toggleClassForTableSpinnerPane("add");

      const { filters, metrics, ...others } = this.panelData?.details;

      const searchQuery = {
        ...others,
        metric: metrics,
        filter: filters,
        dataset: this.panelData?.properties?.dataSet,
        vismetadata: this.panelData?.properties?.vismetadata,
      };

      if (evt == toolbarOptionsEnum.EXCEL_EXPORT) {
        if (this.option.isMaxThreshold) {
          this.createExportMetadata({
            chartId: this.chartId,
            type: this.exportTypes.EXCEL,
            subscriptionId: "TempData",
            searchQuery: {
              ...others,
              metric: metrics,
              filter: filters,
              dataset: this.panelData?.properties?.dataSet,
              vismetadata: this.panelData?.properties?.vismetadata,
            },
          });
          this.exportComplete();
        } else {
          try {
            this.currentExportFormatType = this.exportFormatTypes.notFormatted;

            setTimeout(() => {
              const startTime = new Date();
              const appendExcelExportProperties = {};
              const GridExport = this.$refs.grid.excelExport(
                appendExcelExportProperties,
                true
              );

              GridExport.then((workbook) => {
                const book = new Workbook(workbook, "xlsx");

                const builtInProperties = {
                  tags: "Visualyze",
                };

                book.builtInProperties = builtInProperties;
                book.save(`${this.getFileNameByClientSide()}.xlsx`);

                const endTime = new Date();
                const exportNotificationData = this.getExportNotificationData(
                  {
                    startTime,
                    endTime,
                    type: this.exportTypes.EXCEL,
                    searchQuery,
                  }
                );

                this.$emit("setExportNotification", exportNotificationData);
              });
            }, 2000);
          } catch (e) {
            Notify(e, notificationType.WARNING);
          }
        }
      }
    },
    getExportNotificationData(params) {
      return {
        type: params.type,
        exportableObject: this.exportableObject,
        objectId: this.chartId,
        exportProcessStartTime: params.startTime.toISOString(),
        exportProcessEndTime: params.endTime.toISOString(),
        totalRow: this.totalItems,
        searchQuery: params.searchQuery,
      };
    },
    async callExport(evt) {
      this.isExporting = true;
      this.$refs.grid.showSpinner();
      this.toggleClassForTableSpinnerPane("add");

      const { filters, metrics, ...others } = this.panelData?.details;

      const searchQuery = {
        ...others,
        metric: metrics,
        filter: filters,
        dataset: this.panelData?.properties?.dataSet,
        vismetadata: this.panelData?.properties?.vismetadata,
      };

      if (evt == toolbarOptionsEnum.EXCEL_EXPORT) {
        if (this.option.isMaxThreshold) {
          this.createExportMetadata({
            chartId: this.chartId,
            type: this.exportTypes.EXCEL,
            subscriptionId: "TempData",
            searchQuery: {
              ...others,
              metric: metrics,
              filter: filters,
              dataset: this.panelData?.properties?.dataSet,
              vismetadata: this.panelData?.properties?.vismetadata,
            },
          });
          this.exportComplete();
        } else {
          try {
          this.currentExportFormatType = this.exportFormatTypes.formatted;

          const appendExcelExportProperties = {};

          const GridExport = this.$refs.grid.excelExport(
            appendExcelExportProperties,
            true
          );

          GridExport.then((workbook) => {
            const book = new Workbook(workbook, "xlsx");

            const builtInProperties = {
              tags: "Visualyze",
            };

            book.builtInProperties = builtInProperties;
            book.save(`${this.getFileNameByClientSide()}.xlsx`);
          });
          } catch (e) {
            Notify(e, notificationType.WARNING);
          }
        }
      } else if (evt == toolbarOptionsEnum.CSV_EXPORT) {
        if (this.option.isMaxThreshold) {
          this.createExportMetadata({
            chartId: this.chartId,
            type: this.exportTypes.CSV,
            subscriptionId: "TempData",
            searchQuery: {
              ...others,
              metric: metrics,
              filter: filters,
              dataset: this.panelData?.properties?.dataSet,
              vismetadata: this.panelData?.properties?.vismetadata,
            },
          });
          this.exportComplete();
        } else {
          try {
            const startTime = new Date();
            this.currentExportFormatType = this.exportFormatTypes.formatted;
            this.$refs.grid
              .csvExport({
                fileName: `${this.getFileNameByClientSide()}.csv`,
                separator: ';' 
              })
              .then(() => {
                const endTime = new Date();
                const exportNotificationData = this.getExportNotificationData({
                  startTime,
                  endTime,
                  type: this.exportTypes.CSV,
                  searchQuery,
                });
                this.$emit("setExportNotification", exportNotificationData);
              });
          } catch (e) {
            Notify(JSON.stringfy(e), notificationType.WARNING);
          }
        }
      } else {
        const calculatePage = () => {
          if (this.panelDataDetailsColumns?.length <= 5) {
            return {
              pageSize: this.pageSizeTypes.A4,
              pageOrientation: this.pageOrientationTypes.Portrait,
            };
          } else if (
            this.panelDataDetailsColumns?.length > 5 &&
            this.panelDataDetailsColumns?.length <= 8
          ) {
            return {
              pageSize: this.pageSizeTypes.A4,
              pageOrientation: this.pageOrientationTypes.Landscape,
            };
          } else if (this.panelDataDetailsColumns?.length <= 12) {
            return {
              pageSize: this.pageSizeTypes.A3,
              pageOrientation: this.pageOrientationTypes.Landscape,
            };
          } else if (
            this.panelDataDetailsColumns?.length > 12 &&
            this.panelDataDetailsColumns?.length <= 17
          ) {
            return {
              pageSize: this.pageSizeTypes.A2,
              pageOrientation: this.pageOrientationTypes.Landscape,
            };
          } else if (
            this.panelDataDetailsColumns?.length > 20 &&
            this.panelDataDetailsColumns?.length <= 30
          ) {
            return {
              pageSize: this.pageSizeTypes.A1,
              pageOrientation: this.pageOrientationTypes.Landscape,
            };
          } else {
            return {
              pageSize: this.pageSizeTypes.A0,
              pageOrientation: this.pageOrientationTypes.Landscape,
            };
          }
        };

        const resultPageSize = this
          .datatablePdfExportCalculatedPageSizeAutomatically
          ? calculatePage()
          : {
              pageSize: this.datatablePdfExportPageSize,
              pageOrientation: this.datatablePdfExportPageOrientationType,
            };

        //BACKEND HAZIR OLUNCA YORUMLU ALAN DEVREYE ALINACAK
        this.currentExportFormatType = this.exportFormatTypes.formatted;
        
        let base64Image = null;
      
        if (this.datatablePdfExportHeaderImage && this.datatablePdfExportHeaderImage !== '') {
          //this.datatablePdfExportHeaderImage => tabloda, pdf setup içindeki seçilen resmin id'sini temsil eder. JPEG formatını destekliyor
          base64Image = await this.fetchImageBase64({ imageId: this.datatablePdfExportHeaderImage });
        }
        
        try {
          let pdfExportProperties = {
          header: {
            fromTop: 0,
            height: 80,
            contents: [
                this.datatablePdfExportShowHeader && this.datatablePdfExportHeaderText !== ''
                  ? {
                      type: "Text", // is active type switch
                      value: this.replacePdfHeaderTextByFilterChartParameter(), // text value input
                      position: { x: 280, y: 20 }, // center and top 0 for a4
                      style: {
                        textBrushColor: "#000000",
                        fontSize: this.datatablePdfExportHeaderTextSize,
                      },
                      font: new PdfTrueTypeFont(arialFont, this.datatablePdfExportHeaderTextSize),
                    }
                  : null,
                base64Image && this.datatablePdfExportShowHeaderImage
                  ? {
                      type: "Image", // is active type switch
                      src: base64Image,
                      position: { x: 0, y: 0 }, //position left, center, right mı yada koordinat mı ?
                      size: { height: 50, width: 100 }, // image height, width
                    }
                  : null,
              ].filter((x) => x),
          },
          footer: {
            fromBottom: 0,
            height: 60,
            contents: [
              this.datatablePdfExportShowPageNumber ?
              {
                type: 'PageNumber',
                pageNumberType: 'Number',
                // format: 'Page {$current} of {$total}', //optional
                position: { x: (595 - 14) / 2, y: 25 },
                style: {
                  textBrushColor: '#000000',
                  fontSize: 14,
                  hAlign: 'Center',
                },
              } : null,
            ].filter((x) => x),
          },
        };
         
          const gridBorderWidth = this.datatablePdfExportShowGridBorder ? 1 : 0; 
          const pdfOptions = {
            fileName: `${this.getFileNameByClientSide()}.pdf`,
            theme: {
              //HEADER
              //repeat özelliği pdfHeaderQueryCellInfo fonksiyonu içinde çalışıyor
              header: {
                font: new PdfTrueTypeFont(arialFont, 11),
                fontColor: this.datatableColumnFontColor !== '' ? this.datatableColumnFontColor : '#000000',
                bold: this.datatableColumnFontWeight === 600,
                border: { color: '#dee2e6', dashStyle: 'Solid', width: gridBorderWidth, lineStyle: 'thin' },
              },
              //ROW
              record: {
                font: new PdfTrueTypeFont(arialFont, 10),
                fontColor: this.datatableFontColor !== '' ? this.datatableFontColor :'#000000',
                border: { color: '#dee2e6', dashStyle: 'Solid', width: gridBorderWidth, lineStyle: 'thin' },
              },
              //TOTAL 
              caption: {
                font: new PdfTrueTypeFont(arialFont, 9),
                fontColor: this.datatableTotalFontColor !== '' ? this.datatableTotalFontColor : '#000000',
              },
            },
            ...resultPageSize,
            ...pdfExportProperties,
          };

          const startTime = new Date();
        
          this.$refs.grid.pdfExport(pdfOptions).then(() => {
            const endTime = new Date();
            const exportNotificationData = this.getExportNotificationData({
              startTime,
              endTime,
              type: this.exportTypes.PDF,
              searchQuery,
            });
            this.$emit("setExportNotification", exportNotificationData);
          });
        } catch (e) {
          Notify(JSON.stringfy(e), notificationType.WARNING);
        }
      }
    },
    replacePdfHeaderTextByFilterChartParameter() {
      let pdfExportHeaderText = cloneDeep(this.datatablePdfExportHeaderText);
      
      if (!this.datatablePdfExportShowHeader || !this.filterChartList?.length)
        return pdfExportHeaderText;

      this.filterChartList.forEach((filterChart) => {
        const key =
          filterChart.properties.style[
            this.getKeyByIncludes(filterChart, includesValues.UNIQUE_NAME)
          ];
        const value =
          filterChart?.details?.FILTER_VALUE?.[
            filterChart?.details?.metrics[0]?.field
          ]?.value;
         //const noSelectionContentValue = filterChart?.properties?.style?.[chartPropertiesEnum.FILTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT];
      
        if (pdfExportHeaderText.includes(key) && value) {
          pdfExportHeaderText = pdfExportHeaderText.replaceAll(
            `<${key}>`,
            value
          );
        } else if (!value) {
            pdfExportHeaderText = pdfExportHeaderText.replaceAll(`<${key}>`, "");
        }
      });
      
      return pdfExportHeaderText;
    },
    getKeyByIncludes(obj, includesValue) {
      let keys = Object.keys(obj?.properties?.style);
      return keys.find((x) => x.includes(includesValue));
    },
  },
};
</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
</style>

<style scoped>
.ejs-table-container {
  height: 100%;
}
::v-deep .e-dialog .e-footer-content .e-primary {
  color: white !important;
}

::v-deep .e-grid {
  height: 100%;
}
::v-deep .e-content {
  height: 100% !important;
}
::v-deep .e-grid .e-sortfilterdiv {
  margin: -22px 10px;
}

::v-deep .isDisableEmptyRow .e-emptyrow {
  display: none !important;
}

::v-deep .e-columnheader .e-headercell .e-rhandler{
    height: 100% !important;
  }

::v-deep .e-grid .e-gridheader .e-fltr-icon .e-sortfilterdiv {
  margin: -11px 10px -10px 0px !important;
}
::v-deep .e-grid .e-sortfilterdiv {
  margin: -12px -4px !important;
}
::v-deep .e-grid .e-filtermenudiv {
  margin: -10px -6px !important;
}
::v-deep .e-grid .e-icon-gdownarrow {
  font-size: 10px;
  text-indent: 7px;
}
::v-deep .e-grid .e-icon-grightarrow {
  font-size: 10px;
  text-indent: 7px;
}
::v-deep .e-group-intent {
  width: 7px !important;
}
::v-deep .vis-sumTemplate-span {
  position: relative;
  margin-right: 20px;
  white-space: nowrap;
}
::v-deep .vis-sumTemplate-icon {
  position: absolute;
  top: 0;
  right: -20px;
  font-size: 1rem;
  cursor: pointer;
}
::v-deep .e-headercontent .e-table {
  padding-right: 10px !important;
}
::v-deep .e-gridcontent .e-content {
  overflow-y: scroll !important;
}
::v-deep .e-spinner-pane {
  display: none !important;
}
::v-deep .vis-ellipsis-context-menu {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
::v-deep .e-spinner-pane-show {
  display: block !important;
}
::v-deep .e-contextmenu-wrapper ul .e-menu-item {
  padding: 0 !important;
}
</style>
