import { CustomIcon } from "../assets/js/custom-icons";
import i18n from "../i18n";
import { detailsKeys } from "../commons/dashboardAndPanel";
import { DATAMODEL_DEFAULT_PROPERTIES_CONFIGURATIONS } from "../mocks/datamodelDefaultProperties";
import { SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM } from "./dashboardProperties";

export const importTypes = {
  CSV: "CSV",
  EXCEL: "EXCEL",
  DB: "DB",
};

export const statusTypes = {
  uploading: "uploading",
  finished: "finished",
};

export const loadBehaviourOptions = [
  {
    key: "append_new_data",
    label: "generalPages.labelAppendNewData",
  },
];

export const tableTypeOptions = [
  {
    key: "DIMENSION",
    label: "generalPages.dim",
    value: "DIMENSION",
  },
  {
    key: "FACT",
    label: "generalPages.fact",
    value: "FACT",
  },
];

export const customFilterTypes = {
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  DATE: "DATE",
  DATERANGE: "DATERANGE",
};

export const customFilterOptions = [
  {
    key: customFilterTypes.NUMBER,
    label: "generalPages.number",
  },
  {
    key: customFilterTypes.TEXT,
    label: "generalPages.text",
  },
  {
    key: customFilterTypes.DATE,
    label: "generalPages.date",
  },
];

export const DatasetFieldTypes = {
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
};

export const DatamodelContextDefaults = {
  COLUMN_BASED_FORMATTING: "COLUMN_BASED_FORMATTING",
  ALIAS: "ALIAS",
  SCREEN_TIP: "SCREEN_TIP",
  DESCRIPTION: "DESCRIPTION",
  FIELD_POPUP: "FIELD_POPUP",
  FILTER: "FILTER",
  FORMAT: "FORMAT",
  GROUP: "GROUP",
  DRILL_PATH: "DRILL_PATH",
  DUPLICATE: "DUPLICATE",
  HIDE: "HIDE",
  SHOW: "SHOW",
  FILTER_POPUP: "FILTER_POPUP",
  DELETE: "DELETE",
  USAGE_TYPES: {
    MEASURE: "MEASURE",
    DATE: "DATE",
    TEXT: "TEXT",
    BOOLEAN: "BOOLEAN",
    ATTRIBUTE: "ATTRIBUTE",
    CUSTOM_FILTERS: "CUSTOM_FILTERS",
    NONE: "NONE",
    SELECTOR_DATA_ITEM: "SELECTOR_DATA_ITEM"
  },
  SUMMARIZATION_RULE: {
    SUM: "SUM",
    AVG: "AVG",
    MAX: "MAX",
    MIN: "MIN",
    COUNT: "COUNT",
    DISTINCT_COUNT: "DISTINCT_COUNT",
    NONE: "NONE",
    SAME_AS_MEASURE: "SAME_AS_MEASURE",
  },
  BARS: {
    ASC: "asc",
    DESC: "desc",
    SUM_ASC_DESC: "sumAscDesc",
    TOP_BOTTOM_POPUP: "TOP_BOTTOM_POPUP",
    DO_NOT_SORT: null,
  },
  DISTINCT: "DISTINCT",
  NEW_CALCULATION: "NEW_CALCULATION",
  EDIT_CALCULATION: "EDIT_CALCULATION",
  DELETE_CALCULATION: "DELETE_CALCULATION",
  TAB_NAME: "TAB_NAME",
  SECTION_BACKGROUND: "SECTION_BACKGROUND",
  CONDITIONAL_FORMATTING: "CONDITIONAL_FORMATTING",
  SECURITY: "SECURITY",
  PROPERTIES: "PROPERTIES",
  OBJECT_LOCK: "OBJECT_LOCK",
  OBJECT_UNLOCK: "OBJECT_UNLOCK",
  DATASET: "DATASET",
  UNSAVE_DASHBOARD: "UNSAVE_DASHBOARD",
  REFRESH_CACHE: "REFRESH_CACHE"
};
export const checkTypeSelectorDataItem = (type) => {
  return type === DatamodelContextDefaults.USAGE_TYPES.SELECTOR_DATA_ITEM;
}
export const ContextMenuViewType = {
  DATAMODEL: "DATAMODEL",
  DASHBOARD: "DASHBOARD",
  CHART: "CHART",
};
export const contextMenuItemKeys = {
  USAGE_TYPES: "USAGE_TYPES",
  ORDER_BY: "ORDER_BY",
  AGG_FUNCTION: "AGG_FUNCTION",
};
const measureRuleTexts = {
  [DatamodelContextDefaults.SUMMARIZATION_RULE.SUM]:
    i18n.tc("generalPages.sum"),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.MAX]:
    i18n.tc("generalPages.max"),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.AVG]:
    i18n.tc("generalPages.avg"),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.MIN]:
    i18n.tc("generalPages.min"),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT]:
    i18n.tc("generalPages.count"),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.DISTINCT_COUNT]: i18n.tc(
    "generalPages.distinctCount"
  ),
  [DatamodelContextDefaults.SUMMARIZATION_RULE.NONE]:
    i18n.tc("generalPages.none"),
};
const aggregationFunctionMaxMinList = [
  {
    text: measureRuleTexts[DatamodelContextDefaults.SUMMARIZATION_RULE.MAX],
    value: DatamodelContextDefaults.SUMMARIZATION_RULE.MAX,
    key: contextMenuItemKeys.AGG_FUNCTION,
  },
  {
    text: measureRuleTexts[DatamodelContextDefaults.SUMMARIZATION_RULE.MIN],
    value: DatamodelContextDefaults.SUMMARIZATION_RULE.MIN,
    key: contextMenuItemKeys.AGG_FUNCTION,
  },
];

const MeasureTypeSummarizationRules = [
  {
    text: measureRuleTexts[DatamodelContextDefaults.SUMMARIZATION_RULE.SUM],
    value: DatamodelContextDefaults.SUMMARIZATION_RULE.SUM,
    key: contextMenuItemKeys.AGG_FUNCTION,
  },
  {
    text: measureRuleTexts[DatamodelContextDefaults.SUMMARIZATION_RULE.AVG],
    value: DatamodelContextDefaults.SUMMARIZATION_RULE.AVG,
    key: contextMenuItemKeys.AGG_FUNCTION,
  },
  ...aggregationFunctionMaxMinList
];

const getAggregationFunctionItemList = (fieldType) => {
  return [
      ...(fieldType === DatasetFieldTypes.NUMBER
        ? MeasureTypeSummarizationRules
        : []),
        ...(fieldType === DatasetFieldTypes.TEXT ||
          fieldType === DatasetFieldTypes.DATE
            ? aggregationFunctionMaxMinList
            : []),
      {
        text: measureRuleTexts[
          DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT
        ],
        value: DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT,
        key: contextMenuItemKeys.AGG_FUNCTION,
      },
      {
        text: measureRuleTexts[
          DatamodelContextDefaults.SUMMARIZATION_RULE.DISTINCT_COUNT
        ],
        value: DatamodelContextDefaults.SUMMARIZATION_RULE.DISTINCT_COUNT,
        key: contextMenuItemKeys.AGG_FUNCTION,
      },
      {
        text: measureRuleTexts[
          DatamodelContextDefaults.SUMMARIZATION_RULE.NONE
        ],
        value: DatamodelContextDefaults.SUMMARIZATION_RULE.NONE,
        key: contextMenuItemKeys.AGG_FUNCTION,
      },
    ]
};
// alınan parametreleri objeye çıkar TODO
export const getDatamodelFieldContextMenu = (
  isCalculated,
  itemType,
  viewType,
  itemUsageType,
  field,
  // dashboard ekranından veya dattamodel dısındaki herhangi bir ekrandan acıldıgında
  // "isPageDatamodel" parametresi ile bazı context menu itemlar gizlenebilir
  { isShowButtonActive = false, isColumnBasedFormattingActive = false, isPageDatamodel = false }
) => {
    // viewType === ContextMenuViewType.CHART => Chart edit sayfasındaki slot içindeki fieldlar için kullanılır!!
    // viewType === ContextMenuViewType.DATAMODEL => Datamodel sayfasındaki fieldlar için kullanılır!!
    // viewType === ContextMenuViewType.DASHBOARD => Dashbboard datamodel sekmesindeki fieldlar için kullanılır!!
    
  return [
    {
      text: i18n.tc("generalPages.rename"),
      value: DatamodelContextDefaults.ALIAS,
      iconCss: CustomIcon.Title,
      key: DatamodelContextDefaults.ALIAS,
    },
    {
      text: i18n.tc("generalPages.screenTip"),
      value: DatamodelContextDefaults.SCREEN_TIP,
      iconCss: CustomIcon.TipsAndUpdated,
      key: DatamodelContextDefaults.SCREEN_TIP,
    },
    {
      text: i18n.tc("generalPages.description"),
      value: DatamodelContextDefaults.DESCRIPTION,
      iconCss: CustomIcon.Description,
      key: DatamodelContextDefaults.DESCRIPTION,
    },
    {
      separator: true,
    },
    getUsageTypeItemAction(itemType),
    {
      text: i18n.tc("generalPages.aggregationFunction"),
      items: getAggregationFunctionItemList(itemType),
      iconCss: CustomIcon.Functions,
    },
    (!isCalculated && viewType === ContextMenuViewType.DATAMODEL) ||
    viewType === ContextMenuViewType.DASHBOARD
      ? {
          text: i18n.tc("generalPages.newCalculation"),
          value: DatamodelContextDefaults.NEW_CALCULATION,
          iconCss: CustomIcon.CalculateAlt,
          field,
        }
      : null,
    (isCalculated &&
      !field?.sourceFieldId &&
      viewType === ContextMenuViewType.DATAMODEL) ||
    viewType === ContextMenuViewType.DASHBOARD
      ? {
          text: i18n.tc("generalPages.editCalculation"),
          value: DatamodelContextDefaults.EDIT_CALCULATION,
          iconCss: CustomIcon.CalculateAlt,
          field,
        }
      : null,
    field.fieldType === DatasetFieldTypes.TEXT &&
    checkMaxOrMinTypeIncludesByParam(field.type)
      ? null
      : itemUsageType !== DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      ? formatItem
      : null,
    {
      separator: true,
    },
    viewType === ContextMenuViewType.DATAMODEL ||
    viewType === ContextMenuViewType.DASHBOARD
      ? {
          text: i18n.tc("generalPages.filter"),
          value: DatamodelContextDefaults.FILTER,
          iconCss: CustomIcon.FilterAltOutlined,
        }
      : null,
    viewType !== ContextMenuViewType.DATAMODEL
      ? {
          text: i18n.tc("generalPages.sortBy"),
          items: [
            {
              text: i18n.tc("generalPages.ascending"),
              value: DatamodelContextDefaults.BARS.ASC,
              key: contextMenuItemKeys.ORDER_BY,
            },
            {
              text: i18n.tc("generalPages.descending"),
              value: DatamodelContextDefaults.BARS.DESC,
              key: contextMenuItemKeys.ORDER_BY,
            },
            {
              text: i18n.tc("generalPages.dontSort"),
              value: DatamodelContextDefaults.BARS.DO_NOT_SORT,
              key: contextMenuItemKeys.ORDER_BY,
            },
          ],
          iconCss: CustomIcon.SortThin,
        }
      : null,
    viewType !== ContextMenuViewType.DATAMODEL &&
    itemUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      ? {
          text: i18n.tc("generalPages.topBottom"),
          key: DatamodelContextDefaults.BARS.TOP_BOTTOM_POPUP,
          iconCss: CustomIcon.UpLineArrow,
        }
      : null,
    viewType === ContextMenuViewType.CHART && isColumnBasedFormattingActive
      ? {
          text: i18n.tc("generalPages.properties"),
          value: field,
          iconCss: CustomIcon.Filter,
          key: DatamodelContextDefaults.COLUMN_BASED_FORMATTING,
        }
      : null,
    itemUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      ? {
          text: field?.sourceFieldId
            ? i18n.tc("generalPages.editGroup")
            : i18n.tc("generalPages.group"),
          key: DatamodelContextDefaults.GROUP,
          value: DatamodelContextDefaults.GROUP,
          iconCss: CustomIcon.GroupWork,
        }
      : null,
    viewType === ContextMenuViewType.DATAMODEL ||
    viewType === ContextMenuViewType.DASHBOARD
      ? {
          text: i18n.tc("generalPages.drillPath"),
          value: DatamodelContextDefaults.DRILL_PATH,
          iconCss: CustomIcon.Lan,
        }
      : null,
    {
      separator: true,
    },
    viewType === ContextMenuViewType.DATAMODEL ||
    viewType === ContextMenuViewType.DASHBOARD
      ? {
          text: i18n.tc("generalPages.duplicate"),
          value: DatamodelContextDefaults.DUPLICATE,
          iconCss: CustomIcon.ContentCopy,
        }
      : null,
    viewType === ContextMenuViewType.DATAMODEL ||
    viewType === ContextMenuViewType.DASHBOARD
      ? isShowButtonActive
        ? {
            text: i18n.tc("generalPages.show"),
            value: DatamodelContextDefaults.SHOW,
            iconCss: CustomIcon.VisibilityEye,
          }
        : {
            text: i18n.tc("generalPages.hide"),
            value: DatamodelContextDefaults.HIDE,
            iconCss: CustomIcon.VisibilityOff,
          }
      : null,
    viewType === ContextMenuViewType.DATAMODEL && isPageDatamodel
      ? {
          separator: true,
        }
      : null,
    viewType === ContextMenuViewType.DATAMODEL && isPageDatamodel
      ? {
          text: i18n.tc("generalPages.Security"),
          value: DatamodelContextDefaults.SECURITY,
          iconCss: CustomIcon.AdminPanelSettings,
        }
      : null,
    viewType === ContextMenuViewType.DATAMODEL && isPageDatamodel
      ? {
          text: i18n.tc("generalPages.Properties"),
          value: DatamodelContextDefaults.PROPERTIES,
          iconCss: CustomIcon.Description,
        }
      : null,
    viewType === ContextMenuViewType.CHART
      ? getDeleteItemAction()
      : null,
    (isCalculated || !field?.baseColumn || !field?.valid) && viewType !== ContextMenuViewType.CHART
      ? {
          text: i18n.tc("generalPages.delete"),
          value: DatamodelContextDefaults.DELETE_CALCULATION,
          iconCss: CustomIcon.DeleteCalculation,
          field,
        }
      : null,
  ].filter((x) => x);
};

export const formatItem = {
  text: i18n.tc("generalPages.format"),
  value: DatamodelContextDefaults.FORMAT,
  iconCss: CustomIcon.FormatPaint,
  key: DatamodelContextDefaults.FORMAT,
};

export const getDeleteItemAction = () => {
  return {
    text: i18n.tc("generalPages.delete"),
    value: DatamodelContextDefaults.DELETE,
    iconCss: CustomIcon.TrashCircle,
    key: DatamodelContextDefaults.DELETE,
  };
};

export const getUsageTypeItemAction = (itemType) => {
    return {
      text: i18n.tc("generalPages.usageType"),
      items: [
        {
          text: i18n.tc("generalPages.labelAttribute"),
          value: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
          key: contextMenuItemKeys.USAGE_TYPES,
        },
        itemType === DatasetFieldTypes.DATE
          ? {
              text: i18n.tc("generalPages.labelDate"),
              value: DatamodelContextDefaults.USAGE_TYPES.DATE,
              key: contextMenuItemKeys.USAGE_TYPES,
            }
          : null,
        {
          text: i18n.tc("generalPages.labelMeasure"),
          value: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
          key: contextMenuItemKeys.USAGE_TYPES,
        },
      ].filter((i) => i),
      iconCss: CustomIcon.Abc,
    };
  };

export const getSliceItem = (params) => {
  return params?.slotType !== detailsKeys.SECTIONS
    ? [
        {
          text: i18n.tc("generalPages.sortBy"),
          items: [
            {
              text: i18n.tc("generalPages.byNameAscending"),
              value: DatamodelContextDefaults.BARS.ASC,
            },
            {
              text: i18n.tc("generalPages.byNameDescending"),
              value: DatamodelContextDefaults.BARS.DESC,
            },
            {
              text: i18n.tc("generalPages.dontSort"),
              value: DatamodelContextDefaults.BARS.DO_NOT_SORT,
            },
          ],
          iconCss: CustomIcon.SortThin,
        },
        params.slotType === detailsKeys.CONTENT ?  {
          text: i18n.tc("generalPages.rename"),
          value: DatamodelContextDefaults.ALIAS,
          iconCss: CustomIcon.Title,
          key: DatamodelContextDefaults.ALIAS,
        } : null,
        params.slotType === detailsKeys.CONTENT ? {
            text: i18n.tc("generalPages.aggregationFunction"),
            items: aggregationFunctionMaxMinList,
            iconCss: CustomIcon.Functions,
          }: null,
        params.fieldUsageType !== DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
          ? formatItem
          : null,
        {
          text: i18n.tc("generalPages.topBottom"),
          value: DatamodelContextDefaults.BARS.TOP_BOTTOM_POPUP,
          key: DatamodelContextDefaults.BARS.TOP_BOTTOM_POPUP,
          iconCss: CustomIcon.UpLineArrow,
        },
        params.slotType === detailsKeys.SECTIONS
          ? {
              text: i18n.tc("panelProperties.sectionBackgroundColor"),
              value: DatamodelContextDefaults.SECTION_BACKGROUND,
              iconCss: CustomIcon.FormatPaint,
            }
          : null,
        getDeleteItemAction(),
      ].filter((x) => x)
    : [
        {
          text: i18n.tc("panelProperties.sectionBackgroundColor"),
          value: DatamodelContextDefaults.SECTION_BACKGROUND,
          iconCss: CustomIcon.FormatPaint,
        },
        getDeleteItemAction(),
      ].filter((x) => x);
};

export const getSortOrdersMenuItem = (fieldItem, isChartTypeFilter) => {
  return [
    {
      text: i18n.tc("generalPages.sortBy"),
      items: [
        {
          text: i18n.tc("generalPages.ascending"),
          value: DatamodelContextDefaults.BARS.ASC,
          key: contextMenuItemKeys.ORDER_BY,
        },
        {
          text: i18n.tc("generalPages.descending"),
          value: DatamodelContextDefaults.BARS.DESC,
          key: contextMenuItemKeys.ORDER_BY,
        },
      ],
      iconCss: CustomIcon.SortThin,
    },
    getUsageTypeItemAction(fieldItem?.fieldType),
    //Filtrelerde sort yapınca agg functions çalışmıyor sorgu şuanlık yetersiz
    //sadece none durumu karşılanıyor bu yüzden filtrelerde agg funcs kısmı kapatıldı!!
    isChartTypeFilter ? null : {
        text: i18n.tc("generalPages.aggregationFunction"),
        items: getAggregationFunctionItemList(fieldItem?.fieldType),
        iconCss: CustomIcon.Functions,
      },
    getDeleteItemAction(),
  ].filter((x) => x);
};

export const filtersItem = () => {
  return [
    {
      text: i18n.tc("generalPages.filter"),
      value: DatamodelContextDefaults.FILTER_POPUP,
      iconCss: CustomIcon.FilterAltOutlined,
    },
    getDeleteItemAction(),
  ];
};

export const alignItems = {
  LEFT: "left",
  RIGHT: "right",
};

export const BOX_OPTIONS = {
  SINGLE_METRIC: "SINGLE_METRIC",
  SINGLE_TYPE: "SINGLE_TYPE",
};
export const SORT = {
  BY_NAME_ASCENDING: "BY_NAME_ASCENDING",
  BY_NAME_DESCENDING: "BY_NAME_DESCENDING",
  CUSTOM: "CUSTOM"
};
export const AUTO_RENAME = {
  REPLACE_UNDERSCORE_WITH_SPACE: "REPLACE_UNDERSCORE_WITH_SPACE", 
  CAPITALIZE_FIRST_LETTER: "CAPITALIZE_FIRST_LETTER", 
  RESET: "RESET",
  BEAUTIFY_ALIASES: "BEAUTIFY_ALIASES",
};
export const DIAGRAM_LIST = {
  VIEW_DATA: "VIEW_DATA",
  SHOW_ON_DIAGRAM: "SHOW_ON_DIAGRAM",
};
export const getBeautifyMenuItemList=({isVieWDataActive}) => {
  return isVieWDataActive ?
  [{
    text: i18n.tc("datamodel.View Data"),
    value:DIAGRAM_LIST.VIEW_DATA
  }]
  :
  [{
      text: i18n.tc("datamodel.Auto Rename"),
      items: [
        {
          text: i18n.tc('datamodel.Beautify Aliases'),
          value: AUTO_RENAME.BEAUTIFY_ALIASES
        },
        {
            text: i18n.tc('datamodel.Replace Underscores with Spaces'),
            value: AUTO_RENAME.REPLACE_UNDERSCORE_WITH_SPACE
        },
        {
            text: i18n.tc('datamodel.Capitalize First Letter'),
            value: AUTO_RENAME.CAPITALIZE_FIRST_LETTER
        },
        {
            text: i18n.tc('datamodel.Reset Aliases'),
            value: AUTO_RENAME.RESET
        },
      ],
    },
    {
      text: i18n.tc("datamodel.Sort"),
      items: [
        {
          text: i18n.tc('datamodel.By Name Ascending'),
          value: SORT.BY_NAME_ASCENDING
        },
        {
            text: i18n.tc('datamodel.By Name Descending'),
            value: SORT.BY_NAME_DESCENDING
        },
        {
            text: i18n.tc('datamodel.Custom'),
            value: SORT.CUSTOM,
            disabled: true
        },
      ],
    },
    {
      text: i18n.tc("datamodel.View Data"),
      value:DIAGRAM_LIST.VIEW_DATA
    },
    {
      text: i18n.tc("datamodel.Show on Diagram"),
      value: DIAGRAM_LIST.SHOW_ON_DIAGRAM
    }
    ]
  };
  
  //cause of the minor key values are to keep migration stable
  export const datamodelPropertyKeys = {
    ALLOW_AI_ACTIONS: "allowAiActions",
    IS_TIMEOUT_ACTIVE: "isTimeoutActive",
    TIMEOUT_INPUT_VALUE: "timeoutInputValue",
    KEEP_ONE_DATASET_EXPANDED: "KEEP_ONE_DATASET_EXPANDED",
    DATAMODEL_USE_CACHE: "DATAMODEL_USE_CACHE",
    DATAMODEL_CACHE_DURATION: "DATAMODEL_CACHE_DURATION",
    DATAMODEL_USE_DEFAULT_CACHE_DURATION: "DATAMODEL_USE_DEFAULT_CACHE_DURATION",
    DATAMODEL_FORCE_CACHE_DURATION: "DATAMODEL_FORCE_CACHE_DURATION",
  }

  export const getCommonDatamodelPropertyValueByKey = (datamodelProperties, propertyKey) => {
    return datamodelProperties?.[propertyKey] === undefined
    ? DATAMODEL_DEFAULT_PROPERTIES_CONFIGURATIONS[propertyKey]
    : datamodelProperties?.[propertyKey];
  }

  export const checkMaxOrMinTypeIncludesByParam = (fieldType) => {
    return [
      DatamodelContextDefaults.SUMMARIZATION_RULE.MAX,
      DatamodelContextDefaults.SUMMARIZATION_RULE.MIN,
    ].includes(fieldType);
  }

  export const mapSelectorChartParameterList = (selectorCharts) => {
    return selectorCharts.map((m) => {
      return {
        alias: m?.properties?.style?.[SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME],
        fieldId: m?.i,
        type: DatamodelContextDefaults.USAGE_TYPES.SELECTOR_DATA_ITEM,
        fieldType: DatamodelContextDefaults.USAGE_TYPES.SELECTOR_DATA_ITEM,
        isAuthorize: true
      };
    }).filter(x=> x.alias);
  };