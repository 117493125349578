import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import {
  createMetricKey, getConcatArrayWithPipe, getTooltipHeadersForGeneralCharts, isColorBySlotFullAndBarsSlotEmpty, mapChartDataByColorSlot
} from "../chartOptionsHelper";
import { purgeDuplicatesFromArray } from "../utils";

export const generalChartSearch = (params, searchData, chartI) => {
  const metricKeys = params?.metric?.map((a) => createMetricKey(a));
  const aggregationKeys = params?.aggregation?.filter(i => i.boxKey !== BOX_KEY_ENUM.COLORBY.KEY).map((a) => a.field);
  const colorByKeys = params?.aggregation?.filter(i => i.boxKey === BOX_KEY_ENUM.COLORBY.KEY).map((a) => a.field);
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const metricFormatsMapping = params?.metric?.reduce((accumulator, value) => {
    return { ...accumulator, [value.field]: value.format };
  }, {});

  const getMappedAggregationListBySlot = (arr, i) => {
    return arr.map((key) => i[key])
  }

  let metricFormats = [],
    dataSeries = [],
    dataSeriesNames = [],
    tooltipHeaders = getTooltipHeadersForGeneralCharts(params?.aggregation, params.chartType);

  let xAxisKeys = searchData?.aggregations?.map((i) => {
    return getConcatArrayWithPipe(
      isColorBySlotFullAndBarsSlotEmpty(params?.aggregation) ? getMappedAggregationListBySlot(colorByKeys, i) : getMappedAggregationListBySlot(aggregationKeys, i)
    )
  });
  
  xAxisKeys = purgeDuplicatesFromArray(xAxisKeys);

  const mapByColor = mapChartDataByColorSlot(params.metric, params.aggregation, searchData?.aggregations, xAxisKeys);
  
  if (mapByColor) {
    dataSeries = mapByColor.dataSeries;
    dataSeriesNames = mapByColor.dataSeriesNames;
    metricFormats = mapByColor.metricFormats;
  } else {
    dataSeriesNames = params?.metric?.map((e) => e.alias);
    dataSeries = metricKeys.map(m => {
      return searchData?.aggregations?.map(i => i[m])
    });
    metricFormats = params?.metric?.map((a) => a.format);
  }

  if (isColorBySlotFullAndBarsSlotEmpty(params?.aggregation)) {
    dataSeries = [dataSeries.flat(1)]
  }

  let dataSeriesTotal = 0;
  if (dataSeries?.length) {
    dataSeries?.forEach((item) =>
      item?.forEach((x) => (dataSeriesTotal += parseInt(x)))
    );
  }
   
 
  return {
    metricFormats,
    metricFormatsMapping,
    dataSeries,
    dataSeriesTotal,
    dataAxis: xAxisKeys,
    dataSeriesNames,
    aggregationFormats,
    tooltipHeaders,
    id: chartI
  };
};

