<template>
  <el-dialog width="480px" :visible="dialogVisible" class="vis-info-box">
    <div v-if="!isSofttechTextHidden" class="vis-info-img">
      <img
        :src="getImgUrl(image)"
        v-bind:alt="image"
        width="50px"
        height="50px"
      />
    </div>
    <div class="vis-info-content">
      <h2 v-if="!isSofttechTextHidden">{{ title }}</h2>
      <h3>{{ info }}</h3>
      <div v-if="!isSofttechTextHidden" class="vis-info-footer">{{ footerInfo }}</div>
    </div>
    <span slot="footer" class="vis-info-box-footer">
      <el-button
        type="text"
        class="vis-info-confirm-btn"
        @click="$emit('cancel', false)"
        >{{ $t("infoBox.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    info: {
      type: String,
    },
    footerInfo: {
      type: String,
    },
    dialogVisible: {
      type: Boolean,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      customerEnv: process.env.VUE_APP_CUSTOMER_NAME,
    };
  },
  computed: {
    isSofttechTextHidden() {
      return window?.customerConfig?.[this.customerEnv]?.GENERAL?.isSofttechTextHidden;
    }
  },
  methods: {
    getImgUrl(image) {
      var images = require.context("../../assets/images/", false, /\.png$/);
      return images("./" + image + ".png");
    },
  },
};
</script>
<style scoped></style>
