<template>
  <div
    class="vis-chart-card-title"
    :class="[
      { 'vis-flex--Xend': !this.isChartViewLimit },
      { 'vis-display-none': isPanelTypeButton && isModeDashboardView },
      { 'vis-ma--none': isHiddenTitleElements },
    ]"
  >
    <!-- INPUT START -->
    <div
      v-show="isChartViewLimit && !isHiddenTitleElements"
      class="vis-chart-card-input-box"
    >
      <!-- START TEXTAREA - CHANGING CHART NAME FOR LABEL CHART  -->
      <CardTitleTextArea
        v-if="showPanelTitleText && isPanelTypeLabel"
        :isModeDashboardView="isModeDashboardView"
        :placeHolder="'dashboard.enterTitle'"
        :textAreaId="`dashboardCardTitleNameTextarea${panelData.i}`"
        :fontStyle="titleFontStyle"
        :textAreaClass="'vis-chart-input-title'"
        :value="panelDataNameByDynamicChartTitle"
        :textAreaType="textAreaTypes.name"
        :dynamicTitleShowValue="dynamicChartTitle.dynamicTitleShowValue"
        @updateValue="updateTextAreaValue"
        @changeTextAreaHeight="changeTextAreaHeight"
      />
      <!-- END TEXTAREA -->
      <!-- START INPUT - CHANGING THE CHART NAME -->
      <input
        v-if="showPanelTitleText && !isPanelTypeLabel"
        @click.stop="$emit('closePopoverMenu')"
        :value="panelDataNameByDynamicChartTitle"
        @change="updatePanelName($event.target.value)"
        class="vis-chart-input-title no-drag vis-ellipsis"
        type="text"
        :placeholder="$t('dashboard.enterTitle')"
        :style="titleFontStyle"
        :disabled="
          isModeDashboardView || dynamicChartTitle.dynamicTitleShowValue
        "
        tabindex="-1"
      />
      <!-- END INPUT -->

      <!-- START TEXTAREA - CHANGING CHART DESCRIPTION FOR LABEL CHART  -->
      <CardTitleTextArea
        v-if="showPanelSubTitleText && isPanelTypeLabel"
        :isModeDashboardView="isModeDashboardView"
        :placeHolder="'dashboard.enterDesc'"
        :textAreaId="`dashboardCardTitleDescriptionTextarea${panelData.i}`"
        :fontStyle="descriptionFontStyle"
        :textAreaClass="'vis-chart-input-subTitle'"
        :value="panelData.description"
        :textAreaType="textAreaTypes.description"
        @updateValue="updateTextAreaValue"
        @changeTextAreaHeight="changeTextAreaHeight"
      />
      <!-- END TEXTAREA -->

      <!-- START INPUT - CHANGING THE CHART DESCRIPTION -->
      <input
        v-if="showPanelSubTitleText && !isPanelTypeLabel"
        @click.stop="$emit('closePopoverMenu')"
        :value="panelData.description"
        @change="updatePanelDescription($event.target.value)"
        class="vis-chart-input-subTitle no-drag"
        type="text"
        :placeholder="$t('dashboard.enterDesc')"
        :style="descriptionFontStyle"
        :disabled="isModeDashboardView"
        tabindex="-1"
      />
    </div>
    <!-- INPUT END -->
    <i
      v-if="isChartFilterChannelIconActive"
      @click.stop="$emit('setChartFilterChannelNonFilteredCharts', panelData.i)"
      class="vis-cursor-pointer"
      :class="[
        CustomIcon.ChannelTarget,
        { 'vis-color-danger': chartFilterChannelNotConnected },
      ]"
      aria-hidden="true"
    ></i>
    <div
      v-if="!isChartFilterChannelViewActive"
      class="vis-chart-card-icon-box vis-display-flex"
      :class="{
        'vis-absolute-icon-box': isHiddenTitleElements || isPanelTypeTextEditor,
      }"
    >
      <!-- GET DEFAULT AGGREGATIONS ICON START -->
      <i
        v-if="hasDrillDownAggregation"
        @click="deleteDrillDown()"
        :class="CustomIcon.Refresh"
        aria-hidden="true"
      ></i>
      <!--  GET DEFAULT AGGREGATIONS ICON  END -->

      <!-- CLEARALLFILTERS ICON START -->
      <i
        v-if="hasDrilldownFilter && !hasDrillDownAggregation"
        @click="deleteDrillDownFilter()"
        :class="CustomIcon.ClearAllFilters"
        aria-hidden="true"
      ></i>
      <!-- CLEARALLFILTERS ICON END -->

      <!-- PENCIL ICON START -->
      <i
        v-if="showEditIcon"
        @click.stop="goPanelPage(panelData)"
        :class="CustomIcon.Pencil"
        aria-hidden="true"
      ></i>
      <!-- PENCIL ICON END -->
      <!-- TRANSPOSE ICON START -->
      <i
        v-if="isShowAllowTransposeIcon"
        @click.stop="allowTransposeByChart(panelData)"
        :class="CustomIcon.SwapHoriz"
        aria-hidden="true"
      ></i>
      <!-- TRANSPOSE ICON END -->
      <!-- INFO ICON START -->
      <el-popover
        v-if="showCardInfo"
        placement="top-start"
        :title="$t('generalPages.Info')"
        width="200"
        trigger="hover"
        popper-class="vis-keep-words-together"
      >
        <div>
          <pre>{{ cardInfoText }}</pre>
          <div v-if="cacheStoreTime" class="vis-mt--05">
            <p class="vis-font-medium">
              {{ $t("generalPages.Last Refresh Date") }}:
            </p>
            <p>{{ cacheStoreTime }}</p>
          </div>
        </div>
        <i
          slot="reference"
          class="vis-font-size-1-125"
          :class="CustomIcon.Information"
          aria-hidden="true"
        ></i>
      </el-popover>

      <!-- INFO ICON END -->

      <!-- FILTER ALT ICON START -->
      <el-popover
        v-if="hasActiveFilterIcon && showCardFilterIcon"
        placement="top-start"
        trigger="hover"
      >
        <div
          class="vis-card-filter-info-item"
          v-html="panelDataFilterInfo"
        ></div>
        <i
          slot="reference"
          :class="CustomIcon.FilterAlt"
          aria-hidden="true"
        ></i>
      </el-popover>
      <!-- FILTER ALT ICON END -->

      <!-- FieldList  START -->
      <el-select
        v-if="fieldListSelectVisible"
        v-model="valueFieldList"
        multiple
        collapse-tags
        :placeholder="$t('panelProperties.fieldList')"
        size="mini"
        class="fieldListSelect"
        @change="(value) => $emit('fieldListChanged', value)"
      >
        <el-option-group
          v-for="group in optionsFieldList"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span class="vis-float--left">{{ item.label }}</span>
            <span class="fieldListItemAlias vis-float--right">{{
              item.alias
            }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <!-- FieldList  END -->

      <!-- ExportIcon  START -->
      <el-dropdown
        v-if="isShowExportMenu"
        trigger="click"
        placement="bottom"
        @command="handleCommand"
        :class="[{ 'vis-ma--none': !isShowExportMenu }]"
      >
        <i
          :class="CustomIcon.Download"
          class="vis-ma--none"
          aria-hidden="true"
        ></i>

        <el-dropdown-menu class="vis-dropdown-menu">
          <el-dropdown-item
            v-for="popoverItem in popoverExportMenuItems"
            :key="popoverItem.id"
            :command="popoverItem"
          >
            <label
              class="vis-cursor-pointer"
              :class="[{ 'vis-flex--center': showPngExportIcon }]"
            >
              <i :class="popoverItem.icon" aria-hidden="true"></i>
              {{ $t(popoverItem.label) }}
            </label>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- ExportIcon END -->
      <i
        v-if="showInsightIcon"
        aria-hidden="true"
        :class="CustomIcon.TipsAndUpdated"
        class="vis-font-bold"
        @click.stop="toggleChatGptByChartId(panelData.i)"
      ></i>
      <!-- EXPAND ICON START -->
      <i
        v-show="showExpandIcon"
        @click.stop="changeScreenMode(panelData.i)"
        :class="[
          CustomIcon.OpenInFull,
          { 'vis-mr--none': isModeDashboardView },
        ]"
        aria-hidden="true"
      ></i>
      <!-- EXPAND ICON END -->

      <!-- ThreeDotIcon  START -->
      <el-popover
        ref="threedot_popover"
        trigger="click"
        popper-class="vis-dropdown-popper vis-dropdown-popper-chart-menu"
        :tabindex="-1"
        v-show="!isModeDashboardView || isFilterChartMultiSelectActive && popoverMenuItems.length"
      >
        <div tabindex="-1" class="vis-dropdown">
          <ul>
            <li
              v-for="item in popoverMenuItems"
              :key="item.id"
              :test-id="'dashboardCardMenu-' + item.id"
              @click.stop="item.serviceFn(item.params)"
            >
              <i :class="item.icon" aria-hidden="true"></i>
              {{ $t(item.label) }}
            </li>
          </ul>
        </div>
        <i
          slot="reference"
          :class="CustomIcon.VerticalThreeDot"
          class="vis-ma--none"
          aria-hidden="true"
          tabindex="-1"
          :test-id="panelData.i"
        />
      </el-popover>
      <!-- ThreeDotIcon END -->
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import {
  cardPropertiesEnum,
  dashboardCardTitlePropertiesCssKey,
  PIVOT_TABLE_PROPERTIES_ENUM,
} from "../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../mixins/panelDataPropertiesStyle";
import cloneDeep from "clone-deep";
import {
  getActiveFilterList,
  getFilterItemTextValue,
} from "../../util/filter/cardTitleHelper";
import {
  BOX_KEY_ENUM,
  chartWithNotSeeFilterIcon,
  chartTypes,
  otherChartType,
} from "../../commons/dashboardAndPanel";
import CardTitleTextArea from "./DashboardCardTitleTextArea.vue";
import { notificationType } from "../../commons/notificationTypes";
import { Notify } from "../../commons/helper";
import uuid from "uuid";
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
export default {
  mixins: [panelDataPropertiesStyle],
  components: {
    CardTitleTextArea,
  },
  props: {
    panelData: {
      type: Object,
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    isPanelTypeLabel: {
      type: Boolean,
      default: false,
    },
    isPanelTypeButton: {
      type: Boolean,
      default: false,
    },
    isPanelTypeLogo: {
      type: Boolean,
      default: false,
    },
    isExpandIconActive: {
      type: Boolean,
      default: false,
    },
    isPanelTypeTable: {
      type: Boolean,
    },
    isPanelTypePivotTable: {
      type: Boolean,
    },
    isChartViewLimit: {
      type: Boolean,
    },
    isPanelPage: {
      type: Boolean,
    },
    isMaxThreshold: {
      type: Boolean,
      default: false,
    },
    isPanelTypeFilterDropdown: {
      type: Boolean,
      default: false,
    },
    isPanelTypeFilterSelectList: {
      type: Boolean,
      default: false,
    },
    dynamicChartTitle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dashboardTabOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    mergeCardAndTemplateTitleStyleAsCss: {
      type: Object,
      default() {
        return {};
      },
    },
    mergeCardAndTemplateDescriptionStyleAsCss: {
      type: Object,
      default() {
        return {};
      },
    },
    isDataModelAIActionsFeatureEnabled: {
      type: Boolean,
      default: false,
    },
    isChartFilterChannelViewActive: {
      type: Boolean,
      default: false,
    },
    chartFilterChannelNotConnected: {
      type: Boolean,
      default: false,
    },
    isChartFilterChannelIconActive: {
      type: Boolean,
      default: false,
    },
    isFilterPaneChart: {
      type: Boolean,
      default: false,
    },
    isPanelTypeTabPanel: {
      type: Boolean,
      default: false,
    },
    isPanelTypeTextEditor: {
      type: Boolean,
      default: false,
    },
    isTabPanelTabChart: {
      type: Boolean,
      default: false,
    },
    cacheStoreTime: {
      type: String,
      default: null,
    },
    isShowAllowTransposeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      textAreaTypes: {
        name: "name",
        description: "description",
      },
      valueFieldList: [],
      showInsight: process.env.VUE_APP_SHOW_INSIGHT,
    };
  },
  watch: {
    title(newValue, oldValue) {
      const titleStatus = this.panelData.properties.smartTitle;

      if (
        ((!newValue || !newValue.length) && titleStatus) ||
        (newValue !== oldValue && !titleStatus)
      ) {
        return;
      }

      const panelDataCloned = cloneDeep(this.panelData);
      panelDataCloned.properties.smartTitle = !titleStatus;
      this.updateSelectedPanel(panelDataCloned);
    },
  },

  computed: {
    isFilterChartMultiSelectActive() {
      return (this.isPanelTypeFilterSelectList && this.selectListFilterMultipleSelection) ||
      (this.isPanelTypeFilterDropdown && this.dropdownFilterMultipleSelection);
    },
    isMetricTypeNotDate() {
      return this.panelData?.details?.metrics?.[0]?.fieldUsageType !== DatamodelContextDefaults.USAGE_TYPES.DATE;
    },
    isHiddenTitleElements() {
      return (
        this.isPanelTypeButton ||
        this.isPanelTypeLogo ||
        this.isPanelTypeTabPanel
      );
    },
    fieldListSelectVisible() {
      return (
        this.isPanelTypePivotTable &&
        this.CHART_COMPUTED_VALUES[
          PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST
        ]
      );
    },
    optionsFieldList() {
      const columnsOptions = [];
      const rowsOptions = [];
      const measuresOptions = [];

      this.panelDataDetailsMetrics?.forEach((s) => {
        const fieldAndType = s.field + s.type;

        measuresOptions.push({
          value: JSON.stringify({
            field: fieldAndType,
            value: fieldAndType,
            isMeasures: true,
          }),
          label: fieldAndType,
          alias: fieldAndType,
        });
      });

      this.panelDataDetailsAggs
        ?.filter((a) => a.boxKey === BOX_KEY_ENUM.COLUMN.KEY)
        ?.forEach((r) => {
          this.dashboardTabOptions?.searchFilterList?.[r.field]?.forEach(
            (s) => {
              columnsOptions.push({
                value: JSON.stringify({
                  field: r.field,
                  value: s,
                }),
                label: s,
                alias: r.alias,
              });
            }
          );
        });

      this.panelDataDetailsAggs
        ?.filter((a) => a.boxKey === BOX_KEY_ENUM.ROW.KEY)
        ?.forEach((r) => {
          this.dashboardTabOptions?.searchFilterList?.[r.field]?.forEach(
            (s) => {
              rowsOptions.push({
                value: JSON.stringify({
                  field: r.field,
                  value: s,
                }),
                label: s,
                alias: r.alias,
              });
            }
          );
        });

      return [
        {
          label: this.$t("generalPages.measures"),
          options: measuresOptions,
        },
        {
          label: this.$t("datamodel.columns"),
          options: columnsOptions,
        },
        {
          label: this.$t("datamodel.rows"),
          options: rowsOptions,
        },
      ];
    },
    panelDataNameByDynamicChartTitle() {
      return this.dynamicChartTitle.dynamicTitleShowValue
        ? this.dynamicChartTitle.value
        : this.panelData.name;
    },
    panelDataAllFilters() {
      return [
        ...(this.panelDataDetailsFilters?.length
          ? this.panelDataDetailsFilters
          : []),
        ...(this.panelDataDetailsParams?.length
          ? this.panelDataDetailsParams
          : []),
      ];
    },
    hasActiveFilterIcon() {
      return (
        getActiveFilterList(this.panelDataAllFilters)?.length &&
        !chartWithNotSeeFilterIcon.includes(this.panelData.type)
      );
    },
    panelDataFilterInfo() {
      return getFilterItemTextValue(this.panelDataAllFilters, this.isPanelPage);
    },
    isDataManipulationItemActive() {
      return (
        !this.isFilterPaneChart &&
        !this.isPanelTypeTabPanel &&
        !this.isTabPanelTabChart
      );
    },
    popoverMenuItems() {
      return [
        {
          id: uuid(),
          icon: CustomIcon.Copy,
          label: "generalPages.duplicate",
          params: this.panelData.i,
          serviceFn: (x) => this.duplicateChart(x),
          active:
            !this.isModeDashboardView && this.isDataManipulationItemActive,
        },
        {
          id: uuid(),
          icon: CustomIcon.ContentCopy,
          label: "generalPages.copy",
          params: this.panelData,
          serviceFn: (x) => this.$emit("copyChart", x),
          active:
            !this.isModeDashboardView && this.isDataManipulationItemActive,
        },
        {
          id: uuid(),
          icon: CustomIcon.Cut,
          label: "generalPages.Cut",
          params: this.panelData,
          serviceFn: (x) => this.$emit("cutChart", x),
          active:
            !this.isModeDashboardView && this.isDataManipulationItemActive,
        },
        {
          id: uuid(),
          icon: CustomIcon.InverseSelect,
          label: "generalPages.Inverse Select",
          params: this.panelData,
          serviceFn: () => this.$emit("inverseSelectChart"),
          active:
            this.isDataManipulationItemActive && this.isFilterChartMultiSelectActive && this.isMetricTypeNotDate,
        },
        {
          id: uuid(),
          icon: CustomIcon.SelectAll,
          label: "generalPages.Select All",
          params: this.panelData,
          serviceFn: () => this.$emit("selectAllChart"),
          active:
            this.isDataManipulationItemActive && this.isFilterChartMultiSelectActive && this.isMetricTypeNotDate,
        },
        {
          id: uuid(),
          icon: CustomIcon.Trash,
          label: "generalPages.delete",
          params: this.panelData.i,
          serviceFn: (x) => this.deleteChart(x),
          active: !this.isModeDashboardView,
        },
        {
          id: uuid(),
          icon: CustomIcon.FormatPaint,
          label: "table.conditionalFormatting",
          params: "Conditional Formatting",
          serviceFn: (x) => this.openConditionalFormatting(x),
          active:
            !this.isModeDashboardView &&
            this.isPanelTypePivotTable &&
            this.CHART_COMPUTED_VALUES[
              PIVOT_TABLE_PROPERTIES_ENUM
                .PIVOT_TABLE_ALLOW_CONDITIONAL_FORMATTING
            ],
        },
      ].filter((x) => x.active);
    },
    popoverExportMenuItems() {
      return [
        {
          id: uuid(),
          icon: CustomIcon.ExportXLS,
          label: "table.excelExportNotFormatted",
          params: "Excel Export",
          serviceFn: (x) =>
            this.isMaxThreshold && !this.panelData?.id
              ? Notify(
                  this.$t("errorCode.dashboard_did_not_saved_missing_chart_id"),
                  notificationType.WARNING
                )
              : this.setExportOptionsNotFormatted(x),
          active:
            (this.datatableEnableExcelExport && this.isPanelTypeTable) ||
            (this.isPanelTypePivotTable &&
              this.CHART_COMPUTED_VALUES[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT
              ]),
        },
        !this.isMaxThreshold
          ? {
              id: uuid(),
              icon: CustomIcon.ExportXLS,
              label: "table.excelExport",
              params: "Excel Export",
              serviceFn: (x) =>
                this.isMaxThreshold && !this.panelData?.id
                  ? Notify(
                      this.$t(
                        "errorCode.dashboard_did_not_saved_missing_chart_id"
                      ),
                      notificationType.WARNING
                    )
                  : this.setExportOptions(x),
              active:
                (this.datatableEnableExcelExport && this.isPanelTypeTable) ||
                (this.isPanelTypePivotTable &&
                  this.CHART_COMPUTED_VALUES[
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT
                  ]),
            }
          : [],
        {
          id: uuid(),
          icon: CustomIcon.ExportCSV,
          label: "table.csvExport",
          params: "Csv Export",
          serviceFn: (x) =>
            this.isMaxThreshold && !this.panelData?.id
              ? Notify(
                  this.$t("errorCode.dashboard_did_not_saved_missing_chart_id"),
                  notificationType.WARNING
                )
              : this.setExportOptions(x),
          active:
            (this.datatableEnableCSVExport && this.isPanelTypeTable) ||
            (this.isPanelTypePivotTable &&
              this.CHART_COMPUTED_VALUES[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT
              ]),
        },
        !this.isMaxThreshold && !this.panelDataDetailsSections?.length
          ? {
              id: uuid(),
              icon: CustomIcon.ExportPDF,
              label: "table.pdfExport",
              params: "Pdf Export",
              serviceFn: (x) => this.setExportOptions(x),
              active:
                (this.datatableEnablePDFExport && this.isPanelTypeTable) ||
                (this.isPanelTypePivotTable &&
                  this.CHART_COMPUTED_VALUES[
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT
                  ]),
            }
          : [],
        this.showPngExportIcon
          ? {
              id: uuid(),
              icon: CustomIcon.Image,
              label: "panelProperties.imageExport",
              params: "Png Export",
              serviceFn: (x) => this.exportChartToImage(x),
              active: true,
            }
          : [],
      ].filter((x) => x.active);
    },
    titleFontStyle() {
      return {
        ...this.mergeCardAndTemplateTitleStyleAsCss,
        [dashboardCardTitlePropertiesCssKey.TITLE_ALIGN_ITEM]:
          this.getCardPropertyValueByKey(cardPropertiesEnum.TITLE_ALIGN_ITEM),
      };
    },
    showCardInfo() {
      return this.getCardPropertyValueByKey(cardPropertiesEnum.CARD_INFO_SHOW);
    },
    showCardFilterIcon() {
      return (
        this.getCardPropertyValueByKey(
          cardPropertiesEnum.CARD_FILTER_ICON_SHOW
        ) ?? true
      );
    },
    cardInfoText() {
      return this.getCardPropertyValueByKey(cardPropertiesEnum.CARD_INFO_TEXT);
    },
    showExpandIcon() {
      if (!this.isModeDashboardView) return false;

      return (
        (this.getCardPropertyValueByKey(cardPropertiesEnum.CARD_EXPAND_SHOW) ??
          true) &&
        this.isExpandIconActive
      );
    },
    showPngExportIcon() {
      return (
        this.getCardPropertyValueByKey(
          cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT
        ) ?? false
      );
    },
    descriptionFontStyle() {
      return this.mergeCardAndTemplateDescriptionStyleAsCss;
    },
    isShowExportMenu() {
      if (
        (this.datatableEnableExportMenu && this.isPanelTypeTable) ||
        (this.isPanelTypePivotTable &&
          this.CHART_COMPUTED_VALUES[
            PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU
          ])
      ) {
        return true;
      } else
        return (
          this.getCardPropertyValueByKey(
            cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU
          ) ?? false
        );
    },
    showEditIcon() {
      return !this.isModeDashboardView && !this.isPanelTypeTabPanel;
    },
    showInsightIcon() {
      //TODO Buradaki icon bir sonraki gelişmeye kadar aktif olmayacak.
      const x = true;
      if (x) return false;

      if (
        this.panelData.type === otherChartType.BUTTON ||
        !this.isDataModelAIActionsFeatureEnabled
      )
        return false;

      if (!this.panelData?.properties?.style) {
        return;
      }
      let keys = Object.keys?.(this.panelData?.properties?.style);
      let selectedKey = keys?.find((f) => f?.includes("SHOW_INSIGHT"));

      if (this.showInsight === "true" && selectedKey === undefined) {
        return true;
      } else if (this.showInsight === "false") {
        return false;
      } else {
        if (this.panelData.type === chartTypes.TABLE) {
          return this.datatableEnableShowInsight;
        }
        return this.CHART_COMPUTED_VALUES?.[selectedKey];
      }
    },
    showPanelTitleText() {
      return this.checkShowPanelTitleText(
        this.panelDataNameByDynamicChartTitle
      );
    },
    showPanelSubTitleText() {
      return this.checkShowPanelTitleText(this.panelData.description);
    },
    title() {
      return this.panelData?.name;
    },
    hasDrilldownFilter() {
      return this.panelDataAllFilters.find((f) => f.isDrillDown);
    },
    hasDrillDownAggregation() {
      return this.panelData?.details?.aggregation?.find(
        (f) => f.isDrillDownAgg
      );
    },
  },
  methods: {
    getCardPropertyValueByKey(key) {
      return this.panelData?.properties?.style?.[key];
    },
    updateTextAreaValue(value, type) {
      if (type === this.textAreaTypes.name) {
        this.updatePanelName(value.target.value);
      } else if (type === this.textAreaTypes.description) {
        this.updatePanelDescription(value.target.value);
      }
    },
    updatePanelName(value) {
      const clonedSelectedPanel = cloneDeep(this.panelData);
      clonedSelectedPanel.name = value;
      this.updateSelectedPanel(clonedSelectedPanel);
    },
    updatePanelDescription(value) {
      const clonedSelectedPanel = cloneDeep(this.panelData);
      clonedSelectedPanel.description = value;
      this.updateSelectedPanel(clonedSelectedPanel);
    },
    checkShowPanelTitleText(text) {
      if (this.isModeDashboardView && !text) {
        return false;
      } else return true;
    },
    changeTextAreaHeight(textAreaType) {
      if (textAreaType === this.textAreaTypes.name) {
        this.$emit("changeTextAreaHeightWhereCardTitleName", this.panelData.i);
      } else {
        this.$emit(
          "changeTextAreaHeightWhereCardTitleDescription",
          this.panelData.i
        );
      }
    },
    goPanelPage(item) {
      this.$emit("goPanelPage", item);
    },
    allowTransposeByChart(chartData) {
      this.$emit("allowTranspose", chartData);
    },
    toggleChatGptByChartId(chartId) {
      this.$emit("toggleChatGptByChartId", chartId);
    },
    changeScreenMode(chartId) {
      this.$emit("closePopoverMenu");
      this.$emit("changeScreenMode", chartId);
    },
    deleteChart(chartId) {
      this.$emit("deleteChart", chartId);
    },
    duplicateChart(chartId) {
      this.$emit("duplicateChart", chartId);
    },
    setExportOptions(evt) {
      this.$emit("callTableExport", evt);

      this.closeThreeDotPopup();
    },
    exportChartToImage() {
      this.$emit("exportChartToImage", this.panelData);
    },
    setExportOptionsNotFormatted(evt) {
      this.$emit("callTableExportNotFormatted", evt);

      this.closeThreeDotPopup();
    },
    closeThreeDotPopup() {
      this.$refs.threedot_popover.doClose();
    },
    updateSelectedPanel(panelData) {
      this.$emit("updateSelectedPanel", panelData);
    },
    openConditionalFormatting() {
      this.$emit("openConditionalFormatting");
      this.closeThreeDotPopup();
    },
    deleteDrillDownFilter() {
      this.$emit("deleteDrillDownFilter", this.panelData);
    },
    deleteDrillDown() {
      this.$emit("deleteDrillDown", this.panelData);
      this.deleteDrillDownFilter();
    },
    handleCommand(command) {
      if (command) command.serviceFn(command.params);
    },
  },
};
</script>
<style scoped>
.vis-textarea {
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
}
.vis-chart-card-icon-box {
  height: max-content;
  column-gap: 1rem;
}
.vis-chart-card-icon-box i {
  cursor: pointer;
  color: var(--secondary-dark-1);
}
.vis-absolute-icon-box {
  position: absolute;
  right: 7px;
  height: 20px !important;
  z-index: 1;
  margin: 0;
  width: auto;
}
.fieldListSelect {
  width: 160px !important;
}
::v-deep .el-tag.el-tag--info {
  max-width: 80px !important;
}
.fieldListItemAlias {
  color: #8492a6;
  font-size: 0.75rem;
}
.el-popover__title {
  font-weight: bold !important;
}
</style>
