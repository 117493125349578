import { DatamodelContextDefaults } from "./dataModelTypes";
import {
    operator,
} from "../components/panel/filters/js/filters";
export const orderTypes = {
    SORTING: "sorting"
}

export const toolbarOptions = {
    SEARCH: 'Search',
    CSV_EXPORT: 'Csv Export',
    EXCEL_EXPORT: 'Excel Export',
    PDF_EXPORT: 'Pdf Export'
}

export const tableFooterAggregrations = {
    [DatamodelContextDefaults.SUMMARIZATION_RULE.SUM]: {
        text: "generalPages.total",
        calculate: (values) => values?.reduce((prev, cur) => prev + cur, 0)
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.MAX]: {
        text: "generalPages.max",
        calculate: (values) => Math.max(...values)
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.MIN]: {
        text: "generalPages.min",
        calculate: (values) => Math.min(...values)
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.AVG]: {
        text: "generalPages.avg",
        calculate: (values) => (values.reduce((prev, cur) => prev + cur, 0) / values.length).toFixed(6)
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT]: {
        text: "generalPages.count",
        calculate: (values) => values.reduce((prev, cur) => prev + cur, 0)
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.DISTINCT_COUNT]: {
        text: "generalPages.distinctCount",
        calculate: (values) => values.reduce((prev, cur) => prev + cur, 0)
    }
}

export const tableFooterAggregrationsForAttributes = {
    [DatamodelContextDefaults.SUMMARIZATION_RULE.MAX]: {
        text: "generalPages.max",
        calculate: (values) => values.reduce((a, b) => (a > b ? a : b))
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.MIN]: {
        text: "generalPages.min",
        calculate: (values) => values.reduce((a, b) => (a < b ? a : b))
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.COUNT]: {
        text: "generalPages.count",
        calculate: (values) => values.length
    },
    [DatamodelContextDefaults.SUMMARIZATION_RULE.DISTINCT_COUNT]: {
        text: "generalPages.distinctCount",
        calculate: (values) => new Set(values).size
    }
}

export const ejsTableOperatorConversion = {
    equal: operator.EQ,
    notequal: operator.NotEq
}

export const ejsTableSortTypeConversion = {
    Ascending: DatamodelContextDefaults.BARS.ASC,
    Descending: DatamodelContextDefaults.BARS.DESC,
}

export const ejsTablePropertyType = {
    SORTING: "sorting",
    FILTERING: "filtering"
}

export const ejsPivotTableActionNames = {
    VALUE_SORTED: "Value sorted",
}
