export const datatableColumnsDefaultConfigurations = {
    CHART_DATA_TABLE_COLUMN_FONT_FAMILY: undefined,
    CHART_DATA_TABLE_COLUMN_FONT_SIZE: undefined,
    CHART_DATA_TABLE_COLUMN_FONT_WEIGHT: undefined,
    CHART_DATA_TABLE_COLUMN_FONT_COLOR: undefined,
    CHART_DATA_TABLE_FONT_COLOR: undefined,
    CHART_DATA_TABLE_ROW_FONT_FAMILY: undefined,
    CHART_DATA_TABLE_ROW_FONT_WEIGHT: undefined,
    CHART_DATA_TABLE_ROW_FONT_SIZE: undefined,
    CHART_DATA_TABLE_COLUMN_WIDTH: undefined,
    CHART_SELECT_COLUMN_ALIGN: undefined,
    CHART_DATA_TABLE_COLUMN_FORMAT: "NORMAL",
    CHART_DATA_TABLE_COLUMN_TEXT_WRAP: false,
    CHART_DATA_TABLE_ROW_TEXT_WRAP: null,
    CHART_SELECT_ROW_ALIGN: undefined,
    CHART_DATA_TABLE_ROW_BACKGROUND_COLOR: undefined,
    CHART_DATA_TABLE_COLUMN_NAME:"",
    CHART_DATA_TABLE_COLUMN_SCREENTIP:"",
    CHART_DATA_TABLE_ROW_MAX_CHARACTER: undefined,
    CHART_DATA_TABLE_MEASURE_SHOW_TOTAL: true,
    CHART_DATA_TABLE_AGGREGATION_FUNCTION: "SAME_AS_MEASURE",
    CHART_DATA_TABLE_ATTRIBUTE_SHOW_TOTAL: false,
    CHART_DATA_TABLE_ATTRIBUTE_AGGREGATION_FUNCTION: "NONE",
}


export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}